import React from "react";

import classes from "../../../assets/css/personaAI/index.module.scss";

class CustomerPersona extends React.Component {
  constructor(props) {
    super(props);
    this.business = React.createRef();
    this.description = React.createRef();
  }
  render() {
    const {sendPromptToChatGPT} = this.props
    return (
      <main className={classes.category}>
        <header className={classes.category_hero}>
          <h2>Customer Persona</h2>
          <p>Fill inputs below and click button</p>
        </header>
        <section>
          <form
            className={classes.category_form}
            onSubmit={(e) => {
              e.preventDefault();
              const { value: business } = this.business?.current;
              const { value: description } = this.description?.current;

              const prompt = `Create perfect customer profile  for ${business}.`; //to chatGpt

              sendPromptToChatGPT(
                "Customer persona",
                { business,description, author: "user" },
                prompt
              );
            }}
          >
            <div>
              <div>
                <div>
                  <label htmlFor="business-name">Business name</label>
                  <input
                    placeholder="Type here"
                    id="business-name"
                    ref={this.business}
                  />
                </div>
              </div>
              <div>
                <div>
                  <label htmlFor="description">Description</label>
                  <input
                    placeholder="Type here"
                    id="description"
                    ref={this.description}
                  />
                </div>
              </div>
            </div>
            <button type="submit">
              Start using{" "}
              <svg
                width="21"
                height="20"
                viewBox="0 0 21 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.8337 1.66666L9.66699 10.8333M18.8337 1.66666L13.0003 18.3333L9.66699 10.8333M18.8337 1.66666L2.16699 7.49999L9.66699 10.8333"
                  stroke="white"
                  strokeWidth="1.67"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </form>
        </section>
      </main>
    );
  }
}

export default CustomerPersona;
