import React, { Component } from "react";

import {
  Map,
  GoogleApiWrapper,
  Marker,
  Polygon,
  InfoWindow,
} from "google-maps-react";

import googleMapStyles from "../../components/Common/google-styles";
import Venue from "../../assets/images/venue.gif";
import Billboard from "../../assets/css/common/icons/billboard.svg";

import Geocode from "react-geocode";
const GOOGLE_MAP_KEY = process.env.REACT_APP_GOOGLEMAPKEY
Geocode.setApiKey(GOOGLE_MAP_KEY);

const LoadingContainer = (props) => (
  <div id="preloader">
    <div id="status">
      <div className="spinner-chase">
        <div className="chase-dot"></div>
        <div className="chase-dot"></div>
        <div className="chase-dot"></div>
        <div className="chase-dot"></div>
        <div className="chase-dot"></div>
        <div className="chase-dot"></div>
      </div>
    </div>
  </div>
);

class GoogleMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Coords: {},
      streetIQCardStatus: true,
      activeMarker: {},
      selectedPlace: {},
      showingInfoWindow: false,
      markerGeoCode: {},
    };
  }
  onMarkerClick = (props, marker) =>
    this.setState({
      activeMarker: marker,
      selectedPlace: props,
      showingInfoWindow: true,
    });

  onInfoWindowClose = () =>
    this.setState({
      activeMarker: null,
      showingInfoWindow: false,
    });

  onMapClicked = () => {
    if (this.state.showingInfoWindow)
      this.setState({
        activeMarker: null,
        showingInfoWindow: false,
      });
  };

  render() {
    const { state, handleClickOnVenueIcon } = this.props;
    const { venues } = state;

    return (
      <Map
        google={this.props.google}
        zoom={state.zoom}
        styles={this.props.mapStyle}
        initialCenter={state.center}
        fullscreenControl={false}
        draggable={true}
        center={state.postCenter}
        scrollwheel={true}
        mapTypeControl={false}
        disableDoubleClickZoom={false}
        disableDefaultUI={true}
        keyboardShortcuts={false}
        //onDragend={(coord) => this.centerMoved(coord)}

      >


        {
          venues.map((venue, index) => (
            <Marker
              key={index}
              icon={state.slider_index === index ? Venue : Billboard}
              position={ venue.position}
              animation= {window.google.maps.Animation.DROP}
              //onMouseover={this.onMarkerClick}
              onClick={() => handleClickOnVenueIcon(index)}
            />
          ))}
   
      </Map>
    );
  }
}

GoogleMap.defaultProps = googleMapStyles;

export default GoogleApiWrapper({
  apiKey: GOOGLE_MAP_KEY,
  LoadingContainer: LoadingContainer,
})(GoogleMap);
