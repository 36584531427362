import axios from "axios";
import React, { Component } from "react";

import { Link, withRouter } from "react-router-dom";

import Trash from "../../assets/css/CreateAd/trash.svg";
import ArrowRight from "../../assets/css/CreateAd/arrow-right.svg";
import classes from "../../assets/css/CreateAd/index.module.css";

import Details from "./details";

import { connect } from "react-redux";

class Pullcampaigns extends Component {
  constructor(props) {
    super(props);
    this.state = {
      haveCampaigns: false,
      pullledCampaigns: [],
      modalViewDetailsStatus: false,
      viewCampaign: {},
      loading: false,
      multiple: false,
      multiple_delete: false,
    };

    this.changeAddStatus = this.changeAddStatus.bind(this);
    this.settingInterval = null;
  }
  componentDidMount() {
    this.setState((state) => {
      return {
        ...state,
        loading: this.props.loaded ? false : true,  //first time is true if u u return from details or create campaign false
        pullledCampaigns: this.props.pulled_campaigns.map((el) => {
          el.checked = false;
          return el;
        }),  //first is empty then we're gonna have campaigns which wont work in didupdate lifecycle
      };
    });
  }

  componentDidUpdate(prevProps) {
    const { pulled_campaigns, loaded} = this.props;
    /*if (prevProps.pulled_campaigns.length !== pulled_campaigns.length) {
      let campaigns = [...pulled_campaigns];
      campaigns = campaigns.map((el) => {
        el.checked = false;
        return el;
      });
      this.setState((state) => {
        return {
          ...state,
          pullledCampaigns: campaigns,
        };
      });
    }*/
    if(loaded !== prevProps.loaded){
      let campaigns = [...pulled_campaigns];
      campaigns = campaigns.map((el) => {
        el.checked = false;
        return el;
      });
      this.setState((state) => {
        return {
          ...state,
          loading: this.props.loaded ? false : true,
          pullledCampaigns:this.props.loaded ?  campaigns : []
        };
      });
    }
  }

  toggleDeleteAd = (id, type) => {
    this.setState({ ...this.state, loading: true });
    axios
      .delete(`https://backendapp.murmurcars.com/api/v1/hypeiq/${id}`)
      .then((response) => {
      
        window.location.reload();
        if (type === "from_details") {
          this.props.history.replace("/ad-manager");
          this.props.history.go("/ad-manager");
        }
        this.setState({ ...this.state, loading: false });
      })

      .catch((error) => alert('netwrok issue'))
  };

  toggleDeleteMultipleAd = () => {
    const {pullledCampaigns} = this.state;
    const list_of_ids = [];
    this.setState({ ...this.state, loading: true });
    for (let i = 0; i < pullledCampaigns.length; i++) {
      
      if (pullledCampaigns[i].checked) {
        list_of_ids.push(pullledCampaigns[i]._id);
      }
    }
   
    axios
      .delete(
        `https://backendapp.murmurcars.com/api/v1/hypeiq/delete/${list_of_ids}`
      )
      .then(() => {
        window.location.reload();
        this.setState({ ...this.state, loading: false });
      })
      .catch((err) =>
        this.setState({
          ...this.state,
          loading: false,
        })
      );
  };

  checkCampaign = (event, id) => {
    const checked = event.target.checked;
    const { pullledCampaigns } = this.state;
    const campaigns = [...pullledCampaigns];
    let multiple = false;
    let count = 0;

    if (checked) count++;
    for (let i = 0; i < campaigns.length; i++) {
      if (campaigns[i].checked) {
        count++;
      }
      if (campaigns[i]._id === id) {
        campaigns[i].checked = checked;
        if (!checked) {
          count--;
        }
      }
    }
    if (count > 1) {
      multiple = true;
    }
    this.setState({
      ...this.state,
      multiple_delete: multiple,
      pullledCampaigns: campaigns,
    });
  };

  checkAllCampigns = (e) => {
    const checked = e.target.checked;
    const { pullledCampaigns } = this.state;
    const campaigns = [...pullledCampaigns];
    for (let i = 0; i < campaigns.length; i++) {
      if (checked) {
        campaigns[i].checked = true;
      } else {
        campaigns[i].checked = false;
      }
    }

    this.setState({
      ...this.state,
      multiple: checked,
      multiple_delete: checked,
      pullledCampaigns: campaigns,
    });
  };

  //ad-campaign
  handleCampaigns = (campaigns) => {
    const { multiple_delete, multiple } = this.state;

    let murmurCampaigns = [];

  
    if (campaigns.length !== 0) {
      {
        campaigns.map((campaign, i) => {
          murmurCampaigns.push(
            <tr key={i}>
              <td className={classes.cads_td}>
                <div className={classes.cads_flex_th}>
                  <div
                    className={`${classes.cads_check} ${classes.cads_check_2}`}
                  >
                    <input
                      type="checkbox"
                      id={campaign._id}
                      checked={
                        (multiple && campaign.checked) || campaign.checked
                      }
                      onChange={(e) => this.checkCampaign(e, campaign._id)}
                    />
                    <label htmlFor={campaign._id}>
                      <span> {campaign.hype_title}</span>
                    </label>
                  </div>

                  <div className={`${classes.cads_radio_active}`}>
                    {campaign.checked && multiple_delete === false && (
                      <button
                        type="button"
                        className={`${classes.check_remove}`}
                        onClick={() => this.toggleDeleteAd(campaign._id)}
                      >
                        <img src={Trash} alt="" />
                      </button>
                    )}
                    {/*  <label className={classes.switch}>
                      <input
                        type="checkbox"
                        checked={!adds[i].toggled}
                        onChange={(e) =>
                          this.changeAddStatus(e, campaign._id)
                        }
                      />
                      <div
                        className={`${classes.slider} ${classes.round}`}
                        //  onClick={() => this.changeAddStatus(campaign._id, this.state.adds[i].checked)}
                      ></div>
                      </label>*/}
                  </div>
                </div>
              </td>
              <td className={classes.cads_td}>
                {campaign.ad_status === "In Review" ? (
                  <span className={classes.td_data}>
                    {" "}
                    {campaign.ad_status}
                  </span>
                ) : (
                  <span
                    className={`${
                      campaign.approved
                        ? classes.cads_active_dot
                        : classes.cads_deactive_dot
                    }`}
                  >
                    <span className={classes.cads_dot}></span>{" "}
                    {`${campaign.approved ? "Active" : "Deactive"}`}
                  </span>
                )}
              </td>
              <td className={classes.cads_td}>
                <span className={classes.td_data}>
                  {" "}
                  {campaign.ad_schedule}
                </span>
              </td>
              <td className={classes.cads_td}>
                <span className={classes.td_data}>{campaign.budget}</span>
              </td>

              <td className={classes.cads_td}>
                <Link
                  to={`/hypeIQ?campaign=${campaign._id}`}
                  className={classes.details_link}
                >
                  Details
                  <img
                    src={ArrowRight}
                    alt=""
                    className={classes.details_img}
                  />
                </Link>
              </td>
            </tr>
          );
        });
      }
    }

    return murmurCampaigns;
  };

  toggleMultipleAdds = (checked) => {
    const adds = this.state.adds;

    for (let i = 0; i < adds.length; i++) {
      if (adds[i].checked) {
        adds[i].toggled = checked;
      }
    }

    this.setState({
      ...this.state,
      adds,
      multiple_delete: checked,
    });
  };
  //activating and disactivating ad campaign
  changeAddStatus(e, id) {
    const checked = !e.target.checked;

    if (this.state.multiple || this.state.all_checked) {
      this.toggleMultipleAdds(checked);
      return;
    }

    const adds = this.state.adds;

    for (let i = 0; i < adds.length; i++) {
      const toggled = adds[i].toggled;

      if (adds[i].id === id) {
        if (toggled) {
        }
        adds[i].toggled = !toggled;
      } else {
        if (!toggled) {
        }
      }
    }

    this.setState({
      ...this.state,
      adds,
    });
  }

  render() {
    const url = this.props.location.search; //search property of history props
    const id = new URLSearchParams(url).get("campaign"); //extracting id

    const campaigns = this.state.pullledCampaigns.filter((el) => el._id === id); //looging for campaign by ad campaign id
    const { multiple_delete } = this.state;
  
    return (
      <React.Fragment>
        {/* this part is ad-manager STARTING*/}
        {this.state.loading && !campaigns.length && (
          <div id="preloader">
            <div id="status">
              <div className="spinner-chase">
                <div className="chase-dot"></div>
                <div className="chase-dot"></div>
                <div className="chase-dot"></div>
                <div className="chase-dot"></div>
                <div className="chase-dot"></div>
                <div className="chase-dot"></div>
              </div>
            </div>
          </div>
        )}
        {!id &&
          !this.state.loading && ( //list all campaigns
            <div className={classes.cads_table}>
              <table>
                <thead>
                  <tr className={classes.first_tr}>
                    <th className={`${classes.cads_th}`}>
                      <div
                        className={`${classes.cads_check} ${classes.invoice_th}`}
                      >
                        <input
                          type="checkbox"
                          id="invoice-txt"
                          onChange={this.checkAllCampigns}
                          checked={this.state.multiple}
                        />
                        <label htmlFor="invoice-txt">Name</label>
                        {multiple_delete && (
                          <button
                            type="button"
                            className={`${classes.check_remove} ${classes.multiple_remove}`}
                            onClick={() => this.toggleDeleteMultipleAd()}
                          >
                            <img src={Trash} alt="" />
                          </button>
                        )}
                      </div>
                    </th>
                    <th className={classes.cads_th}>
                      <span>Status</span>
                    </th>
                    <th
                      className={`${classes.cads_th} ${classes.cads_quantity}`}
                    >
                      <span>Duration</span>
                    </th>
                    <th className={`${classes.cads_th} ${classes.cads_budget}`}>
                      <span>Cost</span>
                    </th>

                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {this.handleCampaigns(this.state.pullledCampaigns)}
                </tbody>
              </table>
            </div>
          )}

        {id &&
          campaigns.length && ( //when user selects an add to check details
            <Details
              campaign={campaigns}
              adds={this.state.adds}
              delete={this.toggleDeleteAd}
              loading={this.state.loading}
            />
          )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const { pulled_campaigns, loaded } = state.HypeIQ;
  return { pulled_campaigns, loaded };
};

export default connect(mapStateToProps, null)(withRouter(Pullcampaigns));
