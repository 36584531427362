import React, { Component } from "react";

import classes from "../../../assets/css/Destination/index.module.css";

import ToggleButtonDopDown from "./toggleButtonDopDown";

class SocialMediaChannels extends React.Component {
  render() {
    const { platforms, toggleDropdownMenu, togglePlatform } = this.props;
    return (
      <div
        className={`${classes.destin_trees} justify-content-start pt-0 pb-0`}
      >
        <ul
          className={`${classes.sources_ul} w-100 flex-row justify-content-start `}
          style={{ minHeight: "fit-content" }}
        >
          {platforms.map((el, i) => (
            <li key={i} className={`${classes.sources_li} mt-3`}>
              <div
                className={`${classes.sources_item} mr-2`}
                style={{ minWidth: "200px" }}
              >
                <p className={classes.sources_p}>
                  <img src={el.item_img} alt="" />
                  <span>{el.item_name}</span>
                </p>
                <div className={classes.source_item_right}>
                  <span className={classes.source_status}>
                    <small
                      className={`${
                        el.active
                          ? classes.active_status_dot
                          : classes.deactive_status_dot
                      }`}
                    ></small>
                  </span>
                  <div className={classes.dot_dropdown}>
                    <ToggleButtonDopDown
                      active={el.active}
                      show={el.menu}
                      toggle={() => {
                        const socialPlatform = new Array(...platforms);

                        const thisElement = el;
                        thisElement.menu = !thisElement.menu;

                        const left = socialPlatform.slice(0, i);
                        const right = socialPlatform.slice(
                          i + 1,
                          socialPlatform.length
                        );

                        toggleDropdownMenu(left, thisElement, right);
                      }}
                      togglePlatform={(state) => {
                        const socialPlatform = new Array(...platforms);

                        const thisElement = el;
                        thisElement.active = state;

                        const left = socialPlatform.slice(0, i);
                        const right = socialPlatform.slice(
                          i + 1,
                          socialPlatform.length
                        );

                        togglePlatform(left, thisElement, right);
                      }}
                    />
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

export default SocialMediaChannels;
