import React, { Component } from "react";

import { Button, Alert, Modal, ModalBody } from "reactstrap";

import SearchNormal from "../../assets/css/Settings/search-normal.svg";
import SearchMaximize from "../../assets/css/Settings/search-maximize.svg";
import ProfileMenu from "../../components/CommonForBoth/TopbarDropdown/ProfileMenu";
import Close from "../../assets/css/Dashboard/close.svg";
import Location from "../../assets/css/PlaceIQ/location.svg";
import Mobile from "../../assets/css/PlaceIQ/mobile.svg";
import Instagramm from "../../assets/css/PlaceIQ/instagram.svg";
import Facebook from "../../assets/css/PlaceIQ/facebook.svg";
import classes from "../../assets/css/Dashboard/dashboard.module.css";
import classes2 from "../../assets/css/PlaceIQ/index.module.scss";
import ChevronLeft from "../../assets/css/PlaceIQ/chevron-left.svg";
import ChevronRight from "../../assets/css/PlaceIQ/chevron-right.svg";
import Earth from "../../assets/images/earth-spins.gif";

import { Link, withRouter } from "react-router-dom";

import axios from "axios";

import { addRemoveRestaurants, selectLocation } from "../../store/actions";
import { connect } from "react-redux";

import GoogleMap from "./google-map";
import Geocode from "react-geocode";

import Checkout from "./checkout";
import LocationModal from "../../components/location-modal";

const GOOGLE_MAP_KEY = process.env.REACT_APP_GOOGLEMAPKEY;
Geocode.setApiKey(GOOGLE_MAP_KEY);

class PlaceIQ extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address: "", //geocode address
      country: "", //location country name
      city: "",
      location: "", //input location
      alert_status: false, //show alert
      permission: false, //if geocode deactivated of we do not hace data for the location
      errorZipCode: false, //mistake in input location typeing
      errorMessage: null, //error message
      venues: [],
      checkout: false,
      locationModal: false,
      locationLoader: false,
      slider_index: 0,
      //toggleCartWithAreaInformation: false,
      dataBox: false,
      center: {
        lat: 0,
        lng: 0,
      },
      postCenter: {
        lat: 0,
        lng: 0,
      },
      zoom: 11,
      loaded: false, //temperorary shoudl to be false
    };

    this.zipIsWrongRef = React.createRef();
    this.toggleCard = this.toggleCard.bind(this);
    this.changeLocationToSearch = this.changeLocationToSearch.bind(this);
    this.submitLocationToZoomIn = this.submitLocationToZoomIn.bind(this);
  }

  changeLocationToSearch(e) {
    const location = e.target.value.trim();
    this.setState((state) => {
      return { ...state, location };
    });
  }

  submitLocationToZoomIn(e) {
    e.preventDefault();
    const { venues, location } = this.state;
    const index = venues.findIndex((venue) => venue.venue_name === location);
    this.setState((state) => ({
      ...state,
      location: "",
      slider_index: index,
      dataBox: true,
    }));
  }

  toggleCard() {
    this.setState({
      ...this.state,
      toggleCartWithAreaInformation: !this.state.toggleCartWithAreaInformation,
    });
  }

  handleReverseGeocode = () => {
    Geocode.fromLatLng(
      this.state.postCenter.lat,
      this.state.postCenter.lng
    ).then(
      (response) => {
        const address = response.plus_code.compound_code;
        const country = address
          .split(/\s|[,]/i)
          .filter((el) => el !== "")
          .at(-1)
          .trim();
        let city = address
          .split(/\s|[,]/i)
          .filter((el) => el !== "")
          .at(-2)
          .trim();

        if (country === "USA") {
          city = city === "Illinois" || city === "IL" ? "Chicago" : "SF";
        }

        this.props.selectLocation(country, city);
        axios
          .get(
            `https://backendapp.murmurcars.com/api/v1/venues/all-venues-by-city/${country}`
          )
          .then((response) => {
            const { data } = response;
            this.setState((state) => {
              return {
                ...state,
                address,
                country,
                city,
                center: data.length ? data.at(0).position : this.state.center,
                postCenter: data.length
                  ? data.at(0).position
                  : this.state.postCenter,
                zoom: 18,
                venues: data,
                dataBox: true,
                loaded: true,
              };
            });
          })
          .catch((err) =>
            this.setState((state) => {
              return { ...state, loaded: true };
            })
          );
      },
      (error) => {
        this.setState((state) => {
          return { ...state, loaded: true };
        });
      }
    );
  };
  handleGeocode = (location) => {
    const { country, city } = this.state;

    Geocode.fromAddress(country + city + location)
      .then((response) => {
        const { lat, lng } = response.results[0].geometry.location;

        this.setState({
          ...this.state,
          loaded: true,
          toggleCartWithAreaInformation: true,
          errorZipCode: false,
          errorMessage: "",
          postCenter: { lat, lng },
          center: { lat, lng },
          location: "",
        });
      })
      .catch(() => {
        this.setState({
          ...this.state,
          loaded: true,
          toggleCartWithAreaInformation: false,
          errorZipCode: true,
          errorMessage: "invalid location name",
        });
      });
  };
  componentDidMount() {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        this.setState({
          ...this.state,
          postCenter: {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          },
          center: {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          },
          permission: true,
        });
        this.handleReverseGeocode();
      },
      (err) => {
        this.setState({
          ...this.state,
          loaded: true,
          alert_status: true,
          permission: false,
        });
      }
    );
  }

  slideControl = (step) => {
    const { slider_index, venues } = this.state;

    const slider_length = venues.length;

    let next_index = slider_index + step;

    if (next_index >= slider_length) {
      next_index = 0;
    } else if (next_index < 0) {
      next_index = slider_length - 1;
    }
    const center = this.state.venues.at(next_index).position;
    this.setState((state) => {
      return {
        ...state,
        slider_index: next_index,
        center,
        postCenter: center,
      };
    });
  };
  handleClickOnVenueIcon = (index) => {
    const center = this.state.venues.at(index).position;
    this.setState((state) => {
      return {
        ...state,
        slider_index: index,
        center,
        postCenter: center,
        dataBox: true,
      };
    });
  };

  toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method*/ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  reverseGeolocation = (country, city) =>
    new Promise((resolve, reject) => {
      Geocode.fromAddress(`${country}, ${city}`)
        .then((response) => {
          const { lat, lng } = response.results[0].geometry.location;
          const center = {
            lat,
            lng,
          };
          resolve(center);
        })
        .catch((err) => {
          reject();
        });
    });

  changeToDifferentCity = (country, city) => {
    this.setState((state) => ({
      ...state,
      locationModal: false,
      locationLoader: true,
    }));
    this.props.selectLocation(country, city);
    this.reverseGeolocation(country, city)
      .then((center) => {
        axios
          .get(
            `https://backendapp.murmurcars.com/api/v1/venues/all-venues-by-city/${country}`
          )
          .then((response) => {
            const { data } = response;
            this.setState((state) => ({
              ...state,
              locationLoader: false,
              country,
              city,
              address: `${country}, ${city}`,
              center,
              postCenter: center,
              dataBox: false,
              venues: data,
            }));
          })
          .catch((err) => {});
      })
      .catch((err) => {});
  };

  removeRestaurantFromCart = (restaurant) => {
    this.props.addRemoveRestaurants(restaurant, false);
  };
  render() {
    const {
      loaded,
      alert_status,
      permission,
      location,
      venues,
      slider_index,
      dataBox,
      checkout,
      locationModal,
      locationLoader,
      country,
      city,
    } = this.state;

    const { selectedRestaurants } = this.props;

    return (
      <React.Fragment>
        {!loaded ? (
          <div id="preloader" style={{ opacity: 0.7 }}>
            <div id="status">
              <div className="spinner-chase">
                <div className="chase-dot"></div>
                <div className="chase-dot"></div>
                <div className="chase-dot"></div>
                <div className="chase-dot"></div>
                <div className="chase-dot"></div>
                <div className="chase-dot"></div>
              </div>
            </div>
          </div>
        ) : locationLoader ? (
          <div id="preloader" style={{ opacity: 0.7 }}>
            <div
              id="status"
              className="top-50"
              style={{ width: "100px", height: "100px" }}
            >
              <img src={Earth} alt="spinner" className="w-100 h-100" />{" "}
            </div>
          </div>
        ) : null}

        <div className={classes.dash_right}>
          <div className={classes.map}>
            <GoogleMap
              state={this.state}
              handleClickOnVenueIcon={this.handleClickOnVenueIcon}
            />
          </div>

          {loaded && (
            <React.Fragment>
              <div className={classes.head_search_dashboard}>
                <div className={classes.head_search}>
                  <form onSubmit={this.submitLocationToZoomIn}>
                    <div
                      className={`${classes.dash_relative} ${classes.search_box}`}
                    >
                      <input
                        type="text"
                        placeholder="search restaurant"
                        value={location}
                        //onChange={this}
                        onChange={(e) => {
                          const restaurant = e.target.value;

                          this.setState((state) => ({
                            ...state,
                            location: restaurant,
                          }));
                        }}
                      />
                      <div className={classes.search_box_flex}>
                        <button type="submit" className={classes.search_icon}>
                          <img
                            src={SearchNormal}
                            alt=""
                            className={classes.search_img}
                          />
                        </button>
                        <button
                          type="button"
                          className={classes.search_maximize}
                          onClick={this.toggleFullscreen}
                        >
                          <img
                            src={SearchMaximize}
                            alt=""
                            className={classes.maximize_img}
                          />
                        </button>

                        <ProfileMenu scope={"global"} />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className={classes.dashboard_right}>
                {alert_status && (
                  <Alert
                    className="d-flex align-items-center justify-content-between"
                    style={{ pointerEvents: "all" }}
                  >
                    {!permission
                      ? "Please activate geolocation or select location in order to fetch data"
                      : "We are not providing data in your region. Please change location"}

                    <Button
                      color="link"
                      onClick={() =>
                        this.setState({
                          ...this.state,
                          alert_status: false,
                        })
                      }
                    >
                      Close
                    </Button>
                  </Alert>
                )}
                {!locationModal ? (
                  <div
                    className={`${classes.show_place} d-flex`}
                    style={{ width: "fit-content", padding: "8px 10px" }}
                    onClick={() => {
                      this.setState((state) => ({
                        ...state,
                        locationModal: true,
                      }));
                    }}
                  >
                    <svg
                      viewBox="0 0 24 24"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="#000000"
                      width="30"
                      height="30"
                    >
                      <g strokeWidth="0"></g>
                      <g strokeLinecap="round" strokeLinejoin="round"></g>
                      <g>
                        <g
                          stroke="none"
                          strokeWidth="1"
                          fill="none"
                          fillRule="evenodd"
                        >
                          {" "}
                          <g
                            transform="translate(-720.000000, -50.000000)"
                            fillRule="nonzero"
                          >
                            {" "}
                            <g transform="translate(720.000000, 50.000000)">
                              {" "}
                              <path
                                d="M24,0 L24,24 L0,24 L0,0 L24,0 Z M12.5934901,23.257841 L12.5819402,23.2595131 L12.5108777,23.2950439 L12.4918791,23.2987469 L12.4918791,23.2987469 L12.4767152,23.2950439 L12.4056548,23.2595131 C12.3958229,23.2563662 12.3870493,23.2590235 12.3821421,23.2649074 L12.3780323,23.275831 L12.360941,23.7031097 L12.3658947,23.7234994 L12.3769048,23.7357139 L12.4804777,23.8096931 L12.4953491,23.8136134 L12.4953491,23.8136134 L12.5071152,23.8096931 L12.6106902,23.7357139 L12.6232938,23.7196733 L12.6232938,23.7196733 L12.6266527,23.7031097 L12.609561,23.275831 C12.6075724,23.2657013 12.6010112,23.2592993 12.5934901,23.257841 L12.5934901,23.257841 Z M12.8583906,23.1452862 L12.8445485,23.1473072 L12.6598443,23.2396597 L12.6498822,23.2499052 L12.6498822,23.2499052 L12.6471943,23.2611114 L12.6650943,23.6906389 L12.6699349,23.7034178 L12.6699349,23.7034178 L12.678386,23.7104931 L12.8793402,23.8032389 C12.8914285,23.8068999 12.9022333,23.8029875 12.9078286,23.7952264 L12.9118235,23.7811639 L12.8776777,23.1665331 C12.8752882,23.1545897 12.8674102,23.1470016 12.8583906,23.1452862 L12.8583906,23.1452862 Z M12.1430473,23.1473072 C12.1332178,23.1423925 12.1221763,23.1452606 12.1156365,23.1525954 L12.1099173,23.1665331 L12.0757714,23.7811639 C12.0751323,23.7926639 12.0828099,23.8018602 12.0926481,23.8045676 L12.108256,23.8032389 L12.3092106,23.7104931 L12.3186497,23.7024347 L12.3186497,23.7024347 L12.3225043,23.6906389 L12.340401,23.2611114 L12.337245,23.2485176 L12.337245,23.2485176 L12.3277531,23.2396597 L12.1430473,23.1473072 Z"
                                fillRule="nonzero"
                              >
                                {" "}
                              </path>{" "}
                              <path
                                d="M8.11081,7.06056 C7.52502,6.47477 7.52502,5.52502 8.11081,4.93924 L10.8685,2.18152 C11.4934,1.55668 12.5064,1.55668 13.1313,2.18152 L15.889,4.93924 C16.4748,5.52502 16.4748,6.47477 15.889,7.06056 C15.3032,7.64634 14.3534,7.64634 13.7677,7.06056 L13.4999,6.79279 L13.4999,10.4999 L17.2069,10.4999 L16.9392,10.2322 C16.3535,9.6464 16.3535,8.69665 16.9392,8.11087 C17.525,7.52508 18.4748,7.52508 19.0606,8.11087 L21.8183,10.8686 C22.4431,11.4934 22.4431,12.5065 21.8183,13.1313 L19.0606,15.889 C18.4748,16.4748 17.525,16.4748 16.9392,15.889 C16.3534,15.3033 16.3534,14.3535 16.9392,13.7677 L17.2071,13.4999 L13.4999,13.4999 L13.4999,17.207 L13.7677,16.9392 C14.3534,16.3534 15.3032,16.3535 15.889,16.9392 C16.4748,17.525 16.4748,18.4748 15.889,19.0606 L13.1313,21.8183 C12.5064,22.4431 11.4934,22.4431 10.8685,21.8183 L8.11081,19.0606 C7.52502,18.4748 7.52502,17.525 8.11081,16.9392 C8.69659,16.3535 9.64634,16.3535 10.2321,16.9392 L10.4999,17.207 L10.4999,13.4999 L6.79273,13.4999 L7.06056,13.7677 C7.64634,14.3535 7.64634,15.3033 7.06056,15.889 C6.47477,16.4748 5.52502,16.4748 4.93924,15.889 L2.18152,13.1313 C1.55668,12.5065 1.55668,11.4934 2.18152,10.8686 L4.93924,8.11087 C5.52502,7.52508 6.47477,7.52508 7.06056,8.11087 C7.64634,8.69665 7.64634,9.6464 7.06056,10.2322 L6.79285,10.4999 L10.4999,10.4999 L10.4999,6.79279 L10.2321,7.06056 C9.64634,7.64634 8.69659,7.64634 8.11081,7.06056 Z"
                                fill="#3F2B89"
                              >
                                {" "}
                              </path>{" "}
                            </g>{" "}
                          </g>{" "}
                        </g>{" "}
                      </g>
                    </svg>
                    <p className={classes.choosen_show_text}>Change Location</p>
                  </div>
                ) : null}
                {!this.state.errorZipCode && dataBox && venues.length ? (
                  <div
                    className={`${classes.choosen_place} ${classes2.extend_height} pl-5 pr-5`}
                  >
                    <h6 className={classes2.choosen_h6}>
                      {venues[slider_index].venue_name}
                    </h6>
                    <div className={classes.choosen_flex}>
                      <button
                        className={`${classes2.slider_btn}  ${classes2.slider_control_left} `}
                        onClick={() => this.slideControl(-1)}
                      >
                        <img src={ChevronLeft} alt="icon" />
                      </button>
                      <button
                        className={`${classes2.slider_btn} ${classes2.slider_control_right} `}
                        onClick={() => this.slideControl(1)}
                      >
                        <img src={ChevronRight} alt="icon" />
                      </button>
                      <div className={classes2.choosen_item}>
                        <p className={classes.choosen_item_p}>Images:</p>
                        <div>
                          {venues.map((el, i) => (
                            <img
                              key={i}
                              src={el.venue_img}
                              alt=""
                              className={
                                i === slider_index ? classes2.active_img : null
                              }
                            />
                          ))}
                        </div>
                      </div>
                      <div className={classes2.choosen_item}>
                        <p className={classes.choosen_item_p}>Contact:</p>
                        <div className={classes2.contacts}>
                          <ul>
                            <li>
                              <img src={Location} alt="" />{" "}
                              <span>{venues[slider_index].venue_address}</span>
                            </li>
                            <li>
                              <img src={Mobile} alt="" />{" "}
                              <span>{venues[slider_index].venue_phone}</span>
                            </li>
                            <div className="d-flex w-100">
                              {venues[slider_index].social_media.map(
                                (el, i) => (
                                  <li key={i} className="mr-2">
                                    <a
                                      href={
                                        venues[slider_index].social_media[0]
                                          .link
                                      }
                                      target="_blank"
                                    >
                                      <img
                                        src={
                                          el.social_media === "instagramm"
                                            ? Instagramm
                                            : Facebook
                                        }
                                        alt=""
                                      />{" "}
                                    </a>
                                  </li>
                                )
                              )}
                            </div>
                            <li className={classes2.category}>
                              <span>Category of people:</span>
                              <div className="d-flex flex-wrap">
                                {venues[slider_index].category_of_people
                                  .split(/"|,/g)
                                  .filter((el) => el !== "")
                                  .map((el, i) => (
                                    <p key={i}>{el}</p>
                                  ))}
                              </div>
                            </li>

                            {/*<li><a href={venues[slider_index].social_media[0].link } target='_blank'>
                              <img src={Instagramm} alt="" />{" "}
                              <span>
                                {venues[slider_index].social_media[0].link}
                              </span>
                              </a>
                            </li>
                            <li>
                            <a  href={venues[slider_index].social_media[1].link}  target='_blank'>
                              <img src={Facebook} alt="" />{" "}
                              <span>
                                {venues[slider_index].social_media[1].link}
                              </span>
                              </a>
                </li>*/}
                          </ul>
                        </div>
                      </div>
                      <div className={classes2.choosen_item}>
                        <p className={classes.choosen_item_p}>Statistics:</p>
                        <div className={classes2.statistics}>
                          <ul>
                            <li>
                              <span>Avarage check size:</span>
                              <p>{venues[slider_index].average_check_size}</p>
                            </li>
                            <li>
                              <span className={classes2.daily_visits}>
                                Daily visit:
                              </span>
                              <p>{venues[slider_index].average_daily_visits}</p>
                            </li>
                            <li>
                              <span>Avarage dwell time</span>
                              <p>{venues[slider_index].average_dwell_time}</p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className={classes2.checkbox}>
                      <input
                        type="checkbox"
                        id="ad-manager"
                        onChange={(e) => {
                          const checked = e.target.checked;
                          this.props.addRemoveRestaurants(
                            venues[slider_index],
                            checked
                          );
                        }}
                        checked={
                          this.props.selectedRestaurants.findIndex(
                            (el) =>
                              el.venue_name === venues[slider_index].venue_name
                          ) >= 0
                        }
                      />
                      <label htmlFor="ad-manager">
                        <span> Add</span>
                      </label>
                    </div>
                    <button
                      type="button"
                      className={classes.choosen_close}
                      onClick={() =>
                        this.setState({
                          ...this.state,
                          dataBox: false,
                        })
                      }
                    >
                      <img
                        src={Close}
                        alt=""
                        className={classes.choosen_close_img}
                      />
                    </button>
                  </div>
                ) : null}

                {selectedRestaurants.length ? (
                  <button
                    style={{
                      background: "transparent",
                      border: "none",
                      pointerEvents: "all",
                    }}
                    onClick={() => {
                      this.setState((state) => ({
                        ...state,
                        checkout: true,
                      }));
                    }}
                  >
                    <div className={`${classes.show_create_ad_place} w-100`}>
                      <p className={classes.choosen_create_ad_text}>
                        Card {selectedRestaurants.length}
                      </p>
                    </div>
                  </button>
                ) : null}
                {!dataBox ? (
                  <div
                    className={classes.show_place}
                    onClick={
                      venues
                        ? () => {
                            this.setState({ dataBox: true });
                          }
                        : null
                    }
                  >
                    <p className={classes.choosen_show_text}>
                      {venues.length
                        ? "Show Venue Data"
                        : "no venues for this city"}
                    </p>
                  </div>
                ) : null}
              </div>
            </React.Fragment>
          )}
        </div>
        <Checkout
          show={checkout}
          toggleModal={() =>
            this.setState((state) => ({
              ...state,
              checkout: false,
            }))
          }
          restaurants={selectedRestaurants}
          removeRestaurantFromCart={this.removeRestaurantFromCart}
        />

        <LocationModal
          modalStatus={locationModal}
          country={country}
          city={city}
          closeModal={(state, country, city) => {
            if (!state || country === undefined || city === undefined) {
              this.setState({
                ...this.state,
                locationModal: false,
              });
              return;
            }
            this.changeToDifferentCity(country, city);
          }}
        />
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  const selectedRestaurants = state.PlaceIQ.restaurants;
  return { selectedRestaurants };
};
export default connect(mapStateToProps, {
  addRemoveRestaurants,
  selectLocation,
})(withRouter(PlaceIQ));
