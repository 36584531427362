import { SELECTLOCTION, ADDREMOVERESTAURANTS } from "./actionTypes";

const initialState = {
  restaurants: [],
 country: '',
 city: '',
};

const PlaceIQ = (state = initialState, action) => {
  switch (action.type) {
    case ADDREMOVERESTAURANTS:
      const { restaurant, checked } = action.payload;
      const { restaurants } = state;

      const index = restaurants.findIndex(
        (el) => el.venue_name === restaurant.venue_name
      );
      const left = restaurants.slice(0, index);
      const right = restaurants.slice(index + 1, restaurants.length);

      state = {
        ...state,
        restaurants: checked
          ? [...state.restaurants, restaurant]
          : [...left, ...right],
      };
      break;
  case SELECTLOCTION:
      const {country, city} = action.payload 
      
      state = {
        ...state,
        country, city
      };
      break
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default PlaceIQ;
