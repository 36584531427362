import React from "react";

import classes from "../../../assets/css/hypeIQ/index.module.scss";
import ArrowRight from "../../../assets/css/hypeIQ/arrow-right.svg";

import VerifyData from "./verify-data";

import { connect } from "react-redux";
import { campaignBudget } from "../../../store/actions";
import axios from "axios";

class Budget extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      verify: false,
      calculating: true,
      data: {
        budget: 0,
      },
    };
    this.backToBudget = this.backToBudget.bind(this);
  }

  componentDidMount() {
    const {
      //specialists_price,
      target_shares,
      target_followers,
      pr_budget,
      branded_city_budget,
      country,
    } = this.props;

    axios
      .post(`https://backendapp.murmurcars.com/api/v1/hypeiq/calculate-budget/${country}`, {
        //specialists_price,
        target_shares,
        target_followers,
        pr_budget,
        branded_city_budget,
      })
      .then((response) => {
        const { price } = response.data;
        this.setState((state) => ({
          ...state,
          calculating: false,
          data: {
            budget: price,
          },
        }));
      })
      .catch((err) => {
        this.setState((state) => ({
          ...state,
          calculating: false,
        }));
      });
  }
  next = (form) => {
    this.props.nextForm({ form });
  };

  backToBudget() {
    this.setState((state) => {
      return {
        ...state,
        verify: false,
      };
    });
  }

  render() {
    const { verify, data, calculating } = this.state;

    const { update } = this.props;

    const disabled = data.budget ? false : true;
    return (
      <>
        {verify ? (
          <VerifyData backToBudget={this.backToBudget} />
        ) : (
          <div className={classes.links_budget}>
            <div className={classes.budget_field}>
              <label>Price for your Hype</label>
              {calculating ? (
                <div>
                  <div className="spinner_container">
                    <div
                      className="spinner-border text-primary"
                      role="status"
                    />
                  </div>
                </div>
              ) : (
                <strong>{data.budget}</strong>
              )}
              <button
                onClick={() => {
                  this.setState((state) => {
                    return {
                      ...state,
                      verify: true,
                    };
                  });
                }}
              >
                See what you typed <img src={ArrowRight} />{" "}
              </button>
            </div>
            <div className={classes.btns}>
              <button onClick={() => this.next("links")}>Back</button>
              <button
                disabled={disabled}
                className={disabled ? "bg-secondary" : null}
                onClick={() => {
                  this.props.campaignBudget(data.budget);
                  this.props.submit(false, data.budget);
                }}
              >
                {update ? "Update" : "I want it"}
              </button>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default connect(null, { campaignBudget })(Budget);
