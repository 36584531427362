import React from "react";

import { Modal, ModalBody, ModalHeader, Row, Col } from "reactstrap";


import classes from "../../../../assets/css/hypeIQ/modal.module.scss";
import CloseBtn from "../../../../assets/css/common/icons/close.svg";
import Simple from "../../../../assets/css/hypeIQ/simple.png";
import Premium from "../../../../assets/css/hypeIQ/premium.png";
import OwnPlan from "../../../../assets/css/hypeIQ/own.png";

import { connect } from "react-redux";

class BrandedCity extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      packages: {
        simple: false,
        premium: false,
        customised: false,
      },
      budget: null,
    };
  }
  render() {
    const { show, toggle, country } = this.props;
    const { packages, budget } = this.state;
    const { simple, premium, customised } = packages;

    const Package = simple ? "Simple" : premium ? "Premium" : "Own Plan";
    
    return (
      <Modal
        isOpen={show}
        toggle={toggle}
        className={`${classes.modal} ${classes.branded}`}
      >
        <ModalHeader>
          <div className={classes.modal_header}>
            <span>What is branded city?</span>{" "}
            <button onClick={() => toggle(false)}>
              <img src={CloseBtn} alt="" />
            </button>
          </div>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col className="d-flex flex-column align-items-center">
              <h1 className={classes.branded_city}>Branded city</h1>
              <div className={classes.branded_city_imgs}>
                <div className={classes.branded_img} />
                <div className={classes.branded_img} />
                <div className={classes.branded_img} />
              </div>

              <div className={classes.whatis_branded}>
                <p>
                  Welcome to the Branded City Our clients want their brands to
                  create hype. And we say 'Let's advertise it literally
                  everywhere in the city Sounds surreal? With 'Branded City',
                  everything becomes reality. Via 'Branded City's' projectors,
                  your commercial will be shown literally everywhere in the
                  city. Buildings, pavements, walls and many more options are
                  available for your promotion. Let's take the city by your ad
                </p>
              </div>

              <div className={classes.branded_city_packages}>
                <label className={classes.package} htmlFor="simple">
                  <div>
                    <img src={Simple} alt="" />
                    <div className={classes.custom_radio_cont}>
                      <div
                        className={`${classes.custom_radio_rim} ${
                            simple
                            ? classes.custom_radio_rim_active
                            : null
                        }`}
                      >
                        <input
                          type="radio"
                          id="simple"
                          name="package"
                          onClick={() => {
                            this.setState((state) => ({
                              packages: {
                                simple: !state.simple,
                                premium: false,
                                customised: false,
                              },
                              budget: 550,
                            }));
                          }}
                        />
                        <span
                          className={`${classes.custom_radio} ${
                             simple
                              ? classes.custom_radio_active
                              : null
                          }`}
                        />
                      </div>
                    </div>
                  </div>
                  <h5>Simple</h5>
                  <span className={classes.package_price}>
                    550{country === 'Azerbaijan' ? '₼' : '$'} <span>/month</span>
                  </span>
                </label>
                <label className={classes.package} htmlFor="premium">
                  <div>
                    <img src={Premium} alt="" />
                    <div className={classes.custom_radio_cont}>
                      <div
                        className={`${classes.custom_radio_rim} ${
                            premium
                            ? classes.custom_radio_rim_active
                            : null
                        }`}
                      >
                        <input
                          type="radio"
                          id="premium"
                          name="package"
                          onClick={() => {
                            this.setState((state) => ({
                              packages: {
                                simple: false,
                                premium: !state.premium,
                                customised: false,
                              },
                              budget: 2000
                            }));
                          }}
                        />
                        <span
                          className={`${classes.custom_radio} ${
                  
                             premium
                              ? classes.custom_radio_active
                              : null
                          }`}
                        />
                      </div>
                    </div>
                  </div>
                  <h5>Premuium</h5>
                  <span className={classes.package_price}>
                    2200{country === 'Azerbaijan' ? '₼' : '$'} <span>/month</span>
                  </span>
                </label>
                <label className={classes.package} htmlFor="customised">
                  <div>
                    <img src={OwnPlan} alt="" />
                    <div className={classes.custom_radio_cont}>
                    <div
                        className={`${classes.custom_radio_rim} ${
                        customised
                            ? classes.custom_radio_rim_active
                            : null
                        }`}
                      >
                        <input
                          type="radio"
                          id="customised"
                          name="package"
                          onClick={() => {
                            this.setState((state) => ({
                              packages: {
                                simple: false,
                                premium: false,
                                customised: !state.customised,
                              },
                    
                            }));
                          }}
                        />
                        <span
                       className={`${classes.custom_radio} ${
                     customised
                          ? classes.custom_radio_active
                          : null
                      }`}
                        />
                      </div>
                    </div>
                  </div>
                  <h5>Own plan</h5>
                </label>
              </div>

              <div className={classes.btns}>
                <button
                  type="button"
                  onClick={() => {
                    toggle(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={() => {
                    toggle(true, Package, budget);
                  }}
                >
                  Save
                </button>
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    );
  }
}
const mapStateToProps = (state) => {
  const { branded_city_package } = state.HypeIQ;
  return { branded_city_package };
};
export default connect(mapStateToProps, null)(BrandedCity);
