import React from "react";

import classes from "../../../assets/css/personaAI/index.module.scss";

class MarketingPlan extends React.Component {
  constructor(props) {
    super(props);
    this.business = React.createRef();
    this.marketing = React.createRef();
    this.currency = React.createRef();
    this.budget = React.createRef();
    this.website = React.createRef();
    this.description = React.createRef();
  }
  render() {
    const {sendPromptToChatGPT} = this.props
    return (
      <main className={classes.category}>
        <header className={classes.category_hero}>
          <h2>Marketing plan</h2>
          <p>Fill inputs below and click button</p>
        </header>
        <section>
          <form
            className={classes.category_form}
            onSubmit={(e) => {
              e.preventDefault();
              const { value: business } = this.business?.current;
              const { value: marketing } = this.marketing?.current;
              const { value: currency } = this.currency?.current;
              const { value: budget } = this.budget?.current;
              const { value: website } = this.website?.current;
              const { value: description } = this.description?.current;

              const prompt = `Create marketing plan for ${business} that does ${description} with ${budget}${currency} budget and wants to exploy the ${marketing} type.`; //to chatGpt

              sendPromptToChatGPT(
                "Marketing plan",
                { business, currency,budget,marketing,website, author: "user" },
                prompt
              );
            }}
          >
            <div>
              <div>
                <div>
                  <label htmlFor="business-name">Business name</label>
                  <input
                    placeholder="Type here"
                    id="business-name"
                    ref={this.business}
                  />
                </div>
                <div>
                  <label htmlFor="marketing-type">Marketing type</label>
                  <select id="marketing-type" ref={this.marketing}>
                    <option value="null">Choose</option>
                    <option value="social marketing">Social Marketing</option>
                    <option value="service Marketing">Service Marketing</option>
                    <option value="direct Marketing">Direct Marketing</option>
                  </select>
                </div>
                <div>
                  <label htmlFor="budget">Budget</label>
                  <span className={classes.combination}>
                    <select ref={this.currency}>
                      <option value="usd">USD</option>
                      <option value="azn">AZN</option>
                      <option value="eur">EUR</option>
                    </select>
                    <input
                      placeholder="Type here"
                      id="budget"
                      ref={this.budget}
                    />
                  </span>
                </div>
              </div>
              <div>
                <div>
                  <label htmlFor="website-name">Name of website</label>
                  <input
                    placeholder="Type here"
                    id="website-name"
                    ref={this.website}
                  />
                </div>
                <div>
                  <label htmlFor="description">Description</label>
                  <textarea
                    id="description"
                    placeholder="Type here"
                    className={classes.height_lg}
                    ref={this.description}
                  />
                </div>
              </div>
            </div>
            <button type="submit">
              Start using{" "}
              <svg
                width="21"
                height="20"
                viewBox="0 0 21 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.8337 1.66666L9.66699 10.8333M18.8337 1.66666L13.0003 18.3333L9.66699 10.8333M18.8337 1.66666L2.16699 7.49999L9.66699 10.8333"
                  stroke="white"
                  strokeWidth="1.67"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </form>
        </section>
      </main>
    );
  }
}

export default MarketingPlan;
