import React, { Component } from "react";

import {
  Map,
  GoogleApiWrapper,
  Marker,
  Polygon,
  InfoWindow,
} from "google-maps-react";

import googleMapStyles from "../../components/Common/google-styles";
import "../../assets/css/common/css/google-map.css";
import Venue from "../../assets/images/venue.gif";

import Geocode from "react-geocode";

const GOOGLE_MAP_KEY = process.env.REACT_APP_GOOGLEMAPKEY;

Geocode.setApiKey(GOOGLE_MAP_KEY);

const LoadingContainer = (props) => (
  <div id="preloader">
    <div id="status">
      <div className="spinner-chase">
        <div className="chase-dot"></div>
        <div className="chase-dot"></div>
        <div className="chase-dot"></div>
        <div className="chase-dot"></div>
        <div className="chase-dot"></div>
        <div className="chase-dot"></div>
      </div>
    </div>
  </div>
);

class GoogleMap extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { deletePolygonFromStack, state } = this.props;
    const {markers, coordinates} = state

    return (
      <Map
        google={this.props.google}
        zoom={state.zoom}
        styles={this.props.mapStyle}
        initialCenter={state.center}
        fullscreenControl={false}
        draggable={true}
        center={state.postCenter}
        scrollwheel={true}
        mapTypeControl={false}
        disableDoubleClickZoom={false}
        disableDefaultUI={true}
        keyboardShortcuts={false}
      >
        {/*{this.handleGEOMarkers()}*/}
        {coordinates.map((polygons, i) => (
          <Polygon
            key={i}
            paths={polygons}
            fillColor="#7356bd"
            fillOpacity={0.35}
            //fillOpacity="0.5"
            strokeOpacity={0.8}
            strokeWeight={1}
            strokeColor="#200b47"
            onClick={() => deletePolygonFromStack(i)}
          />
        ))}
        {markers ? markers.map((el, index) => (
          <Marker
            key={index}
            icon={Venue }
            position={el.position}
            animation={window.google.maps.Animation.DROP}
          />
        )) : null}
      </Map>
    );
  }
}

GoogleMap.defaultProps = googleMapStyles;

export default GoogleApiWrapper({
  apiKey: GOOGLE_MAP_KEY,
  LoadingContainer: LoadingContainer,
})(GoogleMap);
