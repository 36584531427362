import React from "react";

import classes2 from "../../../assets/css/hypeIQ/index.module.scss";
import User from "../../../assets/css/common/icons/user.svg";
import Add from "../../../assets/css/hypeIQ/add.svg";
import "../../../assets/css/common/css/info.scss";
import info_circle from "../../../assets/css/hypeIQ/info.svg";
import ArrowDown from "../../../assets/css/CreateAd/arrow-down.svg";

import { Row, Col } from "reactstrap";

import { withRouter } from "react-router-dom";

import { connect } from "react-redux";
import {
  brandedCity,
  addProfecionals,
  titleCampaighnDescription,
  assignProffecionals,
} from "../../../store/actions";

import BrandedCity from "./modals/branded_city";

import { Tooltip } from "../../../components/tooltip";

class HypeTitle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      //influencers: null,
      branded_city_modal: null,
      customLocation: false,
      data: {
        branded_city: null,
        title: null,
        description: null,
        country: null,
        city: null,
        location: null,
      },
      areas: {
        Baku: [
          "nizami",
          "nasimi",
          "khazar",
          "sabunchu",
          "qaradaq",
          "binaqadi",
          "narimanov",
          "sabayil",
          "pirallahı",
          "xətai",
          "yasamal",
          "suraxanı",
        ],
        Tbilisi: [
          "chughureti",
          "didube",
          "eldani",
          "isani",
          "krtsanisi",
          "mtatsminda",
          "nadzaladevi",
          "saburtalo",
          "samgori",
          "vake",
        ],
      },
      cities: {
        Azerbaijan: ["Baku"],
        Georgia: ["Tbilisi"],
        Turkiye: ["Stambul"],
        US: ["Chicago", "San Francisco"],
      },
    };

    this.influencers_work_description = React.createRef(null);
    this.influencers_hype_target = React.createRef(null);
  }

  componentDidMount() {
    const {
      branded_city,
      hype_title,
      hype_description,
      country,
      city,
      location,
    } = this.props.data;
    this.setState((state) => ({
      ...state,
      data: {
        branded_city,
        title: hype_title,
        description: hype_description,
        country,
        city,
        location,
      },
    }));
  }

  brandedCity = (e) => {
    const value = e.target.value;

    
    if (value == null) {
      return;
    }
    if (value === "no") {
      this.props.brandedCity(false, null, null);
    }
    this.setState((state) => {
      return {
        ...state,
        branded_city_modal:
          value === "yes" || value === "modify" ? true : false,
        data: {
          ...state.data,
          branded_city: value === "yes" || value === "modify" ? true : false,
        },
      };
    });
  };

  next = (form) => {
    const { title, description, country, city, location } = this.state.data;
    /*const {
      hype_title,
      hype_description,
      country: Country,
      city: City,
    } = this.props.data;*/

    this.props.titleCampaighnDescription({
      title,
      description,
      country,
      city,
      location,
    });
    this.props.nextForm({ form });
  };

  render() {
    const { branded_city_modal, data, areas, cities, customLocation } =
      this.state;
    const { title, description, city, country, location, branded_city } = data;

    const disabled =
      title == null || country == null || city == null || branded_city == null
        ? true
        : false;

    
    return (
      <>
        <Row>
          <Col className={classes2.custom_cols}>
            <span className={`${classes2.input_field} mb-3`}>
              <label htmlFor="title">Title*</label>
              <input
                id="title"
                placeholder="Type title here"
                defaultValue={title}
                onChange={(e) => {
                  const value = e.target.value.trim();
                  this.setState((state) => {
                    return {
                      ...state,
                      data: {
                        ...this.state.data,
                        title: value,
                      },
                    };
                  });
                }}
              />
              <img src={User} alt="" />
            </span>
            <span>
              <label>Description</label>
              <textarea
                placeholder="Select variant Weight"
                defaultValue={description}
                onChange={(e) => {
                  const value = e.target.value.trim();
                  this.setState((state) => {
                    return {
                      ...state,
                      data: {
                        ...this.state.data,
                        description: value,
                      },
                    };
                  });
                }}
              />
            </span>
          </Col>
          <Col className={classes2.custom_cols}>
            <div className="mb-3">
              <span className={classes2.label_field}>
                <label>Select Country*</label>
              </span>
              <span className={`${classes2.select_field}`}>
                <select
                  onChange={(e) => {
                    const value = e.target.value;

                    this.setState((state) => ({
                      ...state,
                      data: {
                        ...state.data,
                        country: value,
                        city: null,
                      },
                    }));
                  }}
                >
                  {country != null ? (
                    <option value={country}>{country}</option>
                  ) : (
                    <option value="null">Select</option>
                  )}
                  {["Azerbaijan", "Georgia", "Turkiye", "US"].map((loc, i) => {
                    if (loc !== country) {
                      return (
                        <option key={i} value={loc}>
                          {loc}
                        </option>
                      );
                    }
                  })}
                </select>
                <img src={ArrowDown} alt="" />
              </span>
            </div>
            {country != null ? (
              <div className="mb-3">
                <span className={classes2.label_field}>
                  <label>Select City*</label>
                </span>
                <span className={`${classes2.select_field}`}>
                  <select
                    id="city"
                    onChange={(e) => {
                      const value = e.target.value;

                      this.setState((state) => ({
                        ...state,
                        data: {
                          ...state.data,
                          city: value,
                          location: null,
                        },
                      }));
                    }}
                  >
                    {city != null ? (
                      <option value={city}>{city}</option>
                    ) : (
                      <option value="null">Select</option>
                    )}
                    {cities[country].map((loc, i) => {
                      if (loc !== city) {
                        return (
                          <option key={i} value={loc}>
                            {loc}
                          </option>
                        );
                      }
                    })}
                  </select>
                  <img src={ArrowDown} alt="" />
                </span>
              </div>
            ) : null}
            {city != null ? (
              <div className="mb-3">
                <span className={classes2.label_field}>
                  <label>Select Location</label>
                </span>
                {city in areas && !customLocation ? (
                  <span className={`${classes2.select_field}`}>
                    <select
                      onChange={(e) => {
                        const value = e.target.value;
                        this.setState((state) => {
                          const nextState = {
                            ...state,
                            data: {
                              ...state.data,
                              location: value,
                            },
                          };
                          if (value === "custom") {
                            nextState.customLocation = true;
                            nextState.data.location = null;
                          }
                          return {
                            ...nextState,
                          };
                        });
                      }}
                    >
                      {location != null ? (
                        <option value={location}>{location}</option>
                      ) : (
                        <option value="null">Select</option>
                      )}
                      {areas[city].map((loc, i) => {
                        if (loc !== location) {
                          return (
                            <option key={i} value={loc}>
                              {loc}
                            </option>
                          );
                        }
                      })}
                      <option value="custom">Choose other location</option>
                    </select>
                    <img src={ArrowDown} alt="" />
                  </span>
                ) : (
                  <span className={`${classes2.input_field} mb-3`}>
                    <input
                      id="location"
                      placeholder="Type location here"
                      defaultValue={location}
                      onChange={(e) => {
                        const value = e.target.value.trim();
                        this.setState((state) => {
                          return {
                            ...state,
                            data: {
                              ...this.state.data,
                              location: value,
                            },
                          };
                        });
                      }}
                    />
                    {city in areas && location ? (
                      <img
                        src={Add}
                        alt=""
                        id="custom"
                        onClick={() => {
                          this.setState((state) => ({
                            ...state,
                            customLocation: false,
                          }));
                        }}
                      />
                    ) : null}
                  </span>
                )}
              </div>
            ) : null}
            {country ? (
              <span>
                <span className={classes2.label_field}>
                  <label>Do you need Branded City?</label>
                  <img src={info_circle} alt="" id="branded-city" />
                  <div className="info">
                    <Tooltip target={`branded-city`}>
                      <div className="tooltip-cont">
                        The world of mouth works via 'Branded City's projectors.
                        By choosing this option, prepare to see your brand's
                        commercials all around the city, e.g. on the buildings
                        ,pavements, walls of stadiums on a daily basis and
                        during the biggest events.
                      </div>
                    </Tooltip>
                  </div>
                </span>
                <span className={classes2.select_field}>
                  <select
                    onChange={this.brandedCity}
                    value={
                      branded_city == null //first u seee select
                        ? "null"
                        : branded_city
                        ? "yes" //then depends on redux reducer state
                        : "no"
                    }
                  >
                    <option value="null">Select</option>
                    <option value="yes">Yes</option>
                    {branded_city ? (
                      <option value="modify">Modify</option>
                    ) : null}
                    <option value="no">No</option>
                  </select>
                  <img src={ArrowDown} alt="" />
                </span>
              </span>
            ) : null}
          </Col>{" "}
        </Row>

        <button
          disabled={disabled}
          className={disabled ? "bg-secondary" : null}
          onClick={() => this.next("campaign_target")}
        >
          Next
        </button>

        <BrandedCity
          show={branded_city_modal}
          toggle={(branded, Package, sum) => {
            this.setState((state) => {
              return {
                ...state,
                branded_city_modal: false,
              };
            });
            if (branded === true) {
              this.props.brandedCity(branded, Package, sum);
            }
          }}
          country={country}
        />
      </>
    );
  }
}

export default connect(null, {
  brandedCity,
  addProfecionals,
  titleCampaighnDescription,
  assignProffecionals,
})(withRouter(HypeTitle));
