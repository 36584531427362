import React, { Component } from "react";

import {
  Map,
  GoogleApiWrapper,
  Marker,
  Polygon,
  InfoWindow,
} from "google-maps-react";

import googleMapStyles from "./google-styles";
import Billboard from "../../assets/css/common/icons/billboard.svg";

import Geocode from "react-geocode";

const GOOGLE_MAP_KEY = process.env.REACT_APP_GOOGLEMAPKEY;
Geocode.setApiKey(GOOGLE_MAP_KEY);

const LoadingContainer = (props) => (
  <div id="preloader">
    <div id="status">
      <div className="spinner-chase">
        <div className="chase-dot"></div>
        <div className="chase-dot"></div>
        <div className="chase-dot"></div>
        <div className="chase-dot"></div>
        <div className="chase-dot"></div>
        <div className="chase-dot"></div>
      </div>
    </div>
  </div>
);

class GoogleMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Coords: {},
      streetIQCardStatus: true,
      activeMarker: {},
      selectedPlace: {},
      showingInfoWindow: false,
      markerGeoCode: {},
    };
  }
  onMarkerClick = (props, marker) =>
    this.setState({
      activeMarker: marker,
      selectedPlace: props,
      showingInfoWindow: true,
    });

  onInfoWindowClose = () =>
    this.setState({
      activeMarker: null,
      showingInfoWindow: false,
    });

  onMapClicked = () => {
    if (this.state.showingInfoWindow)
      this.setState({
        activeMarker: null,
        showingInfoWindow: false,
      });
  };

  render() {
    const { segment, state } = this.props;

    const { markers } = state;

    return (
      <Map
        google={this.props.google}
        zoom={16}
        styles={this.props.mapStyle}
        initialCenter={state.center}
        fullscreenControl={false}
        draggable={true}
        center={state.postCenter}
        scrollwheel={true}
        mapTypeControl={false}
        disableDoubleClickZoom={false}
        disableDefaultUI={true}
        keyboardShortcuts={false}
        maxZoom={12}
        minZoom={12}
        //onDragend={(coord) => this.centerMoved(coord)}
      >
        {segment &&
          markers[segment].map((place, index) => (
            <Marker
              key={index}
              name={place.name}
              position={place.position}
              population={place.population}
              noise_level={place.noise_level}
              venue_name={place.venue_name}
              venue_rating={place.venue_rating}
              venue_email={place.venue_email}
              venue_phone={place.venue_phone}
              venue_img={place.venue_artWork_url}
              aiq={place.aiq}
              icon={segment === "billboards" ? Billboard :  place.image}
              onMouseover={this.onMarkerClick}
            />
          ))}
        <InfoWindow
          marker={this.state.activeMarker}
          onClose={this.onInfoWindowClose}
          visible={this.state.showingInfoWindow}
        >
          <div>
            <h6> Location: {this.state.selectedPlace.name}</h6>
            {segment === "pedestrian" ? (
              <h6>
                {" "}
                Population Dencity: {this.state.selectedPlace.population}
              </h6>
            ) : segment === "air_quality" ? (
              <h6> Air Quality: {this.state.selectedPlace.aiq}</h6>
            ) : segment === "noise_level" ? (
              <h6> noise level: {this.state.selectedPlace.noise_level}</h6>
            ) : segment === "billboards" ? (
              <>
                <h6> venue: {this.state.selectedPlace.venue_name}</h6>
                <h6> rating: {this.state.selectedPlace.venue_rating}</h6>
                <h6> email: {this.state.selectedPlace.venue_email}</h6>
                <h6> mobile: {this.state.selectedPlace.venue_phone}</h6>
                <img src={this.state.selectedPlace.venue_img} alt="" />
              </>
            ) : null}
          </div>
        </InfoWindow>
      </Map>
    );
  }
}

GoogleMap.defaultProps = googleMapStyles;

export default GoogleApiWrapper({
  apiKey: GOOGLE_MAP_KEY,
  LoadingContainer: LoadingContainer,
})(GoogleMap);
