import React from "react";

import classes from "../../../assets/css/hypeIQ/index.module.scss";
import Attach from "../../../assets/css/hypeIQ/attach-circle.svg";
import ArrowRight from "../../../assets/css/hypeIQ/arrow-right-white.svg";
import Backup from "../../../assets/css/CreateAd/backup.svg";
import Insta from "../../../assets/css/hypeIQ/socials/instagram.svg";
import Facebook from "../../../assets/css/hypeIQ/socials/Facebook.svg";
import Tiktok from "../../../assets/css/hypeIQ/socials/tiktok.svg";
import Delete from "../../../assets/css/common/icons/close.svg";
import Trash from '../../../assets/css/common/icons/trashRed.svg'

import { connect } from "react-redux";
import {
  uploadSocialMediaLinks,
  uploadBannerAndPostLink,
  deleteBanner
} from "../../../store/actions";

import { Upload } from "antd";
import SocialMedia from "./modals/social-links";
const { Dragger } = Upload;

class HypeLinks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      social_media_modal: false,
      data: {
        banner: null,
        banner_file: null,
        banner_name: null,
        post_link: null,
      },
    };
    this.instagramm = React.createRef(null);
    this.facebook = React.createRef(null);
    this.tiktok = React.createRef(null);
  }
  next = (next, form) => {
    if (next) {
      const {
        banner_file: banner_file_loc,
        banner_name: banner_name_loc,
        post_link: post_link_loc,
        banner: banner_link_loc,
      } = this.state.data;

      const { banner_file, banner_name, post_link, banner_link } =
        this.props.data;
      const body = {
        banner_file: banner_file_loc == null ? banner_file : banner_file_loc,
        banner_name:
          banner_name_loc == null ? banner_name : banner_name_loc.trim(),
        post_link: post_link_loc == null ? post_link : post_link_loc,
        banner_link: banner_link_loc == null ? banner_link : banner_link_loc,
      };

      this.props.uploadBannerAndPostLink(body);
    }
    this.props.nextForm({ form });
  };
  uploadBanner = (info) => {
    const reader = new FileReader();
    reader.readAsDataURL(info.file);

    reader.onload = (e) => {
      const file = info.file;
      const banner_name = info.file.name;
      const banner = e.target.result;
      this.setState((state) => {
        return {
          ...state,
          data: {
            ...state.data,
            banner,
            banner_file: file,
            banner_name,
          },
        };
      });
    };
  };
  deleteBanner = () => {
    this.setState((state) => {
      return {
        ...state,
        data: {
          ...state.data,
          banner: null,
          banner_file: null,
          banner_name: null,
        },
      };
    });
  };
  render() {
    const { social_media_modal, data: data_loc } = this.state;
    const { post_link: post_link_loc, banner } = data_loc;
    const { data } = this.props;
    const { post_link, banner_link } = data;

    return (
      <div className={classes.links_budget}>
        <div className={classes.input_field}>
          <label>Link to the post or page</label>
          <input
            placeholder="Paste here"
            defaultValue={post_link == null ? post_link_loc : post_link}
            onChange={(e) => {
              const value = e.target.value.trim();
              this.setState((state) => {
                return {
                  ...state,
                  data: {
                    ...state.data,
                    post_link: value,
                  },
                };
              });
            }}
          />
          <img src={Attach} slt="" />
        </div>
        <div className={`${classes.input_field} ${classes.last_child}`}>
          <label>Link to social media</label>
          <button
            className="m-0"
            onClick={() => {
              this.setState((state) => {
                return {
                  ...state,
                  social_media_modal: true,
                };
              });
            }}
          >
            Add links <img src={ArrowRight} slt="" />
          </button>
        </div>
        <div className={classes.media_links}>
          {this.props.media_links.instagramm != null ? (
            <div>
              <span>
                <img src={Insta} alt="" />{" "}
                <a href={this.props.media_links.instagramm} target="_blank">
                  {this.props.media_links.instagramm}
                </a>
              </span>
              <img
                src={Delete}
                alt=""
                onClick={() => {
                  this.props.uploadSocialMediaLinks({
                    instagramm: null,
                    facebook: this.props.media_links.facebook,
                    tiktok: this.props.media_links.tiktok,
                  });
                }}
              />
            </div>
          ) : null}
          {this.props.media_links.facebook != null ? (
            <div>
              <span>
                <img src={Facebook} alt="" />{" "}
                <a href={this.props.media_links.facebook} target="_blank">
                  {this.props.media_links.facebook}
                </a>
              </span>
              <img
                src={Delete}
                alt=""
                onClick={() => {
                  this.props.uploadSocialMediaLinks({
                    instagramm: this.props.media_links.instagramm,
                    facebook: null,
                    tiktok: this.props.media_links.tiktok,
                  });
                }}
              />
            </div>
          ) : null}
          {this.props.media_links.tiktok ? (
            <div>
              <span>
                <img src={Tiktok} alt="" />{" "}
                <a href={this.props.media_links.tiktok} target="_blank">
                  {this.props.media_links.tiktok}
                </a>
              </span>
              <img
                src={Delete}
                alt=""
                onClick={() => {
                  this.props.uploadSocialMediaLinks({
                    instagramm: this.props.media_links.instagramm,
                    facebook: this.props.media_links.facebook,
                    tiktok: null,
                  });
                }}
              />
            </div>
          ) : null}
        </div>

        <div className={classes.upload_cont}>
          <label>Upload banner</label>
          <div className={classes.media_block}>
            <Dragger
              listType="picture-card"
              beforeUpload={() => false}
              showUploadList={false}
              style={{
                background: "white",
                borderColor: "transparent",
              }}
              onChange={(info) => this.uploadBanner(info)}
            >
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                  <img src={Backup} alt="" />
                  <div className={classes.drg_and_drp}>
                    <h4 className={classes.media_h4}>Drag & Drop</h4>
                    <p className={classes.media_p}>
                      Drag or click here to upload
                    </p>
                  </div>
                </div>
                <button type="button" className={classes.media_upload_btn}>
                  Upload Image
                </button>
              </div>
            </Dragger>
          </div>
        </div>
        {banner != null || banner_link != null ? (
          <div className={classes.banner_container}>
            <img src={banner != null ? banner :  banner_link} alt="" className={classes.banner} />
            <img
              src={Trash}
              alt=""
              className={classes.trash}
              onClick={banner_link == null ? this.deleteBanner : this.props.deleteBanner}
            />
          </div>
        ) : null}
        <div className={classes.btns}>
          <button onClick={() => this.next(false, "campaign_target")}>
            Back
          </button>
          <button onClick={() => this.next(true, "budget")}>See price</button>
        </div>
        <SocialMedia
          show={social_media_modal}
          instagramm={this.instagramm}
          facebook={this.facebook}
          tiktok={this.tiktok}
          toggle={(state) => {
            if (state) {
              this.props.uploadSocialMediaLinks({
                instagramm: this.instagramm.current.value.length
                  ? this.instagramm.current.value
                  : null,
                facebook: this.facebook.current.value.length
                  ? this.facebook.current.value
                  : null,
                tiktok: this.tiktok.current.value.length
                  ? this.tiktok.current.value
                  : null,
              });
            }
            this.setState((prev) => {
              return {
                ...prev,
                social_media_modal: false,
              };
            });
          }}
        />
      </div>
    );
  }
}

export default connect(null, {
  uploadSocialMediaLinks,
  uploadBannerAndPostLink,
  deleteBanner
})(HypeLinks);
