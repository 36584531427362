import React from "react";

import classes from "../../../assets/css/personaAI/index.module.scss";

class AdCopy extends React.Component {
  constructor(props) {
    super(props);
    this.platform = React.createRef();
    this.keywords = React.createRef();
    this.description = React.createRef();
  }

  render() {
    const { sendPromptToChatGPT } = this.props;
    return (
      <main className={classes.category}>
        <header className={classes.category_hero}>
          <h2>Paid ad copy</h2>
          <p>Fill inputs below and click button</p>
        </header>
        <section>
          <form
            className={classes.category_form}
            onSubmit={(e) => {
              e.preventDefault();
              const { value: platform } = this.platform?.current;
              const { value: keywords } = this.keywords?.current;
              const { value: description } = this.description?.current;

              const prompt = `Create Ad copy for ${platform} headlines. ${description} with following keywords: ${keywords}.`; //to chatGpt
            
              sendPromptToChatGPT('Ad copy', {platform, keywords, description, author: 'user'}, prompt)
            }}
          >
            <div>
              <div>
                <div>
                  <label htmlFor="choose-platform">Choose platform</label>
                  <select id="choose-platform" ref={this.platform}>
                    <option value="null">Choose</option>
                    <option value="Google Ads">Google Ads</option>
                    <option value="Facebook Ads">Facebook Ads</option>
                  </select>
                </div>
                <div>
                  <label htmlFor="description">
                    Type keywords (seperate with comma)
                  </label>
                  <textarea
                    id="keywords"
                    placeholder="Type here"
                    className={classes.height_lg}
                    ref={this.keywords}
                  />
                </div>
              </div>
              <div className="align-self-start">
                <div>
                  <label htmlFor="description">
                    Description (min 30 words)
                  </label>
                  <textarea
                    id="description"
                    placeholder="Type here"
                    className={classes.height_lg}
                    ref={this.description}
                  />
                </div>
              </div>
            </div>
            <button type="submit">
              Start using{" "}
              <svg
                width="21"
                height="20"
                viewBox="0 0 21 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.8337 1.66666L9.66699 10.8333M18.8337 1.66666L13.0003 18.3333L9.66699 10.8333M18.8337 1.66666L2.16699 7.49999L9.66699 10.8333"
                  stroke="white"
                  strokeWidth="1.67"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </form>
        </section>
      </main>
    );
  }
}

export default AdCopy;
