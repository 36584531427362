import axios from "axios";
import React, { Component } from "react";

import { Link, withRouter } from "react-router-dom";

import Trash from "../../assets/css/CreateAd/trash.svg";
import ArrowRight from "../../assets/css/CreateAd/arrow-right.svg";
import classes from "../../assets/css/CreateAd/index.module.css";

import AdDetails from "./ad-details";

class Pullcampaigns extends Component {
  constructor(props) {
    super(props);
    this.state = {
      haveCampaigns: false,
      pullledCampaigns: [],
      modalViewDetailsStatus: false,
      viewCampaign: {},
      adds: [],
      loading: false,
      multiple: false,
      multiple_delete: false,
    };

    this.changeAddStatus = this.changeAddStatus.bind(this);
    this.settingInterval = null;
  }
  componentDidMount() {
    let auth = sessionStorage.getItem("authUser");
    this.getCampaigns(auth);
  }

  //Get Campigns from APi call
  getCampaigns = (auth) => {
    this.setState({ ...this.state, loading: true });

    const allCampaigns = `https://backendapp.murmurcars.com/api/v1/campaigns/${auth}/all`;
    return axios
      .get(allCampaigns)
      .then((response) => {
        if (response.status !== 400 || response.status !== 500) {
          const adds = [];
          let item = {};
          const iterator = response.data.message;
          const campaigns = [];

          for (let i = 0; i < iterator.length; i++) {
            if (iterator[i].ad_schedule) {
              const date = iterator[i].ad_schedule.split(" ")[1];

              iterator[i].active = new Date(date) > new Date();
              campaigns.push(iterator[i]);
              item = {
                id: iterator[i]._id,

                checked: false,
              };
              adds.push(item);
            }
          }

          const campaignsLength = campaigns.length;

          this.setState({
            ...this.state,
            pullledCampaigns: campaigns,
            adds: adds,
            loading: false,
            haveCampaigns: campaignsLength > 0 ? true : false,
          });
        }
      })
      .catch(() => {
        this.setState({
          ...this.state,
          loading: false,
        });
      });
  };

  toggleDeleteAd = (id, type) => {
    this.setState({ ...this.state, loading: true });
    axios
      .delete(`https://backendapp.murmurcars.com/api/v1/campaigns/${id}`)
      .then(() => {
        window.location.reload();
        if (type === "from_details") {
          this.props.history.replace("/ad-manager");
          this.props.history.go("/ad-manager");
        }
        this.setState({ ...this.state, loading: false });
      })

      .catch((error) => alert("something went wrong"));
  };

  toggleDeleteMultipleAd = () => {
    const adds = this.state.adds;
    const list_of_ids = [];
    this.setState({ ...this.state, loading: true });
    for (let i = 0; i < adds.length; i++) {
      if (adds[i].checked) {
        list_of_ids.push(adds[i].id);
      }
    }

    axios
      .delete(
        `https://backendapp.murmurcars.com/api/v1/campaigns/delete/${list_of_ids}`
      )
      .then(() => {
        window.location.reload();
        this.setState({ ...this.state, loading: false });
      })
      .catch((err) =>
        this.setState({
          ...this.state,
          loading: false,
        })
      );
  };

  checkCampaign = (event) => {
    const id = event.target.id;
    const adds = this.state.adds;
    let multiple = false;
    let count = 0;
    for (let i = 0; i < adds.length; i++) {
      if (adds[i].checked && adds[i].id !== id) {
        count++;
      }
      if (adds[i].id === id) {
        adds[i].checked = !adds[i].checked;
        if (adds[i].checked) {
          count++;
        }
      }
    }

    if (count > 1) {
      multiple = true;
    } else {
      multiple = false;
    }
    this.setState({
      ...this.state,
      adds,
      multiple_delete: multiple,
    });
  };

  checkAllCampigns = () => {
    const adds = this.state.adds;
    let multiple = !this.state.multiple;

    for (let i = 0; i < adds.length; i++) {
      if (multiple) {
        adds[i].checked = true;
      } else {
        adds[i].checked = false;
      }
    }

    this.setState({
      ...this.state,
      adds,
      multiple,
      multiple_delete: multiple,
    });
  };

  //ad-campaign
  handleCampaigns = () => {
    const { multiple_delete, adds, multiple } = this.state;

    let murmurCampaigns = [];

    if (this.state.pullledCampaigns.length !== 0) {
      {
        this.state.pullledCampaigns.map((campaign, i) => {
          murmurCampaigns.push(
            <tr key={campaign._id}>
              <td className={classes.cads_td}>
                <div className={classes.cads_flex_th}>
                  <div
                    className={`${classes.cads_check} ${classes.cads_check_2}`}
                  >
                    <input
                      type="checkbox"
                      id={campaign._id}
                      checked={(multiple && adds[i].checked) || adds[i].checked}
                      onChange={this.checkCampaign}
                    />
                    <label htmlFor={campaign._id}>
                      <span> {campaign.campaign_name}</span>
                    </label>
                  </div>

                  <div className={`${classes.cads_radio_active}`}>
                    {adds[i].checked && multiple_delete === false && (
                      <button
                        type="button"
                        className={`${classes.check_remove}`}
                        onClick={() => this.toggleDeleteAd(campaign._id)}
                      >
                        <img src={Trash} alt="" />
                      </button>
                    )}
                    {/*  <label className={classes.switch}>
                      <input
                        type="checkbox"
                        checked={!adds[i].toggled}
                        onChange={(e) =>
                          this.changeAddStatus(e, campaign._id)
                        }
                      />
                      <div
                        className={`${classes.slider} ${classes.round}`}
                        //  onClick={() => this.changeAddStatus(campaign._id, this.state.adds[i].checked)}
                      ></div>
                      </label>*/}
                  </div>
                </div>
              </td>
              <td className={classes.cads_td}>
                {campaign.ad_status === "In Review" ? (
                  <span className={classes.td_data}> {campaign.ad_status}</span>
                ) : (
                  <span
                    className={`${
                      campaign.active
                        ? classes.cads_active_dot
                        : classes.cads_deactive_dot
                    }`}
                  >
                    <span className={classes.cads_dot}></span>{" "}
                    {`${campaign.active ? "Active" : "Deactive"}`}
                  </span>
                )}
              </td>
              <td className={classes.cads_td}>
                <span className={classes.td_data}>
                  {" "}
                  {campaign.display_quantity}
                </span>
              </td>
              <td className={classes.cads_td}>
                <span className={classes.td_data}>{campaign.daily_budget}</span>
              </td>
              <td className={classes.cads_td}>
                {campaign.area ? (
                  <span className={classes.td_data}>{campaign.area}</span>
                ) : (
                  <div className="d-flex flex-column">
                    {campaign.locations.map((area, i) => (
                      <span key={i} className={classes.td_data}>
                        {area}
                      </span>
                    ))}
                  </div>
                )}
              </td>
              <td className={classes.cads_td}>
                <Link
                  to={`/ad-manager?campaign=${campaign._id}`}
                  className={classes.details_link}
                >
                  Details
                  <img
                    src={ArrowRight}
                    alt=""
                    className={classes.details_img}
                  />
                </Link>
              </td>
            </tr>
          );
        });
      }
    }

    return murmurCampaigns;
  };

  toggleMultipleAdds = (checked) => {
    const adds = this.state.adds;

    for (let i = 0; i < adds.length; i++) {
      if (adds[i].checked) {
        adds[i].toggled = checked;
      }
    }

    this.setState({
      ...this.state,
      adds,
      multiple_delete: checked,
    });
  };
  //activating and disactivating ad campaign
  changeAddStatus(e, id) {
    const checked = !e.target.checked;

    if (this.state.multiple || this.state.all_checked) {
      this.toggleMultipleAdds(checked);
      return;
    }

    const adds = this.state.adds;

    for (let i = 0; i < adds.length; i++) {
      const toggled = adds[i].toggled;

      if (adds[i].id === id) {
        if (toggled) {
        }
        adds[i].toggled = !toggled;
      } else {
        if (!toggled) {
        }
      }
    }

    this.setState({
      ...this.state,
      adds,
    });
  }

  render() {
    const url = this.props.location.search; //search property of history props
    const id = new URLSearchParams(url).get("campaign"); //extracting id

    const campaigns = this.state.pullledCampaigns.filter((el) => el._id === id); //looging for campaign by ad campaign id
    const { multiple_delete, loading } = this.state;
    const { location } = this.props;
    return (
      <React.Fragment>
        {/* this part is ad-manager STARTING*/}
        {loading && !campaigns.length && (
          <div id="preloader">
            <div id="status">
              <div className="spinner-chase">
                <div className="chase-dot"></div>
                <div className="chase-dot"></div>
                <div className="chase-dot"></div>
                <div className="chase-dot"></div>
                <div className="chase-dot"></div>
                <div className="chase-dot"></div>
              </div>
            </div>
          </div>
        )}
        {!location.search.length > 0 &&
          !loading && ( //list all campaigns
            <div className={classes.cads_table}>
              <table>
                <thead>
                  <tr className={classes.first_tr}>
                    <th className={`${classes.cads_th}`}>
                      <div
                        className={`${classes.cads_check} ${classes.invoice_th}`}
                      >
                        <input
                          type="checkbox"
                          id="invoice-txt"
                          onChange={this.checkAllCampigns}
                          checked={this.state.multiple}
                        />
                        <label htmlFor="invoice-txt">Name</label>
                        {multiple_delete && (
                          <button
                            type="button"
                            className={`${classes.check_remove} ${classes.multiple_remove}`}
                            onClick={() => this.toggleDeleteMultipleAd()}
                          >
                            <img src={Trash} alt="" />
                          </button>
                        )}
                      </div>
                    </th>
                    <th className={classes.cads_th}>
                      <span>Status</span>
                    </th>
                    <th
                      className={`${classes.cads_th} ${classes.cads_quantity}`}
                    >
                      <span>Display Quantity</span>
                    </th>
                    <th className={`${classes.cads_th} ${classes.cads_budget}`}>
                      <span>Daily Budget</span>
                    </th>
                    <th className={classes.cads_th}>
                      <span>Area</span>
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>{this.handleCampaigns()}</tbody>
              </table>
            </div>
          )}

        {this.props.location.search.length > 0 &&
          campaigns.length && ( //when user selects an add to check details
            <AdDetails
              campaign={campaigns}
              adds={this.state.adds}
              delete={this.toggleDeleteAd}
              loading={this.state.loading}
            />
          )}
      </React.Fragment>
    );
  }
}
export default withRouter(Pullcampaigns);
