import React, { Fragment } from "react";

import MessageBox from "./message-box";

import classes from "../../assets/css/personaAI/index.module.scss";
import "./alerts.scss";
import ArrowLeft from "../../assets/css/CreateAd/ads-details/arrow-left.svg";

import { Link } from "react-router-dom";

class PersonaAIChat extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      success: null,
    };
    this.description = React.createRef();
  }

  alertError = (message) => {
    this.setState({ error: message });
  };

  successfullyConnected = (message) => {
    this.setState({ error: message });
  };


  render() {
    const { error, success } = this.state;
    const {
      tokens,
      user_id,
      chat: chatHistory,
      loading,
      favorites,
      sendingAnotherDescriptionToChatGTP,
      savePostAsFavorites,
      deleteFromFavorites,
      exitChat,
    } = this.props;

    const chat = [];

    if (loading) {
      chat.push(...chatHistory, { author: "ai", status: "loading" });
    } else {
      chat.push(...chatHistory); //array of generated content by openai chatgpt
    }

    return (
      <>
        {error ? (
          <div className="xd-message msg-danger">
            <div className="xd-message-icon">
              <i className="ion-close-round"></i>
            </div>
            <div className="xd-message-content">
              <p>{error}</p>
            </div>
            <a href="#" className="xd-message-close">
              <i className="close-icon ion-close-round"></i>
            </a>
          </div>
        ) : null}
        {success ? (
          <div className="xd-message msg-success">
            <div className="xd-message-icon">
              <i className="ion-checkmark"></i>
            </div>
            <div className="xd-message-content">{success}</div>
            <a href="#" className="xd-message-close">
              <i className="close-icon ion-close-round"></i>
            </a>
          </div>
        ) : null}
        <section className={classes.generated_content}>
          <div className="d-flex justify-content-between">
            <header>
              <h5>PersonaAi</h5>
              <span>{`${tokens} tokens left`}</span>
            </header>
            <Link
              to="/personaAI/categories"
              className={classes.back_icon}
              onClick={exitChat}
            >
              <img src={ArrowLeft} alt="" className={classes.left_img} />
              <span>Back</span>
            </Link>
          </div>
          {chat.map((chat, i) => (
            <Fragment key={i}>
              <MessageBox
                chat={chat}
                user_id={user_id}
                favorites={favorites}
                alertError={this.alertError}
                savePostAsFavorites={savePostAsFavorites}
                deleteFromFavorites={deleteFromFavorites}
                successfullyConnected={this.successfullyConnected}
              />
            </Fragment>
          ))}
          <div className={classes.chat_input}>
            <textarea ref={this.description} />
            <button
              onClick={() =>
                sendingAnotherDescriptionToChatGTP(
                  this.description.current?.value
                )
              }
            >
              <svg
                viewBox="0 0 25.00 25.00"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                width={20}
                height={20}
              >
                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  {" "}
                  <path
                    d="M9.16109 12.9424L2.91109 12.4324C2.42109 12.3124 2.35109 11.6724 2.80109 11.4624L20.7111 3.55243C21.1811 3.34243 21.6711 3.81243 21.4411 4.25243L13.0111 21.2124C12.7811 21.6424 12.1211 21.5724 12.0011 21.1124L11.1711 13.2124L18.4411 6.41243"
                    stroke="#7702bd"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>{" "}
                </g>
              </svg>
            </button>
          </div>
        </section>
      </>
    );
  }
}

export default PersonaAIChat;
