import React from "react";

import classes from "../../../assets/css/CreateAd/verify-data/index.module.css";
import classes2 from "../../../assets/css/CreateAd/navbar.module.css";
import LogoCreate from "../../../assets/css/CreateAd/logo-create.png";
import Copyright from "../../../assets/css/CreateAd/copyright.svg";
import ArrowLeft from "../../../assets/css/CreateAd/ads-details/arrow-left.svg";

import { Link, withRouter } from "react-router-dom";

import { connect } from "react-redux";

class VerifyData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.submit = this.submit.bind(this);
  }

  submit(event) {
    event.preventDefault();
  }

  render() {
    return (
      <React.Fragment>
        <section className={classes.mur_contain}>
          <div className={classes.verify_data}>
            <div className={classes.verify_content}>
              <form onSubmit={this.submit}>
                {this.props.data.length &&
                  this.props.data.map((el, id) => (
                    <ul className={classes.verify_ul} key={id}>
                      <li className={classes.verify_li}>
                        <span className={classes.verify_li_span}>Title</span>
                        <p className={classes.verify_li_p}>{el.hype_title}</p>
                      </li>
                      <li className={classes.verify_li}>
                        <span className={classes.verify_li_span}>
                          How many social share?
                        </span>
                        <p className={classes.verify_li_p}>
                          {el.hype_target_shares}
                        </p>
                      </li>
                      <li className={classes.verify_li}>
                        <span className={classes.verify_li_span}>
                          Media for PR
                        </span>

                        <p className={classes.verify_li_p}>
                          {el.PR_subscription}
                        </p>
                      </li>
                      <li className={classes.verify_li}>
                        <span className={classes.verify_li_span}>
                          Description
                        </span>
                        <p className={classes.verify_li_p}>
                          {el.hype_description}
                        </p>
                      </li>
                      <li className={classes.verify_li}>
                        <span className={classes.verify_li_span}>
                          Campaing Duration
                        </span>
                        <p className={classes.verify_li_p}>
                          {el.campaign_duration}
                        </p>
                      </li>
                      <li className={classes.verify_li}>
                        <span className={classes.verify_li_span}>
                          Press release
                        </span>
                        <p className={classes.verify_li_p}>{el.PR_doc_name}</p>
                      </li>
                      <li className={classes.verify_li}>
                        <span className={classes.verify_li_span}>
                          Do you need influencers?
                        </span>
                        <p className={classes.verify_li_p}>
                          {el.influencers ? "yes" : "no"}
                        </p>
                      </li>
                      <li className={classes.verify_li}>
                        <span className={classes.verify_li_span}>
                          How many followers?
                        </span>
                        <p className={classes.verify_li_p}>
                          {el.hype_target_followers}
                        </p>
                      </li>
                      <li className={classes.verify_li}>
                        <span className={classes.verify_li_span}>
                          Link to the post or page
                        </span>
                        <p className={classes.verify_li_p}>{el.post_link}</p>
                      </li>

                      <li className={classes.verify_li}>
                        <span className={classes.verify_li_span}>
                          Do you need Branded City?
                        </span>
                        <p className={classes.verify_li_p}>
                          {el.branded_city ? "yes" : "no"}
                        </p>
                      </li>
                      <li className={classes.verify_li}>
                        <span className={classes.verify_li_span}>
                          Do you need photographers?
                        </span>

                        <p className={classes.verify_li_p}>
                          {el.photographers ? "yes" : "no"}
                        </p>
                      </li>
                      <li className={classes.verify_li}>
                        <span className={classes.verify_li_span}>Banner</span>

                        <div className={classes.verify_img_cover}>
                          <img
                            className={classes.verify_img}
                            src={el.banner_link}
                          />
                        </div>
                      </li>
                      <li className={classes.verify_li}>
                        <span className={classes.verify_li_span}>Location</span>
                        <p className={classes.verify_li_p}>{el.city}</p>
                      </li>
                      <li className={classes.verify_li}>
                        <span className={classes.verify_li_span}>
                          Do you need PR?
                        </span>
                        <p className={classes.verify_li_p}>
                          {el.PR ? "yes" : "no"}
                        </p>
                      </li>
                    </ul>
                  ))}

                <div className={classes.verify_center}>
                  <div className={classes.step_center}>
                    <button
                      className={`${classes.publish_back_link} bg-transparent`}
                      onClick={this.props.backToBudget}
                    >
                      <img src={ArrowLeft} alt="" />
                      <span className="text-secondary">Go Back</span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    hype_title,
    hype_description,
    hype_target_shares,
    hype_target_followers,
    PR_subscription,
    campaign_duration,
    influencers,
    photographers,
    branded_city,
    country,
    city,
    PR,
    post_link,
    PR_link,
    PR_doc_name,
    banner_link,
  } = state.HypeIQ;

  return {
    data: [
      {
        hype_title,
        hype_description,
        hype_target_shares,
        hype_target_followers,
        PR_subscription,
        campaign_duration,
        influencers,
        photographers,
        branded_city,
        country,
        city,
        PR,
        post_link,
        PR_link,
        PR_doc_name,
        banner_link,
      },
    ],
  };
};

export default connect(mapStateToProps, null)(withRouter(VerifyData));
