import React from "react";

import HeadSearch from "../../components/CommonForBoth/Headsearch";
import CampaignAnalytics from "./analytics";

import { Link } from "react-router-dom";

import classes from "../../assets/css/analitics/index.module.css";
import ArrowRight from "../../assets/css/CreateAd/arrow-right.svg";
import classes2 from "../../assets/css/CreateAd/index.module.css";
import { Fragment } from "react";

import axios from "axios";

class Analytics extends React.Component {
  constructor() {
    super();
    this.state = {
      haveCampaigns: false,
      loaded: false,
      user_email: sessionStorage.getItem("authUser"),
      campaigns: [],
    };
  }

  componentDidMount() {
    const { user_email } = this.state;
    axios
      .get(
        `https://backendapp.murmurcars.com/api/v1/campaigns/list-of-all-campaigns/${user_email}`
      )
      .then((response) => {
        const { data } = response;
        
        this.setState({
          ...this.state,
          loaded: true,
          campaigns: data,
          haveCampaigns: data.length ? true : false,
        });
      })
      .catch(() => {
        this.setState({
          ...this.state,
          loaded: true,
        });
      });
  }

  handleCampaigns = () => {
    const { campaigns } = this.state;

    let murmurCampaigns = [];

    if (campaigns.length !== 0) {
      {
        campaigns.map((campaign, i) => {
          murmurCampaigns.push(
            <tr key={campaign._id}>
              <td className={classes2.cads_td}>
                <div className={classes2.cads_flex_th}>
                  <span> {campaign.campaign_name}</span>
                </div>
              </td>

              <td className={classes2.cads_td}>
                <Link
                  to={`/analytics?campaign_id=${campaign._id}`}
                  className={classes2.details_link}
                >
                  Analytics
                  <img
                    src={ArrowRight}
                    alt=""
                    className={classes2.details_img}
                  />
                </Link>
              </td>
            </tr>
          );
        });
      }
    }

    return murmurCampaigns;
  };

  render() {
    const { haveCampaigns, user_email, loaded } = this.state;

    const url = this.props.location.search; //search property of history props
    const id = new URLSearchParams(url).get("campaign_id"); //extracting id
  
    return (
      <Fragment>
        {!loaded && (
          <div id="preloader" style={{ opacity: 0.7 }}>
            <div id="status">
              <div className="spinner-chase">
                <div className="chase-dot"></div>
                <div className="chase-dot"></div>
                <div className="chase-dot"></div>
                <div className="chase-dot"></div>
                <div className="chase-dot"></div>
                <div className="chase-dot"></div>
              </div>
            </div>
          </div>
        )}

        <div className={classes.dash_right}>
          <HeadSearch page="Analytics" />
          {loaded && !id && (
            <div
              className={`${classes.analytics_block} ${
                !haveCampaigns && classes.no_analytics_block
              }`}
            >
              {!haveCampaigns ? (
                <div className={classes.no_analytics_alert}>
                  <h1>No active campaign was found! Please create an ad!</h1>
                  <Link to="/ad-manager/campaign-objective">Create Ad +</Link>
                </div>
              ) : (
                <div className={classes2.cads_table}>
                  <table>
                    <thead>
                      <tr className={classes2.first_tr}>
                        <th className={classes2.cads_th}>
                          <span>Campaign</span>
                        </th>

                        <th></th>
                      </tr>
                    </thead>
                    <tbody>{this.handleCampaigns()}</tbody>
                  </table>
                </div>
              )}
            </div>
          )}
          {loaded && id && <CampaignAnalytics campaign_id={id} email={user_email} />}
        </div>
      </Fragment>
    );
  }
}

export default Analytics;
