//react
import React, { Fragment } from "react";
//styles
import classes from "../../assets/css/personaAI/index.module.scss";
//route
import { Route, Switch, withRouter } from "react-router-dom";
//componnets
import HeadSearch from "../../components/CommonForBoth/Headsearch";
import Hero from "./header";
import Categories from "./categories";
//categories
//import MarketingStrategy from "./categories/marketing-strategy";
import Templates from "./categories/template";
import WebsiteCopy from "./categories/website-copy";
import CustomerPersona from "./categories/customer-persona";
import AdCopy from "./categories/ad-copy";
import MarketingPlan from "./categories/marketing-plan";
import SubscribeModal from "./subscribe-modal";
//ai
import PersonaAIChat from "./chat-with-personaAI";
//profile
import { queryForEmail } from "../../helpers/fakebackend_helper";
//axios
import axios from "axios";
import CanselSubscription from "./cansel-membership";

class PersonaAI extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      openAI_chat: false, //this state for page where we get results from openai and chat
      subscription_modal: false, //modal of subscr packages available
      stripe_subscription: false, //stripe form to add card
      cansel_subscription_modal: false,
      success: null,
      error: null,
      profile: {
        //information about customer (id, email and fullName)
        id: "", //user id
        fullName: "",
        email: sessionStorage.getItem("authUser"),
        tokens: 0,
        plan: null, //default is trial or price_id  if customer subscribed to product
        active: false, //plan is active or not 
        interval: null, //month, year
        currency: null,
        amount: 0, //plan price,
        favorites: [],
        ayshare: sessionStorage.getItem("ayshare") === "true" ? true : false, //if user connected (we have its api key in db) ? true : false
      },
      subscription: { package: "trial", id: "" }, //default is  package trial and id null or subscr_id  if customer subscribed to product
      cansel_reason: null, //text describing reason of canselation
      chatGpt: {
        category: "", //ad copy, website copy and setera
        outputs: [], //generated choices in form of array
      },
      dallE: {},
    };
  }

  componentDidMount() {
    queryForEmail(`https://backendapp.murmurcars.com/api/v1/users/checkEmail/${false}`, {
      email: this.state.profile.email,
    })
      .then((res) => {
        const data = res.resp;

        const {
          _id,
          fullName,
          favoriteAIGeneratedContent,
          numberOfPersonaAITokens,
          personaAISubscription,
          ayrshare_api_key,
        } = data;

        sessionStorage.setItem(
          "ayshare",
          JSON.stringify(ayrshare_api_key == null ? false : true)
        );
        

        this.setState((state) => ({
          ...state,
          profile: {
            ...state.profile,
            fullName,
            id: _id,
            tokens: numberOfPersonaAITokens,
            plan:
              personaAISubscription != null
                ? personaAISubscription.plan.id
                : null,
            active:
              personaAISubscription != null
                ? personaAISubscription.plan.active
                : false,
            currency:
              personaAISubscription != null
                ? personaAISubscription.plan.currency
                : false,
            amount:
              personaAISubscription != null
                ? personaAISubscription.plan.amount
                : false,
            interval:
              personaAISubscription != null
                ? personaAISubscription.plan.interval
                : false,
            favorites: favoriteAIGeneratedContent,
          },
          subscription:
            personaAISubscription === "trial"
              ? { package: "trial", id: null }
              : personaAISubscription != null
              ? { package: "", id: personaAISubscription.id }
              : { package: null, id: null },
        }));
      })
      .catch((error) => console.log('netwrok issue'))
  }

  //generating content from chatgpt and navigating to chat
  sendPromptToChatGPT = (category, labels, prompt) => {
    const { id, fullName } = this.state.profile;

    labels.author = fullName;
    this.setState((state) => ({
      ...state,
      loading: true,
      chatGpt: {
        ...state.chatGpt,
        //in page where we show generated text also need to show inputs. that is why i store user inputs in state
        category,
        outputs: [...state.chatGpt.outputs, labels],
      },
      openAI_chat: true,
    }));

    this.toChatGPT(id, prompt);
  };

  toChatGPT = (id, prompt) => {
    const { favorites } = this.state.profile;
    axios
      .post("https://backendapp.murmurcars.com/api/v1/ai/post-ai", {
        text: prompt,
        user_id: id,
      })
      .then((response) => {
        const { data } = response;

        if (data?.status === 204) {
          //not enough tokens
          this.setState((state) => ({
            ...state,
            loading: false,
            subscription_modal: true,
          }));
          return;
        }


        let { text } = data.reply.at(0);
        const { numberOfAvailableToken } = data;
        text = text.split(/\n\n|\n/).filter((el) => el !== "");

        this.setState((state) => ({
          ...state,
          loading: false,
          chatGpt: {
            ...state.chatGpt,
            outputs: [
              ...state.chatGpt.outputs,
              { text: text.join(), author: "ai", favorite:false, id: "" },
            ],
          },
          profile: {
            ...state.profile,
            tokens: numberOfAvailableToken,
          },
        }));
      })
      .catch((error) => alert('netwrok issue'))
  };

  //to show which package user clicked on now
  chooseSubscriptionPackage = (packgage, priceId) => {
    this.setState((state) => ({
      ...state,
      subscription: { ...state.subscription, packgage }, //two packages available - pro and ultra
      profile: {
        ...state.profile,
        priceId, // for subscription to package need priceId of a product
      },
    }));
  };

  //cansel reason
  canselReasonInput = (e) => {
    const value = e.target.value;

    this.setState((state) => ({
      ...state,
      cansel_reason: value,
    }));
  };

  savePostAsFavorites = (text) => {
    const { chatGpt, profile } = this.state;
    const { outputs } = chatGpt;
    const { id: userId } = profile;
    axios
      .post(`http://localhost:4000/api/v1/ai/customer/${userId}/favorites`, {
        text,
      })
      .then((response) => {
        const { generatedId } = response.data;

        for (let message of outputs) {
          if (message.text === text) {
            message.favorite = true;
            message.id = generatedId;
          }
        }
        this.setState((state) => ({
          ...state,
          chatGpt: {
            ...state.chatGpt,
            outputs,
          },
        }));
      })
      .catch((error) => alert('netwrok issue'))
  };

  deleteFromFavorites = (favId) => {
    const { chatGpt, profile } = this.state;
    const { outputs } = chatGpt;
    const { id } = profile;
    axios
      .put(
        `http://localhost:4000/api/v1/ai/customer/${id}/favorites/${favId}/revome`
      )
      .then((response) => {
        for (let message of outputs) {
          if (message.id === favId) {
            message.favorite = false;
            message.id = '';
          }
        }
        this.setState((state) => ({
          ...state,
          chatGpt: {
            ...state.chatGpt,
            outputs,
          },
        }));
      })
      .catch((error) => alert('netwrok issue'))
  };

  editChatGPTCreatedContent = (editedContent,index) => {
      const {outputs} = this.state.chatGpt
       const history = new Array(...outputs)
       history[index].text = editedContent
       this.setState(state => ({
        ...state,
        chatGpt: {
          ...state.chatGpt,
          outputs: history
        }
       }))
  } 



  render() {
    const {
      success,
      error,
      openAI_chat,
      subscription_modal,
      stripe_subscription,
      cansel_subscription_modal,
      subscription,
      profile,
      chatGpt,
      loading,
      cansel_reason,
    } = this.state;
    
    return (
      <div className={classes.dash_right}>
        <HeadSearch page="Persona AI" />
        <div className={classes.right}>
          {openAI_chat ? (
            <PersonaAIChat
              tokens={profile.tokens}
              //input={chatGpt.input}
              user_id={profile.id}
              chat={chatGpt.outputs}
              loading={loading}
              favorites={profile.favorites}
              sendingAnotherDescriptionToChatGTP={(prompt) => {
                this.setState((state) => ({
                  ...state,
                  loading: true,
                  chatGpt: {
                    ...state.chatGpt,
                    outputs: [
                      ...state.chatGpt.outputs,
                      { author: "user", text: prompt },
                    ],
                  },
                }));
                this.toChatGPT(profile?.id, prompt);
              }}
              savePostAsFavorites={this.savePostAsFavorites}
              deleteFromFavorites={this.deleteFromFavorites}
              exitChat={() => {
                this.setState(state => ({
                  ...state,
                  openAI_chat: false
                }))
              }}
            />
          ) : (
            <div className={classes.categories}>
              {success ? (
                <div className="xd-message msg-success">
                  <div className="xd-message-icon">
                    <i className="ion-checkmark"></i>
                  </div>
                  <div className="xd-message-content">{success}</div>
                  <a href="#" className="xd-message-close">
                    <i className="close-icon ion-close-round"></i>
                  </a>
                </div>
              ) : null}
              {error ? (
                <div className="xd-message msg-danger">
                  <div className="xd-message-icon">
                    <i className="ion-close-round"></i>
                  </div>
                  <div className="xd-message-content">
                    <p>{error}</p>
                  </div>
                  <a href="#" className="xd-message-close">
                    <i className="close-icon ion-close-round"></i>
                  </a>
                </div>
              ) : null}
              <Hero />
              <Switch>
                <Route path="/personaAI/categories/website-copy">
                  <WebsiteCopy
                    //openAI_chat={openAI_chat}
                    sendPromptToChatGPT={this.sendPromptToChatGPT}
                  />
                </Route>
                <Route path="/personaAI/categories/ad-copy">
                  <AdCopy sendPromptToChatGPT={this.sendPromptToChatGPT} />
                </Route>
                {/*<Route path="/personaAI/categories/marketing-strategy">
                  <MarketingStrategy
                    sendPromptToChatGPT={this.sendPromptToChatGPT}
                  />
          </Route>*/}
                <Route path="/personaAI/categories/customer-persona">
                  <CustomerPersona
                    sendPromptToChatGPT={this.sendPromptToChatGPT}
                  />
                </Route>
                <Route path="/personaAI/categories/marketing-plan">
                  <MarketingPlan
                    sendPromptToChatGPT={this.sendPromptToChatGPT}
                  />
                </Route>
                <Route path="/personaAI/categories/templates">
                  <Templates sendPromptToChatGPT={this.sendPromptToChatGPT} />
                </Route>
                <Route path="/personaAI">
                  <Categories openChatGPTChat={() => {
                    this.setState(state => ({
                      ...state,
                      openAI_chat: true
                    }))
                  }}/>
                </Route>
              </Switch>
            </div>
          )}
          <SubscribeModal
            show={subscription_modal}
            stripe_subscription={stripe_subscription}
            subscription={subscription}
            profile={profile}
            chooseSubscriptionPackage={this.chooseSubscriptionPackage}
            toggle={(state) => {
              //subscribing on clicked package
              this.setState((prevState) => ({
                ...prevState,
                subscription_modal: state, //if true will open stripe for false modal is gonna close
                stripe_subscription: state,
              }));
            }}
            canselSubscription={() => {
              this.setState((state) => ({
                ...state,
                subscription_modal: false,
                cansel_subscription_modal: true,
              }));
            }}
            subscribedSuccessfully={(profile, subscr) => {
              let tokens = 0;
              if (subscription == "pro") {
                tokens = 10000;
              } else {
                tokens = 50000;
              }
              axios
                .post(
                  `http://localhost:4000/api/v1/ai/customer/${this.state.profile.id}/subscription-success`,
                  {
                    tokens,
                  }
                )
                .then((response) => {
                  this.props.history.replace("/personaAI/categories");
                  const { message } = response.data;
                  this.setState((state) => ({
                    ...state,
                    success: message,
                    profile: {
                      ...state.profile,
                      ...profile,
                      tokens
                    },
                    subscription: subscr,
                    subscription_modal: false,
                    openAI_chat: false
                  }));
                })
                .catch((error) => alert('netwrok issue'))
            }}
            membershipChanged={(tokens, subscription,message, Package) => {
              this.props.history.replace("/personaAI/categories");
              this.setState((state) => ({
                ...state,
                success: message,
                profile: {
                  ...state.profile,
                  ...profile,
                  tokens,
                  plan: subscription.plan.id,
                  active: subscription.plan.active,
                  currency: subscription.plan.currency,

                  amount: subscription.plan.amount,

                  interval: subscription.plan.interval,
                },
                subscription: {id: subscription.id, package: Package},
                openAI_chat: false,
                subscription_modal: false
              }));
            }}
          />
          <CanselSubscription
            show={cansel_subscription_modal}
            toggle={(message) => {
              this.props.history.replace("/personaAI/categories");
              this.setState((state) => ({
                ...state,
                cansel_subscription_modal: false,
                openAI_chat: false,
                success: message,
                profile: {
                  ...state.profile,
                  plan: null, //default is trial or price_id  if customer subscribed to product
                  active: false, //plan is active or not 
                  interval: null, //month, year
                  currency: null,
                  amount: 0, //plan price,
                  tokens: 0
                },
                subscription: { package: null, id: null}
              }));
            }}
            cansel_reason={cansel_reason}
            canselReasonInput={this.canselReasonInput}
            subscription={subscription}
            user_id={profile.id}
          />
        </div>
      </div>
    );
  }
}

export default withRouter(PersonaAI);
