import React from "react";

import SearchModal from "./modal";

import classes2 from "../../assets/css/StreetIQ/index.module.css";

class Filters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: [],
    };
  }

  render() {
    const {
      searchLocationsByFilterData,
      selectFilterData,

      filters,
      filterMethod,
      resetFilters
    } = this.props;
    

    return (
      <div className={classes2.stc_drops}>
        {filters.length &&
          filters.map((filter, i) => (
            <div className={classes2.stc_drop} key={i}>
              <div className={classes2.stc_drop_select}>
                <SearchModal
                  statistic={filter.filter}
                  labels={filter.labels}
                  filters={filter.filters}
                  method={filterMethod[filter.filter]}
                  activeRadio={(option) =>
                    selectFilterData(option, filter.filter)
                  }
                />
                {filterMethod.filtering ? (
                  <div className={classes2.selected_filters}>
                    {filterMethod[Object.keys(filterMethod).slice(2)[i]]}
                  </div>
                ) : null}
              </div>
            </div>
          ))}

        <div className={classes2.stc_drop}>
          <button
            className={classes2.stc_drop_submit}
            type="submit"
            onClick={() => searchLocationsByFilterData()}
          >
            <span>Search</span>
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.625 15.75C12.56 15.75 15.75 12.56 15.75 8.625C15.75 4.68997 12.56 1.5 8.625 1.5C4.68997 1.5 1.5 4.68997 1.5 8.625C1.5 12.56 4.68997 15.75 8.625 15.75Z"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M16.5 16.5L15 15"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </div>
        {filterMethod.filtering ? (
          <div className={`${classes2.stc_drop}`}>
            <button
              className={`${classes2.stc_drop_submit} bg-transparent text-danger border  border-white`}
              type="submit"
              onClick={() => resetFilters()}
            >
              <span>Reset</span>
            </button>
          </div>
        ) : null}
      </div>
    );
  }
}

export default Filters;
