import React from "react";

import classes from "../../assets/css/personaAI/index.module.scss";

class Hero extends React.Component {
  render() {
    return (
      <header className={classes.hero}>
        <h1>PersonaAi</h1>
        <p>Get better marketing answers in seconds</p>
      </header>
    );
  }
}

export default Hero;
