import React, { Component } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { withRouter, Link } from "react-router-dom";

import classes from "../../../assets/css/Destination/index.module.css";
import "../../../assets/css/Destination/modal.css";

class ToggleButtonDopDown extends Component {





  render() {
    const {show,active,toggle, togglePlatform} = this.props
    return (
      <React.Fragment>
        {/* <button type="button" class="source_item_drop">
               
              </button> */}
        <Dropdown
          className={classes.destin_dropdown}
          isOpen={show}
          toggle={toggle}
        >
          <DropdownToggle
            id="page-header-user-dropdown"
            tag="button"
            type="button"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 7C13.104 7 14 6.104 14 5C14 3.896 13.104 3 12 3C10.896 3 10 3.896 10 5C10 6.104 10.896 7 12 7ZM12 10C10.896 10 10 10.896 10 12C10 13.104 10.896 14 12 14C13.104 14 14 13.104 14 12C14 10.896 13.104 10 12 10ZM10 19C10 17.896 10.896 17 12 17C13.104 17 14 17.896 14 19C14 20.104 13.104 21 12 21C10.896 21 10 20.104 10 19Z"
                fill="#2E3A59"
              />
            </svg>
          </DropdownToggle>
          <DropdownMenu right className={classes.dropdown_menu}>
            <DropdownItem
              tag="a"
              //onClick={this.props.switchDestinationStatus}
              className={classes.dropdown_item}
              onClick={() => togglePlatform(!active)}
            >
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.00033 12.8333C10.2203 12.8333 12.8337 10.22 12.8337 6.99996C12.8337 3.77996 10.2203 1.16663 7.00033 1.16663C3.78033 1.16663 1.16699 3.77996 1.16699 6.99996C1.16699 10.22 3.78033 12.8333 7.00033 12.8333Z"
                  stroke="#192038"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M11.0251 2.91663L2.8584 11.0833"
                  stroke="#192038"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <span>{active ? "Disable" : "Enable"}</span>
            </DropdownItem>
      
          </DropdownMenu>
        </Dropdown>


   
      </React.Fragment>
    );
  }
}

export default ToggleButtonDopDown
