import React, { Component } from "react";
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap";
import {
  Map,
  GoogleApiWrapper,
  Marker,
  Polyline,
  // DirectionsRenderer,
  InfoWindow,
  Circle,
} from "google-maps-react";
import ReactStars from "react-rating-stars-component";

import "../../assets/css/app.css";
import { Google_Map_Styles } from "./map_styles";

//import moment from "moment";
import axios from "axios";

import classes from "../../assets/css/Dashboard/dashboard.module.css";
import classes2 from "../../assets/css/gps-tracking/index.module.css";
import "../../assets/css/common/css/ant-picker.css";
import "../../assets/css/common/css/box.css";
import "../../assets/css/common/css/switch.css";

import GPSPieChart from "./PieChart";

import { DatePicker } from "antd";

const GOOGLE_MAP_KEY = process.env.REACT_APP_GOOGLEMAPKEY;
//const firebase = require("firebase/app");
//const db = firebase.database();

//const ref = db.ref(`/backpackwalkers/${moment().format("MMM Do YY")}`);
const io = require("socket.io-client");
const socket = io.connect("https://backendapp.murmurcars.com/", {
  path: "/tracking/socket.io",
});
//const socket = io.connect("http://localhost:7444", {});

socket.on("connect", () => {
  console.log("connected");
});

class GPS_TRACKING extends Component {
  state = {
    fullName: "",
    Coords: {},
    mapcenter: { lat: null, lng: null },
    Markers: [],
    FullName: "",
    latitude: null,
    longitude: null,
    coords: null,
    keyValue: null,
    submitted: false,
    Walkers: [],
    WalkersStatus: false,
    NumberOfWalkers: null,
    modalStatus: false,
    partner_name: "",
    partner_startTime: "",
    partner_StartedGPSCoords: {},
    partner_StoppedGPSCoords: {},
    screens: [],
    zoom: 15,
    mode: "real time", //betweenreal time and historical
  };

  componentDidMount() {
    navigator.geolocation.getCurrentPosition((position) => {
      this.setState({
        mapcenter: {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        },
      });
    });

    socket.on("Retrive_Backpack_geoCodes", (data) => {
      if (this.state.mode == "historical") return;
      let walker = null;
      let timeout = null;
      const walkers = [...this.state.Walkers];
      const hasWalker =
        this.state.Walkers.findIndex((el) => el.fullName == data.fullName) !=
        -1;

      if (hasWalker) {
        const index = walkers.findIndex((el) => el.fullName == data.fullName);
        walker = walkers[index];
        walker.StoppedGPSCoords = data.StoppedGPSCoords;
      } else {
        walker = data;
        walker["timeout"] = null;
        walkers.push(walker);
      }

      this.setState((state) => ({
        ...state,
        Walkers: walkers,
        zoom: 17,
        mapcenter: data.StoppedGPSCoords,
      }));

      ((walker) => {
        if (walker["timeout"] != null) {
          clearTimeout(walker["timeout"]);
        }
        timeout = setTimeout(() => {
          this.setState((state) => ({
            ...state,
            Walkers: state.Walkers.filter(
              (el) => el.fullName != walker.fullName
            ),
          }));
          //walker['timeout'] = null;
        }, 10000);
      })(walker);
      this.setState((state) => ({
        ...state,
        Walkers: state.Walkers.map((el) => {
          if (el.fullName == walker.fullName) {
            el["timeout"] = timeout;
          }
          return el;
        }),
      }));
    });
  }

  /*handleGET_The_List_of_Online_Backpackwalkers = () => {
    axios
      .get(
        "${process.env.REACT_APP_PORTAL_DOMAIN}/api/v1/gps/get-list-online-backpackwalkers"
      )
      .then((response) => {
        response.data.map((item) => {
          if (item.GPS_Tracker.length !== 0) {
            let Partners = [];
            // ("this is item", item.GPS_Tracker[0]);
            // Partners.push(item.GPS_Tracker[0]);

            this.state.Walkers.push(item.GPS_Tracker[0]);
            this.setState({
              WalkersStatus: true,
              NumberOfWalkers: this.state.Walkers.length,
            });
            setTimeout(
              () => this.setState({ Walkers: [], WalkersStatus: false }),
              5000
            );
            //("From Axios", this.state);
            //this.handleMarkersonMap();
          } else {
            this.setState({ NumberOfWalkers: this.state.Walkers.length });
          }
        });
      })
      .catch((error) => error);
  };*/
  handleMarkerClick = (data) => {
    this.setState({
      modalStatus: true,
      partner_name: data.name,
      partner_StartedGPSCoords: data.StartedGPSCoords,
      partner_StoppedGPSCoords: data.StoppedGPSCoords,
      partner_startTime: data.startTime,
      formattedOrigin: data.StartedGPSCoords,
      formattedDestination: data.StoppedGPSCoords,
      Number,
    });
  };

  handleMarkersonMap = (props) => {
    let tMarkers = [];

    this.state.Walkers.map((data) => {
      const triangleCoords = {
        lat: data.StoppedGPSCoords.lat,
        lng: data.StoppedGPSCoords.lng,
      };

      tMarkers.push(
        <Marker
          key={data.fullName}
          title={"The marker`s title will appear as a tooltip."}
          label={data.name}
          position={triangleCoords}
          onClick={() => this.handleMarkerClick(data)}
          icon={{
            url: require("../../assets/images/MurmurBackpackWalker.png"),
            anchor: new props.google.maps.Point(5, 58),
          }}
        >
          <InfoWindow
            //marker={this.state.activeMarker}
            visible={true}
          >
            <div>
              <h1>{data.name}</h1>
            </div>
          </InfoWindow>
        </Marker>
      );
    });

    return tMarkers;
  };

  /*  handleCirclesonMap = () => {
    let tCircles = [];

    this.state.Walkers.map((data) => {
      const triangleCoords = {
        lat: data.StoppedGPSCoords.lat,
        lng: data.StoppedGPSCoords.lng,
      };

      tCircles.push(
        <Circle
          radius={120}
          center={triangleCoords}
          // onMouseover={() => ("mouseover")}
          // onClick={() => ("click")}
          // onMouseout={() => ("mouseout")}
          strokeColor="transparent"
          strokeOpacity={0}
          strokeWeight={5}
          fillColor="#FF0000"
          fillOpacity={0.2}
        />
      );
    });

    return tCircles;
  };*/

  toRadians = (deg) => {
    return (deg * Math.PI) / 180;
  };

  toDegrees = (rad) => {
    return (rad * 180) / Math.PI;
  };

  midpoint = (coords) => {
    let x = 0,
      y = 0,
      z = 0;

    coords.forEach((coord) => {
      const lat = this.toRadians(coord.lat);
      const lng = this.toRadians(coord.lng);

      x += Math.cos(lat) * Math.cos(lng);
      y += Math.cos(lat) * Math.sin(lng);
      z += Math.sin(lat);
    });

    const total = coords.length;

    x = x / total;
    y = y / total;
    z = z / total;

    const lngMid = Math.atan2(y, x);
    const hyp = Math.sqrt(x * x + y * y);
    const latMid = Math.atan2(z, hyp);

    return {
      lat: this.toDegrees(latMid),
      lng: this.toDegrees(lngMid),
    };
  };

  render() {
    return (
      <React.Fragment>
        {this.state.mapcenter.lat ? (
          <div className={classes.dash_right}>
            <div className={classes.map}>
              <Map
                google={this.props.google}
                zoom={this.state.zoom}
                styles={Google_Map_Styles}
                initialCenter={this.state.mapcenter}
                fullscreenControl={false}
                draggable={true}
                center={this.state.mapcenter}
                scrollwheel={true}
                mapTypeControl={false}
                disableDoubleClickZoom={false}
                disableDefaultUI={true}
                keyboardShortcuts={false}
              >
                {this.state.screens.map((screen) => {
                  return (
                    <Marker
                      key={screen._id}
                      title={"The marker`s title will appear as a tooltip."}
                      ///  label={screen.name}
                      icon={{
                        url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png", // URL for a blue marker
                      }}
                      position={screen.GPS_Tracker[0].StartedGPSCoords}
                      //          onClick={() => this.handleMarkerClick(data)}
                    />
                  );
                })}

                {this.state.screens.map((screen) => {
                  const triangleCoords = [
                    screen.GPS_Tracker[0].StartedGPSCoords,
                    screen.GPS_Tracker[0].StoppedGPSCoords[
                      screen.GPS_Tracker[0].StoppedGPSCoords.length - 1
                    ],
                  ];

                  return (
                    <Polyline
                      key={screen._id}
                      path={triangleCoords}
                      fillColor="#7356bd"
                      fillOpacity={0.35}
                      //fillOpacity="0.5"
                      strokeOpacity={0.8}
                      strokeWeight={3}
                      strokeColor="#200b47"
                    />
                  );
                })}
                {this.state.screens.map((screen) => {
                  return (
                    <Marker
                      key={screen._id}
                      title={"The marker`s title will appear as a tooltip."}
                      //label={screen.name}
                      icon={{
                        url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png",
                        anchor: new window.google.maps.Point(5, 58),
                      }}
                      position={
                        screen.GPS_Tracker[0].StoppedGPSCoords[
                          screen.GPS_Tracker[0].StoppedGPSCoords.length - 1
                        ]
                      }
                      //          onClick={() => this.handleMarkerClick(data)}
                    />
                  );
                })}
                {this.handleMarkersonMap(this.props)}
                {/* {this.state.WalkersStatus ? this.handleCirclesonMap() : null} */}
              </Map>
            </div>
          </div>
        ) : null}

        <div className={classes.head_search_dashboard}>
          <div className={classes.head_search}>
            <div className={`${classes.dash_relative} ${classes.search_box}`}>
              {this.state.mode == "historical" ? (
                <DatePicker
                  className={classes2["analytics-form__input"]}
                  name="duration"
                  id="duration"
                  picker="date"
                  onChange={async (date, dateString) => {
                    try {
                      const response = await axios.get(
                        `${process.env.REACT_APP_PORTAL_DOMAIN}/api/v1/gps/gps-tracking-by-date/${dateString}`
                      );
               
                      const gpsPoints = [];
                      for (let el of response.data) {
                        gpsPoints.push(el.GPS_Tracker[0].StartedGPSCoords);
                      }

                      this.setState((state) => ({
                        ...state,
                        screens: response.data,
                        zoom: 18,
                        mapcenter: gpsPoints.length ? this.midpoint(gpsPoints) : state.mapcenter,
                      }));
                    } catch (err) {
                      console.log(err);
                    }
                  }}
                />
              ) : null}
            </div>
          </div>
        </div>
        <div className="map-box">
          <p>
            In <strong>real-time tracking</strong>, data is continuously updated
            to show the current location of the tracker, allowing users to
            monitor movements as they happen.
          </p>
          <p>
            On the other hand, <strong>historical tracking</strong> allows users
            to review past movements by accessing previously recorded GPS data.
          </p>
          <p>
            You can switch between these modes depending on your need for
            real-time updates or a deeper look into past tracking data.
          </p>

          <div className="switch-container">
            <p
              className={`slider-state
                ${this.state.mode == "historical" ? "active" : null}
              `}
            >
              Historical
            </p>
            <label className="switch" htmlFor="checkbox">
              <input
                type="checkbox"
                id="checkbox"
                checked={this.state.mode == "real time"}
                onChange={(e) => {
                  const checked = e.target.checked;
                  this.setState((state) => ({
                    ...state,
                    mode: checked ? "real time" : "historical",
                  }));
                }}
              />
              <div className="slider round"></div>
            </label>
            <p
              className={`slider-state
                ${this.state.mode == "real time" ? "active" : null}
              `}
            >
              Real Time
            </p>
          </div>
        </div>

        <Modal
          isOpen={this.state.modalStatus}
          //toggle={this.props.toggleModal}
          className={`setting_cancel_modal`}
        >
          <ModalHeader className={` ${classes2.modal_header}`}>
            <div className={`position-relative max_343`}>
              <Col>
                {/* <img
                  //className="rounded-circle header-profile-user"
                  className={`${
                    this.props.scope === "global"
                      ? classes.profil_img
                      : this.props.scope === "survey"
                      ? classes.profil_img
                      : classes.profil_cover_img
                  }`}
                  src={require("../../assets/images/MurmurBackpackWalker.png")}
                  alt="Header Avatar"
                /> */}
              </Col>
              <Col>
                <span>{this.state.partner_name}</span>{" "}
              </Col>
              <Col>
                Well Performing Partner
                <ReactStars
                  count={5}
                  //onChange={ratingChanged}
                  size={24}
                  value={5}
                  activeColor="#ffd700"
                />
              </Col>
            </div>
          </ModalHeader>
          <ModalBody>
            <form>
              <div className="form_element_modal">
                <label htmlFor="about_reason">
                  {this.state.partner_name} daily generates: {"\n"} ~14772
                  Impressions, ~5435 Views.
                </label>
              </div>
              <div className="form_element_modal">
                <label htmlFor="about_reason">
                  It is beta feature. This data is not a real-time and based on
                  statistics which we collect every week.
                </label>
              </div>
              <div
                className={`${classes2.map_container} form_element_btn text-center`}
              >
                <Row>
                  <Col>
                    <GPSPieChart
                      series={[50, 50]}
                      location={"Baku"}
                      labels={["males", "females"]}
                    />
                  </Col>
                  <Col>
                    <GPSPieChart
                      series={[80, 20]}
                      location={"Baku"}
                      labels={["Android", "iOS"]}
                    />
                  </Col>
                </Row>
                <Row>
                  <label></label>
                </Row>
              </div>
            </form>
          </ModalBody>
          <ModalFooter className={classes2.modal_footer}>
            <Row>
              <Col></Col>
            </Row>
            <button
              type="button"
              className="reason_cancel_btn"
              onClick={() => {
                this.setState({ ...this.state, modalStatus: false });
              }}
            >
              Close
            </button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: GOOGLE_MAP_KEY,
})(GPS_TRACKING);
