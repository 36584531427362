import React from "react";

import axios from "axios";

import { Link, withRouter } from "react-router-dom";



import Trash2 from '../../assets/css/common/icons/trashRed.svg'
import Edit from "../../assets/css/CreateAd/ads-details/edit.svg";
import ArrowLeft from "../../assets/css/CreateAd/ads-details/arrow-left.svg";
import classes2 from "../../assets/css/CreateAd/ads-details/index.module.css";
import "../../assets/css/common/css/tooltip.scss";


import { Upload } from "antd";

import Geocode from "react-geocode";
const GOOGLE_MAP_KEY = process.env.REACT_APP_GOOGLEMAPKEY;
Geocode.setApiKey(GOOGLE_MAP_KEY);

const { Dragger } = Upload;

const propsD = {
  name: "file",
  multiple: true,

  onDrop(e) {
    
  },
};

class AdDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      profecionals_modal: false,
      profecional_ids: {},
      tooltip: false,
      category: null,
      map: {
        center: {
          lat: 0,
          lng: 0,
        },
        coordinates: [],
        zoom: 8,
        postCenter: {
          lat: 0,
          lng: 0,
        },
      },
      updates: {},
      campaign: [],
      editable: false,
    };
  }
  toggleEditMode = (id) => {
    this.props.history.replace(`/hypeIQ/update-campaign?campaign=${id}`);
  };
  changeCampaign = (e, property) => {};
  /* handleFileChange = (info) => {
    const reader = new FileReader();
    reader.readAsDataURL(info.file);
    const Img = new Image();

    const scope = this;

    reader.onload = (e) => {
      Img.src = e.target.result;
      Img.onload = function () {
        
        if (this.width !== 1080 || this.height !== 1920) {
          alert("image size is not 1080X1920");
          return;
        }
        const updates = scope.state.updates;
        updates[0].artWork_url = info.file.name;
        updates[0].file = info.file;
        updates[0].image = e.target.result;

        scope.setState({ ...scope.state, updates });
      };
    };
  };*/
  handleDetailsUpdate = (event, property) => {
    let value = event.target.value;
    if (Number.isNaN(+value)) {
      value = +value.split(/\D/i).join("");
    }

    this.setState((state) => {
      return {
        ...state,
        updates: {
          ...state.updates,
          [property]: value,
        },
      };
    });
  };
  /* handleChange = (event) => {
    const value = event.target.value;
    const { updates } = this.state;
    const { city, country } = updates[0];


    const Locations = [];

    let multiple = false;

    if (city.length > 1) multiple = true;

    if (multiple) {
      for (let location of area) {
        if (location === value) {
          return;
        }
      }
      Locations.push(...area);

      if (Locations.length === locations.length) {
        Locations.pop();
      }
    }
    Locations.push(value);

    axios
      .post(
        "https://backendapp.murmurcars.com/api/v1/zipcode/get-multiple-polygon-coords",
        {
          districts: Locations,
          country: country,
        }
      )
      .then((resp) => {
        const { polygons, center } = resp.data;

        updates[0].area = Locations;
        this.setState({
          ...this.state,
          updates,
          map: {
            ...this.state.location,
            coordinates: polygons,
            center: center,
            postCenter: center,
          },
        });
      })
      .catch((err) => {
        
      });
  };*/

  /*handleUSZipsChange = (e) => {
    e.persist();
    const value = e.target.value;
    const { updates } = this.state;
    const { area, country } = updates[0];

    const { campaign } = this.props;
    const { locations, ad_schedule } = campaign[0];

    if (value.length === 5) {
      const Locations = [];

      let multiple = false;
      if (locations.length > 1) multiple = true;

      if (multiple) {
        for (let location of area) {
          if (location === value) {
            return;
          }
        }
        Locations.push(...area);
        if (Locations.length === locations.length) {
          Locations.pop();
        }
      }
      Locations.push(value);
      axios
        .post(
          "https://backendapp.murmurcars.com/api/v1/zipcode/get-multiple-polygon-coords",
          {
            districts: Locations,
            country: country,
          }
        )
        .then((resp) => {
          const { polygons, center } = resp.data;

          updates[0].area = Locations;

          this.setState({
            ...this.state,
            updates,
            map: {
              ...this.state.location,
              coordinates: polygons,
              center: center,
              postCenter: center,
            },
          });

          e.target.value = "";
        })
        .catch((err) => {
          
        });
    }
  };*/

  /*submitUpdates = () => {
    const updates = this.state.updates[0];
    const { _id, ad_schedule, ad_schedule_time } = this.props.campaign[0];

    const area = updates.area;
    const advertisers_email = updates.advertisers_email;
    const campaign_type = updates.campaign_type;
    const campaign_name = updates.campaign_name;
    const daily_budget = updates.daily_budget;
    const display_quantity = updates.display_quantity;
    const file = updates.file;
    const audienceAge = updates.audienceAge;
    const audienceGender = updates.audienceGender;
    const artWork_url = updates.artWork_url;
    const ad_status = updates.ad_status;
    const ad_type = updates.ad_type;

    this.setState({ ...this.state, loaded: false });

    const formData = new FormData();
    formData.append("advertisers_email", advertisers_email);
    formData.append("campaign_type", campaign_type);
    formData.append("campaign_name", campaign_name);
    formData.append("ad_schedule", ad_schedule);
    formData.append("ad_status", ad_status);
    formData.append("ad_type", ad_type);
    for (let el of area) {
      formData.append("area", JSON.stringify(el));
    }

    formData.append("daily_budget", daily_budget);
    formData.append("display_quantity", display_quantity);
    formData.append("file", file);
    formData.append("audienceAge", audienceAge);
    formData.append("audienceGender", audienceGender);
    formData.append("ad_schedule_time", ad_schedule_time);
    formData.append("artWork_url", artWork_url);
    axios({
      url: `https://backendapp.murmurcars.com/api/v1/campaigns/${_id}`,
      method: "PUT",
      data: formData,
    })
      .then((res) => {
        this.setState({
          ...this.state,
          loaded: true,
          editable: false,
        });
      })
      .catch((err) => 
  };*/

  componentDidMount() {
    const { campaign } = this.props;
    const {
      city,
      country,
      assigned_specialists,
      target_followers,
      target_shares,
      hype_title,
      advertisers_email,
      campaign_status,
      budget,
      approved,
      _id,
    } = campaign.at(0);

    this.setState({
      ...this.state,
      loaded: true,
      campaign: [
        {
          ...this.state.updates,
          _id,
          budget,
          campaign_status,
          advertisers_email,
          hype_title,
          target_followers,
          target_shares,
          assigned_profecionals: assigned_specialists,
          city,
          country,
          active: approved,
        },
      ],
    });
  }

  /*deletePolygonFromStack = (index) => {
    const { updates, map } = this.state;
    const { coordinates: coordinatesState } = map;
    const { area: locationsState } = updates[0];

    const leftCoordinates = coordinatesState.slice(0, index);
    const rightCoordinates = coordinatesState.slice(
      index + 1,
      coordinatesState.length
    );
    const coordinates = [...leftCoordinates, ...rightCoordinates];

    const leftLocations = locationsState.slice(0, index);
    const rightLocations = locationsState.slice(
      index + 1,
      locationsState.length
    );
    const locations = [...leftLocations, ...rightLocations];

    updates[0].area = locations;
    this.setState({
      ...this.state,
      map: {
        ...this.state.map,
        coordinates,
      },
      updates,
    });
  };*/

  render() {
    const { editable, updates, tooltip } = this.state;
    const { campaign } = this.props;
    const { ad_status, active } = campaign.at(0);
    const url = this.props.location.search;
    const id = new URLSearchParams(url).get("campaign");

    let status = ad_status;
    return (
      <React.Fragment>
        {!this.state.loaded && (
          <div id="preloader">
            <div id="status">
              <div className="spinner-chase">
                <div className="chase-dot"></div>
                <div className="chase-dot"></div>
                <div className="chase-dot"></div>
                <div className="chase-dot"></div>
                <div className="chase-dot"></div>
                <div className="chase-dot"></div>
              </div>
            </div>
          </div>
        )}
        {this.state.loaded &&
          this.state.campaign.map((campaign, index) => (
            <div className={classes2.ads_details_section} key={index}>
              <Link to="/hypeIQ" className={classes2.ads_back_icon}>
                <img src={ArrowLeft} alt="" className={classes2.ads_left_img} />
                <span>Back</span>
              </Link>

              <div className={classes2.current_ads_name}>
                <div className={classes2.crrnt_ads_title}>
                  <p>{campaign.hype_title}</p>

                  {status !== "In Review" ? (
                    <span
                      className={`${
                        status
                          ? classes2.cads_active_dot
                          : classes2.cads_deactive_dot
                      }`}
                    >
                      <span className={classes2.cads_dot}></span>
                      {active ? "Active" : "Deactive"}
                    </span>
                  ) : (
                    <span className={classes2.in_review}>{status}</span>
                  )}

                  {/*<!-- <span class="cads_deactive_dot"><span class="cads_dot"></span>Deactive</span> -->*/}
                </div>
                <button
                  type={editable ? "submit" : "button"}
                  className={classes2.crrnt_edit}
                  onClick={() => {
                    this.toggleEditMode(id);
                  }}
                >
                  <span>{`${editable ? "Update" : "Edit"}`}</span>

                  <img src={Edit} alt="" className={classes2.crrnt_edit_img} />
                </button>
              </div>

              <div
                className={`${classes2.budget_category_are} ${classes2.ads_detail_row}`}
              >
                <div className={`fit-content ${classes2.ads_detail_budget}`}>
                  <div className={`fit-content ${classes2.ads_detail_col}`}>
                    <div className={` fit-content ${classes2.ads_detail_top}`}>
                      <h5 className={`no-wrap ${classes2.detail_top_h5}`}>
                        Cost of the service
                      </h5>
                    </div>
                    <div className={classes2.ads_details_content}>
                      
                        <p className={classes2.detail_content_p}>
                          {campaign.budget}
                        </p>
                      
                    </div>
                  </div>
                </div>
                <div className={classes2.ads_detail_category}>
                  <div className={classes2.ads_detail_col}>
                    <div className={classes2.ads_detail_top}>
                      <h5 className={classes2.detail_top_h5}>
                        Target Followers
                      </h5>
                    </div>
                    <div className={classes2.ads_details_content}>
                    
                        <p className={classes2.detail_content_p}>
                          {campaign.target_followers == null ? 0 : campaign.target_followers}
                        </p>
                      
                    </div>
                  </div>
                </div>
                <div className={classes2.ads_detail_category}>
                  <div className={classes2.ads_detail_col}>
                    <div className={classes2.ads_detail_top}>
                      <h5 className={classes2.detail_top_h5}>Target Shares</h5>
                    </div>
                    <div className={classes2.ads_details_content}>
                    
                        <p className={classes2.detail_content_p}>
                          {campaign.target_shares == null ? 0 : campaign.target_shares }
                        </p>
                   
                    </div>
                  </div>
                </div>
              </div>
             {/* <div
                className={`${classes2.ads_detail_info} ${classes2.ads_detail_row}`}
              >
                <div className={classes2.ads_detail_audience}>
                  <div className={classes2.ads_detail_col}>
                    <div className={classes2.ads_detail_top}>
                      <h5 className={classes2.detail_top_h5}>
                        Hired professionals
                      </h5>
                    </div>
                    <div
                      className={`${classes2.ads_details_content} ${
                        this.state.editable && classes2.edit_inline_flex
                      }`}
                    >
                      <ul className={classes2.ads_detail_age}>
                        <li className="fit-content">
                          <span>Photographers</span>
                          <p className={classes2.detail_content_p}>
                            {
                              campaign.assigned_profecionals.filter(
                                (el) => el.category === "photographers"
                              ).length
                            }
                          </p>
                        </li>

                        <li>
                          <span
                            className={`ml-3 mr-3 ${classes2.age_gender_line}`}
                          ></span>
                        </li>

                        <li className="fit-content">
                          <span>Influencers</span>
                          <p className={classes2.detail_content_p}>
                            {
                              campaign.assigned_profecionals.filter(
                                (el) => el.category === "influencers"
                              ).length
                            }
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                          </div>*/}
                {/*<div className={`${classes2.ads_detail_audience} w-100`}>
                  <div className={classes2.ads_detail_col}>
                    <div className={classes2.ads_detail_top}>
                      <h5 className={classes2.detail_top_h5}>
                        List of potential creative specialists for your ad
                      </h5>
                    </div>
                    <div
                      className={`${classes2.ads_details_content} ${
                        this.state.editable && classes2.edit_inline_flex
                      }`}
                    >
                      <ul className={classes2.ads_detail_age}>
                        <li className="pl-4 pr-4">
                          <span>
                            Photographers{" "}
                            {editable ? (
                              <img
                                src={Add}
                                alt=""
                                onClick={() => {
                                  const profecional_ids = {};
                                  const photographers =
                                    campaign.assigned_profecionals.filter(
                                      (el) => el.category === "photographers"
                                    );
                                  for (let photographer of photographers) {
                                    profecional_ids[photographer.fullName] =
                                      photographer.id;
                                  }
                                  this.setState((state) => {
                                    return {
                                      ...state,
                                      profecionals_modal: true,
                                      profecional_ids,
                                      category: "photographers",
                                    };
                                  });
                                }}
                              />
                            ) : null}
                          </span>
                          <div className={classes2.creative_specialists_cont}>
                            {campaign.assigned_profecionals
                              .filter((el) => el.category === "photographers")
                              .map((profecional, i) => (
                                <div key={i}>
                                  <img
                                    src={profecional.profile_photo}
                                    alt="avatar"
                                    className={`${classes.profil_img}`}
                                    id={`hype${campaign._id}`}
                                    onMouseEnter={() =>
                                      this.setState((state) => {
                                        return {
                                          ...state,
                                          tooltip: true,
                                        };
                                      })
                                    }
                                  />
                                  <Tooltip target={`hype${campaign._id}`}>
                                    <div
                                      className="tooltip-cont"
                                      onMouseLeave={() =>
                                        this.setState((state) => {
                                          return {
                                            ...state,
                                            tooltip: false,
                                          };
                                        })
                                      }
                                    >
                                      <div className="profecional-avatar_cont">
                                        <img
                                          className="profecional-avatar"
                                          src={profecional.profile_photo}
                                        />
                                      </div>
                                      <label>Social media:</label>
                                      <ul>
                                        {profecional.facebook ? (
                                          <li>
                                            <a href={profecional.facebook}>
                                              <img src={Facebook} alt="" />
                                            </a>
                                          </li>
                                        ) : null}

                                        {profecional.instagram ? (
                                          <li>
                                            <a href={profecional.instagram}>
                                              <img src={Instagram} alt="" />
                                            </a>
                                          </li>
                                        ) : null}

                                        {profecional.twitter ? (
                                          <li>
                                            <a href={profecional.twitter}>
                                              <img src={Twitter} alt="" />
                                            </a>
                                          </li>
                                        ) : null}

                                        {profecional.telegram ? (
                                          <li>
                                            <a href={profecional.telegram}>
                                              <img src={Telegram} alt="" />
                                            </a>
                                          </li>
                                        ) : null}

                                        {profecional.youtube ? (
                                          <li>
                                            <a href={profecional.youtube}>
                                              <img src={Youtube} alt="" />
                                            </a>
                                          </li>
                                        ) : null}
                                      </ul>
                                    </div>
                                  </Tooltip>
                                </div>
                              ))}
                          </div>
                                        </li>

                        <li>
                          <span className={classes2.age_gender_line}></span>
                        </li>

                        <li className="pl-4 pr-4">
                          <span>
                            Influencers{" "}
                            {editable ? (
                              <img
                                src={Add}
                                alt=""
                                onClick={() => {
                                  const profecional_ids = {};
                                  const influencers =
                                    campaign.assigned_profecionals.filter(
                                      (el) => el.category === "influencers"
                                    );
                                  for (let influencer of influencers) {
                                    profecional_ids[influencer.fullName] =
                                      influencer.id;
                                  }
                                  this.setState((state) => {
                                    return {
                                      ...state,
                                      profecionals_modal: true,
                                      profecional_ids,
                                      category: "influencers",
                                    };
                                  });
                                }}
                              />
                              ) : null} 
                          </span>
                          <div className={classes2.creative_specialists_cont}>
                            {campaign.assigned_profecionals
                              .filter((el) => el.category === "influencers")
                              .map((profecional, i) => (
                                <div key={i}>
                                  <img
                                    src={profecional.profile_photo}
                                    alt="avatar"
                                    className={`${classes.profil_img}`}
                                    id={`hype${campaign._id}`}
                                    onMouseEnter={() =>
                                      this.setState((state) => {
                                        return {
                                          ...state,
                                          tooltip: true,
                                        };
                                      })
                                    }
                                  />
                                  <Tooltip
                                    target={`hype${campaign._id}`}
                                    show={tooltip}
                                  >
                                    <div
                                      className="tooltip-cont"
                                      onMouseLeave={() =>
                                        this.setState((state) => {
                                          return {
                                            ...state,
                                            tooltip: false,
                                          };
                                        })
                                      }
                                    >
                                      <div className="profecional-avatar_cont">
                                        <img
                                          className="profecional-avatar"
                                          src={profecional.profile_photo}
                                        />
                                      </div>
                                      <label>Social media:</label>
                                      <ul>
                                        {profecional.facebook ? (
                                          <li>
                                            <a
                                              href={profecional.facebook}
                                              target="_blank"
                                            >
                                              <img src={Facebook} alt="" />
                                            </a>
                                          </li>
                                        ) : null}

                                        {profecional.instagram ? (
                                          <li>
                                            <a
                                              href={profecional.facebook}
                                              target="_blank"
                                            >
                                              <img src={Instagram} alt="" />
                                            </a>
                                          </li>
                                        ) : null}

                                        {profecional.twitter ? (
                                          <li>
                                            <a
                                              href={profecional.facebook}
                                              target="_blank"
                                            >
                                              <img src={Twitter} alt="" />
                                            </a>
                                          </li>
                                        ) : null}

                                        {profecional.telegram ? (
                                          <li>
                                            <a
                                              href={profecional.facebook}
                                              target="_blank"
                                            >
                                              <img src={Telegram} alt="" />
                                            </a>
                                          </li>
                                        ) : null}

                                        {profecional.youtube ? (
                                          <li>
                                            <a
                                              href={profecional.facebook}
                                              target="_blank"
                                            >
                                              <img src={Youtube} alt="" />
                                            </a>
                                          </li>
                                        ) : null}
                                      </ul>
                                    </div>
                                  </Tooltip>
                                </div>
                              ))}
                          </div>
                        </li>
                      </ul>
                                        </div>
                                        </div>
                </div>
                                        </div>*/}
              <div className={classes2.delete_ads}>
                <button
                  type="button"
                  className={classes2.delete_ads_btn}
                  onClick={() => this.props.delete(id, "from_details")}
                >
                  <span>Delete Campaign</span>
                  <img src={Trash2} alt="" />
                </button>
                <span className={classes2.delete_ads_note}>
                  *There is no backup for deleted AD’s
                </span>
              </div>
            </div>
          ))}
      </React.Fragment>
    );
  }
}

export default withRouter(AdDetails);
