import React, { Component } from 'react';

import axios from 'axios';

import FormData from 'form-data';

import { Button, Badge, Alert } from 'reactstrap';
import classes from '../../assets/css/CreateAd/index.module.css';

import Pullcampaigns from './pullCampign';
import CampaignObjective from './create-campaign/campaign-objective';
import CampaignDetails from './create-campaign/campaign-details';
import Audience from './create-campaign/audience';
import BudgetAndSchedule from './create-campaign/budget-schedule';
import PlacementType from './create-campaign/placement-type';
import AdMedia from './create-campaign/ad-creative';
import VerifyData from './create-campaign/verify-data';
import Location from './create-campaign/location';
import HeadSearch from '../../components/CommonForBoth/Headsearch';


import { toggleSideBar } from '../../store/actions';
import { connect } from 'react-redux';
import { withRouter, Link, Switch, Route, Redirect } from 'react-router-dom';

import Geocode from 'react-geocode';
const GOOGLE_MAP_KEY = process.env.REACT_APP_GOOGLEMAPKEY;
Geocode.setApiKey(GOOGLE_MAP_KEY);

class CreateAdDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      display_quantity: null,
      modal: false,
      brandAwareness: false,
      reach: false,
      tooltipOpen: false,
      traffic: false,
      budgetValue: null,
      audienceAge: null,
      audienceGender: null,
      adCategory: null,
      scheduleCampaignDate: null,
      createAdStatus: false,
      alertStatus: false,
      speedometerValue: null,
      speedometerMaxValue: 100000,
      modalCreateAdBody: false,
      advertisers: '',
      advertisers_email: sessionStorage.getItem('authUser'),
      campaign_type: '',
      payment: '',
      duration: '',
      area: '',
      campaign_name: '',
      restaurants: this.props.restaurants,
      media: {},
      //artWork_url: '',
      file:{},
      daily_budget: '',
      ad_schedule_time: '',
      address: this.props.country + this.props.city,
      country: this.props.country,
      city: this.props.city,
      coordinates: [],
      markers: [],
      center: {
        lat: 0,
        lng: 0,
      },
      popupsurvey: false,
      fromPlaceIQ: false,
    };
    this.selectCampaignObjective = this.selectCampaignObjective.bind(this);
    this.handleCreateDetails = this.handleCreateDetails.bind(this);
    this.handleAudience = this.handleAudience.bind(this);
    this.handleBudgetAndDate = this.handleBudgetAndDate.bind(this);
    this.handleDisplayAmountAndType =
      this.handleDisplayAmountAndType.bind(this);
    this.handleImage = this.handleImage.bind(this);
    this.toggleCompleteCreateAd = this.toggleCompleteCreateAd.bind(this);
  }

  componentDidMount() {
    const url = this.props.location.search; //search property of history props
    const fromPlaceIQ = new URLSearchParams(url).get('from') === 'placeIQ'; //extracting id

    if (!this.props.match.isExact) {
      //if user on create add page and accidentaly refrash page then react will render first step of ad creation to start all over again
      this.toggle();
    }
    this.setState((state) => ({
      ...state,
      popupsurvey: true,
      fromPlaceIQ,
    }));
  }
  closeModal = () => {
    this.setState({
      ...this.state,
      popupsurvey: false,
    });
  };
  toggle = () => {
    this.setState({
      ...this.state,
      modal: true,
      brandAwareness: false,
      reach: false,
      traffic: false,
    });
    this.props.toggleSideBar(false); //geting rid of left navbar for creating ad
    this.props.history.push('/ad-manager/campaign-objective');
  };

  //objectives
  selectCampaignObjective(objective) {
    this.setState({
      ...this.state,
      [objective]: true,
    });
  }

  handleCreateDetails({ campaign_name, adCategory }) {
    this.setState({
      ...this.state,
      adCategory: adCategory,
      campaign_name: campaign_name,
    });
    this.props.history.push('/ad-manager/audience');
  }

  handleCreateAd = (event) => {
    const name = event.target.name;
    if (name === 'artWork_url') {
      this.setState({ ...this.state, file: event.target.files[0] });
    } else {
      this.setState({ ...this.state, [name]: event.target.value });
    }
  };

  handleAudience({ age, gender, specificAttribute }) {
    this.setState({
      ...this.state,
      audienceAge: age,
      audienceGender: gender,
      attribute: specificAttribute,
    });
    this.props.history.push('/ad-manager/location');
  }

  pickLocations = ({
    country,
    city,
    locations,
    coordinates,
    markers,
    center,
  }) => {
    this.setState({
      ...this.state,
      area: locations,
      country,
      city,
      coordinates,
      markers,
      center,
    });
    this.props.history.push('/ad-manager/budget-schedule');
  };

  toggleCancelCreateAd = () => {
    this.setState({ ...this.state, createAdStatus: false });
  };

  handleBudgetAndDate({ budgetValue, duration, time }) {
    this.setState({
      ...this.state,
      daily_budget: budgetValue,
      duration: duration[0] + ' ' + duration[1],
      ad_schedule_time: time[0] + ' ' + time[1],
    });
    this.props.history.push('/ad-manager/placement-type');
  }

  handleDisplayAmountAndType({ campaign_type, display_quantity, restaurants }) {
    this.setState({
      ...this.state,
      campaign_type,
      display_quantity,
      restaurants,
    });
    this.props.history.push('/ad-manager/ad-media');
  }
  handleImage({ file, media }) {
    this.setState({
      ...this.state,
      file,
      media,
    });
    this.props.history.push('/ad-manager/verify-data');
  }

  toggleCompleteCreateAd() {
    const formData = new FormData();
    formData.append('advertisers_email', this.state.advertisers_email);
    formData.append('campaign_type', this.state.campaign_type);
    formData.append('ad_type', this.state.adCategory);
    formData.append('campaign_name', this.state.campaign_name);
    formData.append('ad_schedule', this.state.duration);
    for (const area of this.state.area) {
      formData.append('area', JSON.stringify(area));
    }

    for (const restaurant of this.state.restaurants) {
      formData.append('restaurants', JSON.stringify(restaurant));
    }
    if (!this.state.restaurants.length) {
      formData.append('restaurants', JSON.stringify(null));
    }
    if (!this.state.area.length) {
      formData.append('area', JSON.stringify(null));
    }
    formData.append('daily_budget', this.state.daily_budget);
    formData.append('display_quantity', this.state.display_quantity);

    formData.append('audienceAge', this.state.audienceAge);
    formData.append('audienceGender', this.state.audienceGender);
    formData.append('ad_schedule_time', this.state.ad_schedule_time);
   // formData.append('artWork_url', this.state.artWork_url);
    formData.append('file', this.state.file);
    //formData.append('images', this.state.media);

    formData.append('brandAwareness', this.state.brandAwareness);
    formData.append('reach', this.state.reach);
    formData.append('traffic', this.state.traffic);
    formData.append('country', this.state.country);
    formData.append('city', this.state.city);
    axios({
      url: 'https://backendapp.murmurcars.com/api/v1/campaigns/create',
      method: 'POST',
      data: formData,
    })
      .then((res) => {
        this.setState({ ...this.state, alertStatus: true, modal: false });
        this.props.history.replace('/ad-manager');
        this.props.history.go('/ad-manager');
        this.setState({ ...this.state, alertStatus: false });
      })
      .catch((err) => {
        this.setState({ ...this.state,  modal: false });
      });
  }

  handleCampaigns = () => {
    let murmurCampaigns = [];
    if (this.state.murmurCampaign.length !== 0) {
      {
        this.state.campaigns.map((campaign, key) =>
          murmurCampaigns.push(
            <tr key={'_tr_' + key}>
              <td>
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id={campaign.campaign_name}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor={campaign.campaign_name}
                  >
                    &nbsp;
                  </label>
                </div>
              </td>
              <td>
                <Link to="#" className="text-body font-weight-bold">
                  {campaign.campaign_name}
                </Link>
              </td>
              <td>{campaign.delivery_type}</td>
              <td>{campaign.budget}</td>
              <td>{campaign.CPM}</td>
              <td>
                <Badge
                  className={
                    'font-size-12 badge-soft-' + campaign.campaignStatus
                  }
                  color={campaign.campaignStatus}
                  pill
                >
                  {campaign.campaignData}
                </Badge>
              </td>
              <td>{campaign.reach}</td>
              <td>
                <Button
                  type="button"
                  color="primary"
                  size="sm"
                  className="btn-rounded waves-effect waves-light"
                  onClick={this.toggle}
                >
                  View Details
                </Button>
              </td>
            </tr>,
          ),
        );
      }
    }
    return murmurCampaigns;
  };

  render() {
    const { modal } = this.state;

    return (
      <React.Fragment>
        {!this.state.modal && ( //page where user see all its campaigns and can create new one
          <div className={classes.dash_right}>
            {this.state.alertStatus ? (
              <Alert color="success">
                We received your Ad Request and Reviewing it. Usually it takes
                15 min for approval.
                <a href="#" className="alert-link"></a>
                Have questions? Send us message in Drift.
              </Alert>
            ) : null}

            <HeadSearch page="Ads Manager" />

            {this.props.match.isExact &&
              !this.props.location.search && ( //page where user see all its campaigns
                <div className={classes.create_ads}>
                  <div className={classes.ads_section}>
                    <div className={`${classes.cads_head}`}>
                      <div className={classes.cads_head_left}>
                        <h4 className={classes.cads_h4}>Your Current Ads</h4>
                        <p className={classes.cads_p}>
                          Here you can view the status of your ads with all the
                          necessary details
                        </p>
                      </div>

                      <button
                        type="button"
                        className={classes.create_ads_btn}
                        onClick={this.toggle}
                      >
                        Create Ad
                        <svg
                          width="20"
                          height="20"
                          className={classes.create_ads_img}
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M5 10H15"
                            stroke="white"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M10 15V5"
                            stroke="white"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <Pullcampaigns data={this.state} />
                </div>
              )}

            {this.props.match.isExact &&
              this.props.location.search.length > 0 && ( //details
                <Pullcampaigns data={this.state} />
              )}
          </div>
        )}
        {modal && ( //nesting routes (creat new ad campaign)
          <Switch>
            <Route path="/ad-manager/campaign-objective">
              <CampaignObjective
                toggleObjective={this.selectCampaignObjective}
              />
            </Route>
            <Route path="/ad-manager/campaign-details">
              <CampaignDetails createAdDetails={this.handleCreateDetails} />
            </Route>
            <Route path="/ad-manager/audience">
              <Audience createAudience={this.handleAudience} />
            </Route>
            <Route path="/ad-manager/location">
              <Location
                pickLocations={this.pickLocations}
                address={this.state.address}
                country={this.state.country}
                city={this.state.city}
                locations={this.state.area}
                coordinates={this.state.coordinates}
                markers={this.state.markers}
                center={this.state.center}
                fromPlaceIQ={this.state.fromPlaceIQ}
              />
            </Route>
            <Route path="/ad-manager/budget-schedule">
              <BudgetAndSchedule
                createBudgetAndDuration={this.handleBudgetAndDate}
                address={this.state.address}
              />
            </Route>
            <Route path="/ad-manager/placement-type">
              <PlacementType
                createDisplayAmountAndCampaignType={
                  this.handleDisplayAmountAndType
                }
                country={this.state.country}
                city={this.state.city}
                markers={this.state.restaurants}
              />
            </Route>
            <Route path="/ad-manager/ad-media">
              <AdMedia createAdImage={this.handleImage} />
            </Route>
            <Route path="/ad-manager/verify-data">
              <VerifyData
                data={[
                  {
                    objective: this.state.brandAwareness
                      ? 'Brand awareness'
                      : this.state.reach
                      ? 'Reach'
                      : 'Traffic',
                    age: this.state.audienceAge,
                    gender: this.state.audienceGender,
                    location: this.state.area,
                    name: this.state.campaign_name,
                    type: this.state.campaign_type,
                    attribute: this.state.attribute,
                    displayNumber: this.state.display_quantity,
                    category: this.state.adCategory,
                    budget: this.state.daily_budget,
                    artWork: this.state.media,
                    duration: this.state.duration,
                  },
                ]}
                sendToBackEnd={this.toggleCompleteCreateAd}
              //  toggleBackToAdManager={this.toggle}
              />
            </Route>
          </Switch>
        )}
 
      </React.Fragment>
    );
  }
}

//export default withRouter(connect(null, { toggleSideBar })(CreateAdDashboard));

const mapStateToProps = (state) => {
  const { country, city, restaurants } = state.PlaceIQ;
  return { country, city, restaurants };
};
export default connect(mapStateToProps, { toggleSideBar })(
  withRouter(CreateAdDashboard),
);
