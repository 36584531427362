import { takeEvery, fork, put, all, call } from "redux-saga/effects";

import {
  CREATENEWHYPECAMPAIGN,
  FETCHALLCREATEDHYPECAMPAIGNS,
  FETCHCAMPAIGNTOUPDATEDETAILS,
  PUSHUPDATEDDETAILSOFHYPECAMPAIGNTOBACKEND,
} from "./types";

import {
  createHypeCampaign,
  fetchAllHypeCampaigns,
  queryForEmail,
  fetchHypeCampaign,
  updateHypeCampaign
} from "../../helpers/fakebackend_helper";

import {
  createNewHypeCampaignFailed,
  createNewHypeCampaignSuccess,
  fetchAllHypeIQCampaignsFailed,
  fetchAllHypeIQCampaignsSuccess,
  fetchHypeIQCampaignSuccess,
  fetchHypeIQCampaignFailed,
  pushUpdatedDetailsOfHypeCampaignSuccess,
  pushUpdatedDetailsOfHypeCampaignFailed
} from "./actions";

function* createHype({ payload: { campaign, history } }) {
  const url = "https://backendapp.murmurcars.com/api/v1/hypeiq/create";

  try {
    const email = sessionStorage.getItem("authUser");
    const customer = yield call(
      queryForEmail,
      `https://backendapp.murmurcars.com/api/v1/users/checkEmail/${false}`,
      { email }
    );
    const { phone_number, fullName } = customer.resp;
    campaign.append("advertisers_phone_number", phone_number);
    campaign.append("advertisers_name", fullName);

 
    const response = yield call(createHypeCampaign, { url, campaign });
    history.replace("/hypeIQ");
    
    yield put(createNewHypeCampaignSuccess(response.data.hypeiq));
  } catch (err) {
    yield put(createNewHypeCampaignFailed(err));
  }
}

function* fetchCreatedCampaigns({ payload: email }) {
  const url = `https://backendapp.murmurcars.com/api/v1/hypeiq/${email}/all`;

  try {
    const campaigns = yield call(fetchAllHypeCampaigns, url);
    yield put(fetchAllHypeIQCampaignsSuccess(campaigns.message));
  } catch (err) {
    yield put(fetchAllHypeIQCampaignsFailed(err));
  }
}

function* fetchCreatedCampaign({ payload: id }) {
  const url = `https://backendapp.murmurcars.com/api/v1/hypeiq/fetch-campaign/${id}`;
  try {
    const campaign = yield call(fetchHypeCampaign, url);
    
    yield put(fetchHypeIQCampaignSuccess(campaign.data));
  } catch (err) {
    yield put(fetchHypeIQCampaignFailed(err));
  }
}
function* updateHypeIQCampaign({ payload: { campaign, id, history } }) {
  const url = `https://backendapp.murmurcars.com/api/v1/hypeiq/update-campaign/${id}`;

  try {
   
    const response = yield call(updateHypeCampaign, { url, campaign });
    history.replace("/hypeIQ");
    yield put(pushUpdatedDetailsOfHypeCampaignSuccess(response.data.hypeiq));
    return
  } catch (err) {
    yield put(pushUpdatedDetailsOfHypeCampaignFailed(err));
  }
}

export function* watchCreateNewCampaign() {
  yield takeEvery(CREATENEWHYPECAMPAIGN, createHype);
}
export function* watchFetchingAllCampaigns() {
  yield takeEvery(FETCHALLCREATEDHYPECAMPAIGNS, fetchCreatedCampaigns);
}
export function* watchFetchingCampaign() {
  yield takeEvery(FETCHCAMPAIGNTOUPDATEDETAILS, fetchCreatedCampaign);
}
export function* watchUpdatingCampaign() {
  yield takeEvery(PUSHUPDATEDDETAILSOFHYPECAMPAIGNTOBACKEND, updateHypeIQCampaign);
}

function* CreateHypeCampaignSaga() {
  yield all([
    fork(watchCreateNewCampaign),
    fork(watchFetchingAllCampaigns),
    fork(watchFetchingCampaign),
    fork(watchUpdatingCampaign),
  ]);
}

export default CreateHypeCampaignSaga;
