import React from "react";

import classes from "../../../assets/css/CreateAd/index.module.css";
import classes2 from "../../../assets/css/hypeIQ/index.module.scss";

import HypeLinks from "./links";
import Target from "./target";
import HypeTitle from "./title";
import Budget from "./budget";

import { connect } from "react-redux";
import {
  locateLocation,
  createNewHypeCampaign,
  fetchCampaignToUpdateDetails,
  pushUpdatedDetailsOfHypeCampaign,
} from "../../../store/actions";

import { withRouter } from "react-router-dom";

import Geocode from "react-geocode";
const GOOGLE_MAP_KEY = process.env.REACT_APP_GOOGLEMAPKEY;
Geocode.setApiKey(GOOGLE_MAP_KEY);

class CreateNewHype extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hype_form: "campaign_title",
      email: sessionStorage.getItem("authUser"),
      update: false,
      campaign_id: null,
    };
  }

  nextForm = ({ form }) => {
    this.setState((state) => {
      return {
        ...state,
        hype_form: form,
      };
    });
  };
  /*handleReverseGeocode = (location) => {
    Geocode.fromLatLng(location.lat, location.lng).then(
      (response) => {
        const address = response.results[0].formatted_address;
        const split = address.split(",");
        const country = split.at(-1).trim();
        const city = split.at(1).trim();

        this.props.locateLocation(country, city);
      },
      (error) => {
        this.setState({ ...this.state });
      }
    );
  };*/

  componentDidMount() {
    const { search } = this.props.location;
    if (search) {
      const id = new URLSearchParams(search).get("campaign"); //extracting id
      this.props.fetchCampaignToUpdateDetails(id);
      this.setState((state) => ({
        ...state,
        update: true,
        campaign_id: id,
      }));
      return;
    }
    /*navigator.geolocation.getCurrentPosition(
      (position) => {
        const location = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };

        this.handleReverseGeocode(location);
      },
      (err) => {
        this.setState({
          ...this.state,
        });
      }
    );*/
  }

  submit = (form, budget) => {
    if (form) {
      this.setState((state) => {
        return {
          ...state,
          hype_form: form,
        };
      });
    }
    const { email, update, campaign_id: id } = this.state;
    const { HypeIQ } = this.props;
    const {
      country,
      city,
      hype_title,
      hype_description,
      target_shares,
      target_followers,
      campaign_duration,
      branded_city,
      branded_city_package,
      branded_city_sum,
      PR_doc_name,
      PR_subscription,
      PR,
      banner_name,
      social_media,
      PR_budget,
      PR_file,
      banner_file,
      post_link,
      location
    } = HypeIQ;
  
    const Form = new FormData();

    Form.append("banner_file", banner_file);
    Form.append("PR_file", PR_file);
    Form.append(
      "banner_name",
      banner_name !=null ? banner_name.split(" ").join("") : null
    );
    Form.append(
      "PR_doc_name",
      PR_doc_name !=null  ? PR_doc_name.split(" ").join("") : PR_doc_name
    );

    Form.append("advertisers_email", email);
    //Form.append("advertisers_name", advertisers_name);
    //Form.append("advertisers_phone_number", advertisers_phone_number);
    Form.append("country", country);
    Form.append("city", city);
    Form.append("location", location);
    Form.append("hype_title", hype_title);
    Form.append("hype_description", hype_description);
    Form.append("post_link", post_link);
    Form.append("target_shares", JSON.stringify(target_shares));
    Form.append("target_followers", JSON.stringify(target_followers));
    Form.append("campaign_duration", campaign_duration);
    Form.append(
      "branded_city",
      JSON.stringify(branded_city != null ? branded_city : false)
    );
    Form.append("branded_city_subscription", branded_city_package);
    Form.append("branded_city_budget", JSON.stringify(branded_city_sum));
    Form.append("PR_subscription", PR_subscription);
    Form.append("budget", budget);
    Form.append("social_media", JSON.stringify(social_media));
    Form.append("PR_budget", JSON.stringify(PR_budget));
    Form.append("PR", JSON.stringify(PR != null ? PR : false));
    Form.append(
      "PR_link",
      `https://backendapp.murmurcars.com/advertisers/media/PR/${PR_doc_name}`
    );
    Form.append(
      "banner_link",
      `https://backendapp.murmurcars.com/advertisers/media/banner/${banner_name}`
    );
   
    if (update) {
      this.props.pushUpdatedDetailsOfHypeCampaign(Form, id, this.props.history);
    } else {
      this.props.createNewHypeCampaign(Form, this.props.history);
    }
  };

  render() {
    const { hype_form, update } = this.state;
    const { HypeIQ } = this.props;
    const {
      country,
      city,
      location,
      influencers,
      photographers,
      branded_city,
      branded_city_sum,
      hype_title,
      hype_description,
      PR,
      PR_doc_name,
      PR_file,
      PR_link,
      PR_budget,
      PR_subscription,
      target_shares,
      target_followers,
      campaign_duration,
      banner_file,
      banner_name,
      banner_link,
      post_link,
      social_media,
      loaded,
      budget,
    } = HypeIQ;

    return (
      <>
        {!loaded ? (
          <div id="preloader">
            <div id="status">
              <div className="spinner-chase">
                <div className="chase-dot"></div>
                <div className="chase-dot"></div>
                <div className="chase-dot"></div>
                <div className="chase-dot"></div>
                <div className="chase-dot"></div>
                <div className="chase-dot"></div>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className={classes.cads_head}>
              <div className={classes.cads_head_left}>
                <h4 className={classes.cads_h4}>Create new Hype</h4>
                <p className={classes.cads_p}>
                  Please reach out to our friendly team via
                  billing@murmurcars.com with questions
                </p>
              </div>
            </div>
            <div className={classes2.container}>
              {hype_form === "campaign_title" ? (
                <HypeTitle
                  nextForm={this.nextForm}
                  data={{
                    branded_city,
                    hype_title,
                    hype_description,
                    country,
                    city,
                    location
                  }}
                />
              ) : hype_form === "campaign_target" ? (
                <Target
                  nextForm={this.nextForm}
                  data={{
                    PR,
                    photographers,
                    influencers,
                    target_shares,
                    target_followers,
                    campaign_duration,
                    PR_subscription,
                    PR_doc_name,
                    PR_file,
                    PR_budget,
                    PR_link,
                  }}
                />
              ) : hype_form === "links" ? (
                <HypeLinks
                  nextForm={this.nextForm}
                  data={{ banner_file, banner_name, banner_link, post_link }}
                  media_links={social_media}
                />
              ) : hype_form === "budget" ? (
                <Budget
                  nextForm={this.nextForm}
                  submit={this.submit}
                  update={update}
                  specialists_price={12}
                  target_shares={target_shares}
                  target_followers={target_followers}
                  pr_budget={PR_budget}
                  branded_city_budget={branded_city_sum}
                  budget={budget}
                  country={country}
                />
              ) : null}
            </div>{" "}
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const HypeIQ = state.HypeIQ;
  return { HypeIQ };
};

export default connect(mapStateToProps, {
  locateLocation,
  createNewHypeCampaign,
  fetchCampaignToUpdateDetails,
  pushUpdatedDetailsOfHypeCampaign,
})(withRouter(CreateNewHype));
