import React from "react";

import { Modal, ModalHeader, ModalBody } from "reactstrap";

import "../../../../assets/css/Settings/modal.css";
import Simple from "../../../../assets/css/hypeIQ/simple.png";
import Premium from "../../../../assets/css/hypeIQ/premium.png";
import OwnPlan from "../../../../assets/css/hypeIQ/own.png";

import { connect } from "react-redux";

import axios from "axios";

class PRSubscription extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subscription: "",
      loading: true,
      pr_by_country: {
        media_outlets: {},
        prices: {},
        words: {}
      },
    };
  }
  componentDidMount() {
    const { country } = this.props;
    axios
      .get(
        `https://backendapp.murmurcars.com/api/v1/hypeiq/feth-pr-plan-by-country/${country}`
      )
      .then((response) => {
        const { pr_plan } = response.data;
        this.setState((state) => ({
          ...state,
          loading: false,
          pr_by_country: {
            media_outlets: pr_plan.media_outlets,
            prices: pr_plan.prices,
            words: country  !== 'Azerbaijan' ? pr_plan.words : null
          },
        }));
      })
      .catch((err) => {
        this.setState((state) => ({
          ...state,
          loading: false,
        }));
      });
  }
  billingPackageChange = (subscription) => {
    this.setState({
      subscription,
    });
  };
  render() {
    const { subscription, loading, pr_by_country } = this.state;
    const {country} = this.props 
    
    return (
      <Modal
        isOpen={this.props.show}
        toggle={this.props.toggleModal}
        className="setting_plan_modal"
      >
        <ModalHeader>
          <span>Choose subscription plan for PR:</span>
          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            onClick={this.props.toggleModal}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.4142 12L17.7072 7.70701C18.0982 7.31601 18.0982 6.68401 17.7072 6.29301C17.3162 5.90201 16.6842 5.90201 16.2933 6.29301L12.0002 10.586L7.70725 6.29301C7.31625 5.90201 6.68425 5.90201 6.29325 6.29301C5.90225 6.68401 5.90225 7.31601 6.29325 7.70701L10.5862 12L6.29325 16.293C5.90225 16.684 5.90225 17.316 6.29325 17.707C6.48825 17.902 6.74425 18 7.00025 18C7.25625 18 7.51225 17.902 7.70725 17.707L12.0002 13.414L16.2933 17.707C16.4882 17.902 16.7443 18 17.0002 18C17.2562 18 17.5122 17.902 17.7072 17.707C18.0982 17.316 18.0982 16.684 17.7072 16.293L13.4142 12Z"
                fill="#3F2B89"
              />
            </svg>
          </button>
        </ModalHeader>
        <ModalBody>
          {loading ? (
            <div style={{ height: "100%" }}>
              <div className="spinner_container">
                <div className="spinner-border text-primary" role="status" />
              </div>
            </div>
          ) : (
            <div className="plan_modal_cover">
              <div className="m_plan_item">
                <input
                  type="radio"
                  name="modal-plan"
                  id="plan_walk"
                  onChange={() => this.billingPackageChange("simple")}
                  checked={
                   subscription === "simple"
                      ? true
                      : false
                  }
                />
                <label htmlFor="plan_walk" className="plan_type">
                  <div className="play_flex_top">
                    <p className="plan_p">
                      <small>
                        <img src={Simple} alt="" />
                      </small>
                      <span
                        className={`${
                          subscription === "simple"
                            ? "active_p"
                            : null
                        }`}
                      >
                        Simple
                      </span>
                    </p>
                    <p className="plan_month">
                      <span
                        className={`${
                          this.props.PR_subscription === "simple"
                            ? "active_p"
                            : subscription === "simple"
                            ? "active_p"
                            : null
                        }`}
                      >
                        {pr_by_country.prices.simple} {/*550₼*/}
                      </span>
                      <small>/month</small>
                    </p>

                    <p>
                      {pr_by_country.media_outlets.simple}
                      {/*Azerforum.com, Avropa.info, Azsabah.com, Avrasiya.net,
                    Turkustan.az, Atributinfo.az, Hafta.az, Xeber.az,
                    Xalqcebhesi.az, Baymedia.az*/}
                    </p>
                    {country !== "Azerbaijan" ? (
                      <p>{pr_by_country.words.simple}</p>
                    ) : null}
                  </div>
                  <div className="text-center">
                    <button
                      className="current_plan_btn"
                      onClick={() => this.props.selectPackage("Simple", +pr_by_country.prices.simple.split(/\D/i).at(0))}
                    >
                      Choose this plan
                    </button>
                  </div>
                </label>
              </div>
              <div className="m_plan_item">
                <input
                  type="radio"
                  name="modal-run"
                  id="plan_run"
                  onChange={() => this.billingPackageChange("premium")}
                  checked={
                    subscription === "premium"
                      ? true
                      : false
                  }
                />
                <label htmlFor="plan_run" className="plan_type">
                  <div className="plan_flex_top">
                    <p className="plan_p">
                      <small>
                        <img src={Premium} alt="" />
                      </small>
                      <span
                        className={`${
                          this.props.PR_subscription === "premium"
                            ? "active_p"
                            : subscription === "premium"
                            ? "active_p"
                            : null
                        }`}
                      >
                        Premium
                      </span>
                    </p>
                    <p className="plan_month">
                      <span
                        className={`${
                          this.props.PR_subscription === "premium"
                            ? "active_p"
                            : subscription === "premium"
                            ? "active_p"
                            : null
                        }`}
                      >
                        {pr_by_country.prices.premium}
                        {/*2500₼*/}
                      </span>
                      <small>/month</small>
                    </p>

                    <p>
                      {pr_by_country.media_outlets.premium}
                      {/*All mentioned in Simple plan and Oxu.az, News.Az, Olke.az,
                    Trend.az, QaynarInfo.az, Marja.az, Banker.az*/}
                    </p>
                    {country !== "Azerbaijan" ? (
                      <p>{pr_by_country.words.premium}</p>
                    ) : null}
                  </div>
                  <div className="text-center">
                    <button
                      className="current_plan_btn"
                      onClick={() => this.props.selectPackage("Premium", +pr_by_country.prices.premium.split(/\D/i).at(0))}
                    >
                      Choose this plan
                    </button>
                  </div>
                </label>
              </div>
              <div className="m_plan_item">
                <input
                  type="radio"
                  name="modal-fly"
                  id="plan_fly"
                  onChange={() => this.billingPackageChange("own")}
                  checked={
                    this.props.PR_subscription === "own"
                      ? true
                      : subscription === "own"
                      ? true
                      : false
                  }
                />
                <label htmlFor="plan_fly" className="plan_type">
                  <div className="plan_flex_top">
                    <p className="plan_p">
                      <small>
                        <img src={OwnPlan} alt="" />
                      </small>
                      <span
                        className={`${
                          this.props.PR_subscription === "own"
                            ? "active_p"
                            : subscription === "own"
                            ? "active_p"
                            : null
                        }`}
                      >
                        Own Plan
                      </span>
                    </p>
                  </div>
                  <div className="text-center">
                    <button
                      className="current_plan_btn"
                      onClick={() => this.props.selectPackage("Own")}
                    >
                      Choose this plan
                    </button>
                  </div>
                </label>
              </div>
            </div>
          )}
        </ModalBody>
      </Modal>
    );
  }
}
const mapStateToProps = (state) => {
  const { PR_subscription, country } = state.HypeIQ;
  return { PR_subscription, country };
};
export default connect(mapStateToProps, null)(PRSubscription);
