import React, { Component, Fragment } from "react";

import classes from "../../assets/css/CreateAd/index.module.css";

import Pullcampaigns from "./pulledCampaigns";
import CreateNewHype from "./create";
import HeadSearch from "../../components/CommonForBoth/Headsearch";

import { withRouter } from "react-router-dom";

import { connect } from "react-redux";
import { fetchAllHypeIQCampaigns } from "../../store/actions";

class HypeIQ extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: sessionStorage.getItem("authUser"),
    };
  }
  componentDidMount() {
    this.props.fetchAllHypeIQCampaigns(this.state.email);
  }
  toggleCreateMode = () => {
    this.props.history.push("/hypeIQ/create-hype");
  };

  render() {
    const { search, pathname } = this.props.location;

    const path = pathname.split("/").filter(el => el!=='').at(-1);

    const url = search;
    return (
      <React.Fragment>
        <div className={classes.dash_right}>
          <HeadSearch page="HypeIQ" />
          <div className={classes.create_ads}>
            <div className={classes.ads_section}>
              {path ==='hypeIQ' && !url.length? ( //page where user see all its campaigns
                <Fragment>
                  <div className={classes.cads_head}>
                    <div className={classes.cads_head_left}>
                      <h4 className={classes.cads_h4}>Current Hypes</h4>
                      <p className={classes.cads_p}>
                        Please reach out to our friendly team via
                        billing@murmurcars.com with questions
                      </p>
                    </div>
                    <button
                      type="button"
                      className={classes.create_ads_btn}
                      onClick={this.toggleCreateMode}
                    >
                      Create New Hype
                      <svg
                        width="20"
                        height="20"
                        className={classes.create_ads_img}
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5 10H15"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M10 15V5"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>
                  </div>
                  <Pullcampaigns />
                </Fragment>
              ) : path !== "create-hype" && path !== "update-campaign" ? (
                <Pullcampaigns />
              ) : (
                <CreateNewHype />
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default connect(null, { fetchAllHypeIQCampaigns })(withRouter(HypeIQ));
