import {
  LOCATECOUNTRYANDCITY,
  TITLEANDCAMPAIGNDESCRIPTION,
  TOGGLEBRANDEDCITY,
  ASSIGNPROFFECIONALS,
  ADDPROFECIONALS,
  HYPETARGETPROPERTIES,
  TOGGLEHYPEPR,
  BANNERANDLINKS,
  UPLOADSOCIALMEDIALINKS,
  BUDGETFORCAMPAIGN,
  FETCHALLCREATEDHYPECAMPAIGNSSUCCESS,
  FETCHALLCREATEDHYPECAMPAIGNSFAILED,
  CREATENEWHYPECAMPAIGN,
  CREATENEWHYPECAMPAIGNSUCCESS,
  CREATENEWHYPECAMPAIGNFAILED,
  DELETEBANNER,
  PUSHUPDATEDDETAILSOFHYPECAMPAIGNTOBACKEND,
  PUSHUPDATEDDETAILSOFHYPECAMPAIGNSUCCESS,
  PUSHUPDATEDDETAILSOFHYPECAMPAIGNFAILED,
  FETCHCAMPAIGNTOUPDATEDETAILS,
  FETCHCAMPAIGNTOUPDATEDETAILSSUCCESS,
} from "./types";

const bulk = {
  _id: "",
  loaded: false,
  assigned_profecionals: [],
  influencers: null,
  photographers: null,
  influencers_work_description: null,
  influencers_hype_target: null,
  photographers_work_description: null,
  photographers_hype_target: null,
  country: null,
  city: null,
  location: null,
  advertisers_name: null,
  advertisers_phone_number: null,
  hype_title: null,
  hype_description: null,
  target_shares: null,
  target_followers: null,
  campaign_duration: null,
  budget: null,
  branded_city: null,
  branded_city_package: null,
  branded_city_sum: null,
  PR: null,
  PR_link: null,
  PR_subscription: null,
  PR_budget: null,
  PR_file: {},
  PR_doc_name: null,
  banner_link: null,
  banner_file: null,
  banner_name: null,
  post_link: null,
  social_media: {
    instagramm: null,
    facebook: null,
    tiktok: null,
  },
};

const initialState = {
  ...bulk,
  pulled_campaigns: [],
};

const HypeIQ = (state = initialState, action) => {
  switch (action.type) {
    case LOCATECOUNTRYANDCITY: //create campaign actions starts
      state = {
        ...state,
        country: action.payload.country,
        city: action.payload.city,
      };
      break;
    case TITLEANDCAMPAIGNDESCRIPTION:
      state = {
        ...state,
        hype_title: action.payload.title,
        hype_description: action.payload.campaign_description,
        country: action.payload.country,
        city: action.payload.city,
        location: action.payload.location
      };
      break;
    case TOGGLEBRANDEDCITY:
      state = {
        ...state,
        branded_city: action.payload.state,
        branded_city_package: action.payload.subscription,
        branded_city_sum: action.payload.sum,
      };
      break;
    case ASSIGNPROFFECIONALS:
      state = {
        ...state,
        assigned_profecionals: action.payload,
      };
      break;
    case DELETEBANNER:
      state = {
        ...state,
        banner_file: null,
        banner_link: null,
        banner_name: null,
      };
      break;
    case ADDPROFECIONALS:
      const {
        category,
        state: specialist,
        work_description,
        hype_target,
      } = action.payload;
      const specialists = {};

      if (category === "influencers") {
        specialists.influencers_work_description = work_description;
        specialists.influencers_hype_target = hype_target;
      } else {
        specialists.photographers_work_description = work_description;
        specialists.photographers_hype_target = hype_target;
      }
      let assigned_profecionals = state.assigned_profecionals.filter(el => el.category !== category)
      state = {
        ...state,
        [category]: specialist,
        assigned_profecionals,
        ...specialists,
      };
      break;
    case HYPETARGETPROPERTIES:
      const {
        target_shares,
        campaign_duration,
        target_followers,
        PR_subscription,
        PR_budget,
        PR_file,
        PR_link,
        PR_doc_name,
      } = action.payload;
      state = {
        ...state,
        target_shares,
        campaign_duration,
        target_followers,
        PR_subscription,
        PR_budget,
        PR_file,
        PR_link,
        PR_doc_name,
      };
      break;
    case TOGGLEHYPEPR:
      state = {
        ...state,
        PR: action.payload,
        PR_subscription: action.payload === false ? null : state.PR_subscription,
        PR_budget:action.payload === false ?  null : state.PR_budget,
        PR_file: action.payload === false ? null : state.PR_file,
        PR_link: action.payload === false ? null : state.PR_link,
        PR_doc_name: action.payload === false ? null : state.PR_doc_name,
      };
      break;
    case BANNERANDLINKS:
      const { banner_file, banner_name, banner_link, post_link } =
        action.payload;
      state = { ...state, banner_file, banner_name, banner_link, post_link };
      break;
    case UPLOADSOCIALMEDIALINKS: //create campaign actions end
      const { instagramm, facebook, tiktok } = action.payload;
      state = { ...state, social_media: { instagramm, facebook, tiktok } };
      break;
    case BUDGETFORCAMPAIGN:
      state = { ...state, budget: action.payload };
      break;
    case CREATENEWHYPECAMPAIGN:
      state = { ...state, loaded: false };
      break;
    case CREATENEWHYPECAMPAIGNSUCCESS:
      state = {
        ...bulk,
        loaded: true,
        pulled_campaigns: [...state.pulled_campaigns, action.payload],
      };
      break;
    case CREATENEWHYPECAMPAIGNFAILED:
      state = { ...state, loaded: true };
      break;
    case FETCHALLCREATEDHYPECAMPAIGNSSUCCESS:
      state = {
        ...state,
        loaded: true,
        pulled_campaigns: action.payload,
      };
      break;

    case FETCHCAMPAIGNTOUPDATEDETAILS:
      state = { ...state, loaded: false };
      break;
    case FETCHALLCREATEDHYPECAMPAIGNSFAILED:
      state = { ...state, loaded: true };
      break;
    case FETCHCAMPAIGNTOUPDATEDETAILSSUCCESS:
      state = { ...state, loaded: true, ...action.payload };
      break;
    case PUSHUPDATEDDETAILSOFHYPECAMPAIGNTOBACKEND:
      state = { ...state, loaded: false };
      break;
    case PUSHUPDATEDDETAILSOFHYPECAMPAIGNSUCCESS:
     
      state = {
        ...bulk,
        loaded: true,
        pulled_campaigns: state.pulled_campaigns.map((campaign) => {
          if (campaign._id === action.payload._id) {
            return action.payload;
          } else {
            return campaign;
          }
        }),
      };
      break;
    case PUSHUPDATEDDETAILSOFHYPECAMPAIGNFAILED:
      state = { ...state, loaded: true };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default HypeIQ;
