import React from "react";

import axios from "axios";

import GoogleMap from "./map";

import { Link, withRouter } from "react-router-dom";

import ArrowDown from "../../assets/css/common/icons/arrow-down.svg";
import Trash2 from '../../assets/css/common/icons/trashRed.svg'
import Edit from "../../assets/css/CreateAd/ads-details/edit.svg";
import ImgEdit from "../../assets/css/CreateAd/ads-details/image-edit.svg";
import ArrowLeft from "../../assets/css/CreateAd/ads-details/arrow-left.svg";
import classes2 from "../../assets/css/CreateAd/ads-details/index.module.css";

import { Upload } from "antd";

import Geocode from "react-geocode";

const { Dragger } = Upload;

const GOOGLE_MAP_KEY = process.env.REACT_APP_GOOGLEMAPKEY;
Geocode.setApiKey(GOOGLE_MAP_KEY);

const propsD = {
  name: "file",
  multiple: true,

  onDrop(e) {
    
  },
};

class AdDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      advertisers_email: "",
      loaded: false,
      map: {
        center: {
          lat: 0,
          lng: 0,
        },
        coordinates: [],
        zoom: 8,
        postCenter: {
          lat: 0,
          lng: 0,
        },
      },
      updates: [
        {
          daily_budget: "",
          audienceAge: "",
          audienceGender: "",
          campaign_name: "",
          campaign_type: "",
          artWork_url: "",
          display_quantity: "",
          area: [],
          country: "",
          image: "",
          file: {},
        },
      ],
      editable: false,
    };
  }
  toggleEditMode = () => {
    this.setState({ ...this.state, editable: true });
  };
  handleFileChange = (info) => {
    const reader = new FileReader();
    reader.readAsDataURL(info.file);
    const Img = new Image();

    const scope = this;

    reader.onload = (e) => {


        const updates = scope.state.updates;
        updates[0].artWork = {type: info.file.type, url:e.target.result}
        updates[0].file = info.file;

        scope.setState({ ...scope.state, updates });
      
    };
  };
  handleDetailsUpdate = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    const updates = this.state.updates;
    updates[0][name] = value;
    this.setState({ ...this.state, updates });
  };
  handleChange = (event) => {
    const value = event.target.value;
    const { updates } = this.state;
    const { area, country } = updates[0];

    const { campaign } = this.props;
    const { locations } = campaign[0];

    const Locations = [];

    let multiple = false;

    if (locations.length > 1) multiple = true;

    if (multiple) {
      for (let location of area) {
        if (location === value) {
          return;
        }
      }
      Locations.push(...area);

      if (Locations.length === locations.length) {
        Locations.pop();
      }
    }
    Locations.push(value);

    axios
      .post(
        "https://backendapp.murmurcars.com/api/v1/zipcode/get-multiple-polygon-coords",
        {
          districts: Locations,
          country: country,
        }
      )
      .then((resp) => {
        const { polygons, center } = resp.data;

        updates[0].area = Locations;
        this.setState({
          ...this.state,
          updates,
          map: {
            ...this.state.location,
            coordinates: polygons,
            center: center,
            postCenter: center,
          },
        });
      })
      .catch((err) => {
        // 
      });
  };

  handleUSZipsChange = (e) => {
    e.persist();
    const value = e.target.value;
    const { updates } = this.state;
    const { area, country } = updates[0];

    const { campaign } = this.props;
    const { locations, ad_schedule } = campaign[0];

    if (value.length === 5) {
      const Locations = [];

      let multiple = false;
      if (locations.length > 1) multiple = true;

      if (multiple) {
        for (let location of area) {
          if (location === value) {
            return;
          }
        }
        Locations.push(...area);
        if (Locations.length === locations.length) {
          Locations.pop();
        }
      }
      Locations.push(value);
      axios
        .post(
          "https://backendapp.murmurcars.com/api/v1/zipcode/get-multiple-polygon-coords",
          {
            districts: Locations,
            country: country,
          }
        )
        .then((resp) => {
          const { polygons, center } = resp.data;

          updates[0].area = Locations;

          this.setState({
            ...this.state,
            updates,
            map: {
              ...this.state.location,
              coordinates: polygons,
              center: center,
              postCenter: center,
            },
          });

          e.target.value = "";
        })
        .catch((err) => {
          // 
        });
    }
  };

  submitUpdates = () => {
    const updates = this.state.updates[0];
    const { _id, ad_schedule, ad_schedule_time} = this.props.campaign[0];

    const area = updates.area;
    //const advertisers_email = updates.advertisers_email;
    const campaign_type = updates.campaign_type;
    const campaign_name = updates.campaign_name;
    const daily_budget = updates.daily_budget;
    const display_quantity = updates.display_quantity;
    const file = updates.file;
    const audienceAge = updates.audienceAge;
    const audienceGender = updates.audienceGender;
    const artWork_url = updates.artWork_url;
    const ad_type = updates.ad_type;

    this.setState({ ...this.state, loaded: false });

    const formData = new FormData();
    formData.append("advertisers_email", this.state.advertisers_email);
    formData.append("campaign_type", campaign_type);
    formData.append("campaign_name", campaign_name);
    formData.append("ad_schedule", ad_schedule);
    //formData.append("ad_status", ad_status);
    formData.append("ad_type", ad_type);
    for (let el of area) {
      formData.append("area", JSON.stringify(el));
    }

    formData.append("daily_budget", daily_budget);
    formData.append("display_quantity", display_quantity);
    formData.append("file", file);
    formData.append("audienceAge", audienceAge);
    formData.append("audienceGender", audienceGender);
    formData.append("ad_schedule_time", ad_schedule_time);
    formData.append("artWork_url", artWork_url);
    axios({
      url: `https://backendapp.murmurcars.com/api/v1/campaigns/${_id}`,
      method: "PUT",
      data: formData,
    })
      .then((res) => {
        this.setState({
          ...this.state,
          loaded: true,
          editable: false,
        });
      })
      // .catch((err) => 
  };

  componentDidMount() {
    const { campaign } = this.props;
    const { area, locations, country, city } = campaign[0];
    
    const Locations = [];
    if (area) {
      Locations.push(area);
    } else {
      Locations.push(...locations);
    }

  
    if (Locations.at(0)) {
      axios
        .post(
          "https://backendapp.murmurcars.com/api/v1/zipcode/get-multiple-polygon-coords",
          {
            districts: Locations,
            country: country
              ? country
              : /[1-9]/i.test(area)
              ? `USA` 
              : "Azerbaijan",
          }
        )
        .then((res) => {
          const { polygons, center } = res.data;

          this.setState({
            ...this.state,
            ad_status: campaign[0].ad_status,
            advertisers_email: sessionStorage.getItem("authUser"),
            loaded: true,
            map: {
              ...this.state.map,
              coordinates: polygons,
              postCenter: center,
              center: center,
              zoom: 10,
            },
            updates: [
              {
                ...this.state.updates,
                daily_budget: campaign[0].daily_budget,
                ad_type: campaign[0].ad_type,
                audienceAge: campaign[0].audienceAge,
                audienceGender: campaign[0].audienceGender,
                campaign_name: campaign[0].campaign_name,
                campaign_type: campaign[0].campaign_type,
                image: campaign[0].artWork_url,
                display_quantity: campaign[0].display_quantity,
                area: Locations,
                country: country,
                artWork: campaign[0].artWork
              },
            ],
          });
        })
        .catch((err) => alert(err));
      }{
        Geocode.fromAddress(`${country}, ${city}`)
        .then((response) => {
          const { lat, lng } = response.results[0].geometry.location;
          const center = {
            lat,
            lng,
          };
          this.setState(state => ({
            ...state,
            loaded: true,
            map: {
              ...state.map,
              //coordinates: polygons,
              postCenter: center,
              center: center,
              zoom: 10,
            },
            updates: [
              {
                ...this.state.updates,
                daily_budget: campaign[0].daily_budget,
                ad_type: campaign[0].ad_type,
                ad_status: campaign[0].ad_status,
                advertisers_email: sessionStorage.getItem("authUser"),
                audienceAge: campaign[0].audienceAge,
                audienceGender: campaign[0].audienceGender,
                campaign_name: campaign[0].campaign_name,
                campaign_type: campaign[0].campaign_type,
                artWork_url: campaign[0].artWork_url,
                display_quantity: campaign[0].display_quantity,
                area: Locations,
                country: country,
                artWork: campaign[0].artWork,
              },
            ],
          }))
        })
        .catch((err) => {
          
        });
      }
  }
  deletePolygonFromStack = (index) => {
    const { updates, map } = this.state;
    const { coordinates: coordinatesState } = map;
    const { area: locationsState } = updates[0];

    const leftCoordinates = coordinatesState.slice(0, index);
    const rightCoordinates = coordinatesState.slice(
      index + 1,
      coordinatesState.length
    );
    const coordinates = [...leftCoordinates, ...rightCoordinates];

    const leftLocations = locationsState.slice(0, index);
    const rightLocations = locationsState.slice(
      index + 1,
      locationsState.length
    );
    const locations = [...leftLocations, ...rightLocations];

    updates[0].area = locations;
    this.setState({
      ...this.state,
      map: {
        ...this.state.map,
        coordinates,
      },
      updates,
    });
  };

  render() {
    const { editable } = this.state;
    const { ad_status, active } = this.props.campaign[0];
    const url = this.props.location.search;
    const id = new URLSearchParams(url).get("campaign");

    let status = ad_status;

    console.log(this.state)

    return (
      <React.Fragment>
        {!this.state.loaded && (
          <div id="preloader">
            <div id="status">
              <div className="spinner-chase">
                <div className="chase-dot"></div>
                <div className="chase-dot"></div>
                <div className="chase-dot"></div>
                <div className="chase-dot"></div>
                <div className="chase-dot"></div>
                <div className="chase-dot"></div>
              </div>
            </div>
          </div>
        )}
        {this.state.loaded &&
          this.state.updates.map((campaign, index) => (
            <div className={classes2.ads_details_section} key={index}>
              <Link to="/ad-manager" className={classes2.ads_back_icon}>
                <img src={ArrowLeft} alt="" className={classes2.ads_left_img} />
                <span>Back</span>
              </Link>

              <div className={classes2.current_ads_name}>
                <div className={classes2.crrnt_ads_title}>
                  <p>{campaign.campaign_name}</p>

                  {status !== "In Review" ? (
                    <span
                      className={`${
                        status
                          ? classes2.cads_active_dot
                          : classes2.cads_deactive_dot
                      }`}
                    >
                      <span className={classes2.cads_dot}></span>
                      {active ? 'Active' : 'Deactive'}
                    </span>
                  ) : (
                    <span className={classes2.in_review}>{status}</span>
                  )}

                  {/*<!-- <span class="cads_deactive_dot"><span class="cads_dot"></span>Deactive</span> -->*/}
                </div>
                <button
                  type={editable ? "submit" : "button"}
                  className={classes2.crrnt_edit}
                  onClick={() => {
                    if (editable) {
                      this.submitUpdates();
                    } else {
                      this.toggleEditMode();
                    }
                  }}
                >
                  <span>{`${editable ? "Update" : "Edit"}`}</span>

                  <img src={Edit} alt="" className={classes2.crrnt_edit_img} />
                </button>
              </div>
              <div
                className={`${classes2.ads_detail_info} ${classes2.ads_detail_row}`}
              >
                <div className={classes2.ads_detail_image}>
                  <div className={classes2.ads_detail_col}>
                    <div className={classes2.detail_img}>
                    { campaign.artWork.type.includes("image") ?
                     
                     <img
                       src={campaign.artWork.url}
             
                     />
                   :
                 

                 <video width="320" height="240" controls>
                   <source src={campaign.artWork.url} type={campaign.artWork.type} />

                   Your browser does not support the video tag.
                 </video>
               }
                    </div>
                    <div className={classes2.detail_img_title}>
                      <span>Current Media</span>
                      {!editable ? (
                        <button
                          type="button"
                          className={classes2.detail_img_edit}
                        ></button>
                      ) : (
                        <Dragger
                          {...propsD}
                          beforeUpload={() => false}
                          showUploadList={false}
                          onChange={(info) => this.handleFileChange(info)}
                          style={{
                            background: "white",
                            borderColor: "transparent",
                          }}
                        >
                          <img src={ImgEdit} alt="" />
                        </Dragger>
                      )}
                    </div>
                  </div>
                </div>
                <div className={classes2.ads_detail_audience}>
                  <div className={classes2.ads_detail_col}>
                    <div className={classes2.ads_detail_top}>
                      <h5 className={classes2.detail_top_h5}>Audience</h5>
                    </div>
                    <div
                      className={`${classes2.ads_details_content} ${
                        this.state.editable && classes2.edit_inline_flex
                      }`}
                    >
                      <ul className={classes2.ads_detail_age}>
                        {!editable ? (
                          <li>
                            <span>Age</span>
                            <p className={classes2.detail_content_p}>
                              {campaign.audienceAge}
                            </p>
                          </li>
                        ) : (
                          <li>
                            <span>Age</span>
                            <div
                              className={`${classes2.details_edit_select} ${classes2.mt_8}`}
                            >
                              <select
                                name="audienceAge"
                                onChange={this.handleDetailsUpdate}
                              >
                                <option value={campaign.audienceAge}>
                                  {campaign.audienceAge}
                                </option>
                                {[
                                  "18-25",
                                  "26-35",
                                  "36-45",
                                  "46-55",
                                  "56-65",
                                ].map(
                                  (age, index) =>
                                    age !== campaign.audienceAge && (
                                      <option key={index} value={age}>
                                        {age}
                                      </option>
                                    )
                                )}
                              </select>
                              <img
                                src={ArrowDown}
                                alt="arrow"
                                className={classes2.details_edit_arrow}
                              />
                            </div>
                          </li>
                        )}
                        <li>
                          <span className={classes2.age_gender_line}></span>
                        </li>
                        {!editable ? (
                          <li>
                            <span>Gender</span>
                            <p className={classes2.detail_content_p}>
                              {campaign.audienceGender}
                            </p>
                          </li>
                        ) : (
                          <li>
                            {" "}
                            <span>Gender</span>
                            <div
                              className={`${classes2.details_edit_select} ${classes2.mt_8}`}
                            >
                              <select
                                name="audienceGender"
                                onChange={this.handleDetailsUpdate}
                              >
                                <option value={campaign.audienceGender}>
                                  {campaign.audienceGender}
                                </option>
                                {["Male", "Female", "Both"].map(
                                  (gender, index) =>
                                    gender !== campaign.audienceGender && (
                                      <option value={gender} key={index}>
                                        {gender}
                                      </option>
                                    )
                                )}
                              </select>
                              <img
                                src={ArrowDown}
                                alt="arrow"
                                className={classes2.details_edit_arrow}
                              />
                            </div>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className={classes2.ads_detail_quantity}>
                  <div className={classes2.ads_detail_col}>
                    <div className={classes2.ads_detail_top}>
                      <h5 className={classes2.detail_top_h5}>
                        Display Quantity
                      </h5>
                    </div>
                    <div
                      className={`${classes2.ads_details_content} ${
                        this.state.editable && classes2.edit_inline_flex
                      }`}
                    >
                      {!editable ? (
                        <p className={classes2.detail_content_p}>
                          {campaign.display_quantity}
                        </p>
                      ) : (
                        <input
                          type="text"
                          name="display_quantity"
                          className={classes2.ads_edit_input}
                          value={campaign.display_quantity}
                          onChange={this.handleDetailsUpdate}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`${classes2.budget_category_are} ${classes2.ads_detail_row}`}
              >
                <div className={classes2.ads_detail_budget}>
                  <div className={classes2.ads_detail_col}>
                    <div className={classes2.ads_detail_top}>
                      <h5 className={classes2.detail_top_h5}>Daily Budget</h5>
                    </div>
                    <div className={classes2.ads_details_content}>
                      {!editable ? (
                        <p className={classes2.detail_content_p}>
                          {campaign.daily_budget}
                        </p>
                      ) : (
                        <input
                          type="text"
                          name="daily_budget"
                          className={classes2.ads_edit_input}
                          value={campaign.daily_budget}
                          onChange={this.handleDetailsUpdate}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className={classes2.ads_detail_category}>
                  <div className={classes2.ads_detail_col}>
                    <div className={classes2.ads_detail_top}>
                      <h5 className={classes2.detail_top_h5}>Category</h5>
                    </div>
                    <div className={classes2.ads_details_content}>
                      {!editable ? (
                        <p className={classes2.detail_content_p}>
                          {campaign.ad_type}
                        </p>
                      ) : (
                        <div className={classes2.details_edit_select}>
                          <select
                            name="campaign_type"
                            onChange={this.handleDetailsUpdate}
                          >
                            <option>{campaign.ad_type}</option>
                            {[
                              "Consumer Services",
                              "Consumer Products",
                              "Business Service",
                              "Business Products",
                              "Real-Estate",
                              "Insurance",
                              "Mortgage",
                              "Home Services",
                              "Professional Services",
                              "Automotive",
                            ].map(
                              (type, index) =>
                                type !== campaign.ad_type && (
                                  <option key={index} value={type}>
                                    {type}
                                  </option>
                                )
                            )}
                          </select>
                          <img
                            src={ArrowDown}
                            alt="arrow"
                            className={classes2.details_edit_arrow}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className={classes2.ads_detail_area}>
                  <div className={classes2.ads_detail_col}>
                    <div className={classes2.detail_img}>
                      <GoogleMap
                        state={this.state.map}
                        deletePolygonFromStack={
                          editable && this.deletePolygonFromStack
                        }
                      />
                    </div>
                    <div className={classes2.detail_map_title}>
                      <span>Targer Area</span>
                      {!editable ? (
                        <small>{campaign.country}</small>
                      ) : /[1-9]/i.test(campaign.area) || campaign.country === 'US' ? (
                        <input
                          type="text"
                          defaultValue="type zip code"
                          name="area"
                          onChange={this.handleUSZipsChange}
                        />
                      ) : (
                        <div className={classes2.ddetails_edit_select}>
                          {" "}
                          <select name="area" onChange={this.handleChange}>
                            {[
                              "Nizami",
                              "Nasimi",
                              "Khazar",
                              "Sabunchu",
                              "Qaradaq",
                              "Binaqadi",
                              "Narimanov",
                              "Sabayil",
                              "Pirallahı",
                              "Xətai",
                              "Yasamal",
                              "Suraxanı",
                            ].map((district, i) => {
                              return (
                                <option value={district.toLowerCase()} key={i}>
                                  {district}
                                </option>
                              );
                            })}
                          </select>
                          <img
                            src={ArrowDown}
                            alt="arrow"
                            className={classes2.details_edit_arrow}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes2.delete_ads}>
                <button
                  type="button"
                  className={classes2.delete_ads_btn}
                  onClick={() => this.props.delete(id, "from_details")}
                >
                  <span>Delete Ad</span>
                  <img src={Trash2} alt="" />
                </button>
                <span className={classes2.delete_ads_note}>
                  *There is no backup for deleted AD’s
                </span>
              </div>
            </div>
          ))}
      </React.Fragment>
    );
  }
}

export default withRouter(AdDetails);
