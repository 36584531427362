import React from "react";

import { Modal, ModalBody, ModalHeader, Row, Col } from "reactstrap";

import classes from "../../../../assets/css/hypeIQ/modal.module.scss";
import classes2 from "../../../../assets/css/CreateAd/ad-creative/index.module.css";
import "../../../../assets/css/hypeIQ/uploaded_assets.scss";
import CloseBtn from "../../../../assets/css/common/icons/close.svg";
import Backup from "../../../../assets/css/CreateAd/backup.svg";
import Trash from "../../../../assets/css/CreateAd/trash.svg";

import { Upload } from "antd";

import PDF from "../../../../assets/css/hypeIQ/pdf.png";
import DOCX from "../../../../assets/css/hypeIQ/docx.png";
const { Dragger } = Upload;

class PRLink extends React.Component {
  render() {
    const { show, toggle } = this.props;

    return (
      <Modal
        isOpen={show}
        toggle={toggle}
        className={`${classes.modal} ${classes.social}`}
      >
        <ModalHeader>
          <div className={classes.modal_header}>
            <span>Upload Press-Release</span>{" "}
            <button onClick={() => toggle(false)}>
              <img src={CloseBtn} alt="" />
            </button>
          </div>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col className="d-flex flex-column align-items-center">
              <div className={classes2.media_block}>
                <Dragger
                  listType="picture-card"
                  beforeUpload={() => false}
                  showUploadList={false}
                  onChange={(info) => this.props.uploadPRDocument(info)}
                  style={{
                    background: "white",
                    borderColor: `${"transparent"}`,
                  }}
                >
                  <img src={Backup} alt="" />
                  <h4 className={classes2.media_h4}>Drag & Drop</h4>
                  <h5 className={classes2.media_h5}>
                    Drag or click here to upload
                  </h5>
                  <button type="button" className={classes2.media_upload_btn}>
                    Upload Document
                  </button>
                </Dragger>
              </div>
              {this.props.PR_doc_name != null ? (
                <div className="uploaded_doc">
                  <img
                    src={this.props.PR_doc_name.includes("pdf") ? PDF : DOCX}
                    alt=""
                  />
                  <p>{this.props.PR_doc_name}</p>
                  <img
                    className="trash_icon"
                    src={Trash}
                    alt=""
                    onClick={this.props.deleteUploadedDocument}
                  />
                </div>
              ) : null}
              <div className={classes.btns}>
                <button
                  type="button"
                  onClick={() => {
                    toggle(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={() => {
                    toggle(true);
                  }}
                >
                  Save
                </button>
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    );
  }
}

export default PRLink;
