import React, { Component } from "react";
import StreetIQMap from "./map";

import { Button, Alert } from "reactstrap";

import SearchNormal from "../../assets/css/Settings/search-normal.svg";
import Close from "../../assets/css/StreetIQ/close.svg";
import ArrowRight from "../../assets/css/CreateAd/arrow-right.svg";
import classes from "../../assets/css/Dashboard/dashboard.module.css";
import classes2 from "../../assets/css/StreetIQ/index.module.css";
import "./scroolbar.css";
import "../../assets/css/app.css";
import LocationMarker from "../../assets/images/location-marker.svg";
import PlacesMarker from "../../assets/images/places-marker.svg";
import Earth from "../../assets/images/earth-spins.gif";

import MapMulti from "../../assets/css/StreetIQ/map-multi.svg";

import Geocode from "react-geocode";

import axios from "axios";

import Statistic from "./statistic";
import Filters from "./filters";
import Charts from "./charts";
import LocationModal from "../../components/location-modal";

const GOOGLE_MAP_KEY = process.env.REACT_APP_GOOGLEMAPKEY;
Geocode.setApiKey(GOOGLE_MAP_KEY);

let ref = null;
class StreetIQMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locationModal: false,
      locationLoader: false,
      statistic: {
        areaStatistic: {},
        display: false,
        district: "",
        modal: false,
        loading: false,
        activeTab: "1",
        hasStatistic: true,
      },
      filterMethod: {}, //all applied filters
      filters: [], //alll filters specific to that location
      google: {
        country: "",
        city: "",
        address: "",
        districts: [],
        location: "",
        hint: [],
        center: {
          lat: 0,
          lng: 0,
        },
        postCenter: {
          lat: 0,
          lng: 0,
        },
        zoom: 12,
        coordinates: [],
        markers: [],
        marker_places_icon: PlacesMarker,
        marker_location_icon: LocationMarker,
        loaded: false,
        errorZipCode: false,
        errorMessage: null,
        alert_status: false,
        permission: true,
      },
      places: {
        place: "",
        list_of_places: [],
      },
    };
  }

  //selecting options in filters
  selectFilterData = (option, type) => {
    const value = option.target.id;

    this.setState((state) => {
      const filterMethod = state.filterMethod;
      filterMethod[type] = value; //segment(income,age and setera) and its filter value
      return { ...state, filterMethod: { ...filterMethod, filtering: true } };
    });
  };
  resetFilters = () => {
    this.setState((state) => {
      const filterMethod = state.filterMethod;
      for (let filter in filterMethod) {
        filterMethod[filter] = "";
      }
      return {
        ...state,
        filterMethod: { ...filterMethod, display: true, filtering: false },
      };
    });
  };
  locateGeolocationOnMapFromCity = (location) =>
    new Promise((resolve, reject) => {
      Geocode.fromAddress(location)
        .then((response) => {
          const { lat, lng } = response.results[0].geometry.location;
          const center = {
            lat,
            lng,
          };
          resolve(center);
        })
        .catch((err) => {
          reject();
        });
    });
  //switching from seaching by filter and input
  switchToDifferentQueryMode = () => {
    const filterMethod = this.state.filterMethod;
    filterMethod.display = !filterMethod.display;
    const statistic = this.state.statistic;
    //statistic.display = false;
    const google = this.state.google;
    //const polygons = [];
    //const districts = google.districts; //when send filter data to backend it returns districts with polygons we store districts in districts

    //if (polygons.length && google.coordinates) {
    // google.coordinates = polygons; //if we already cashed the polygons in state
    /*} else if (districts.length) {  //
      axios
        .post(
          "https://backendapp.murmurcars.com/api/v1/zipcode/get-multiple-polygon-coords",
          {
            districts,
            country: google.address,
          }
        )
        .then((resp) => {
          google.coordinates = resp.data.polygons;

          google.zoom = 10;
          google.places = [];
          const segments = [];
          return this.setState({
            ...this.state,
            filterMethod: {
              display: filterMethod.display,
              Population: "",
              Age: "",
              Gender: "",
              Marrige: "",
              Ethnisity: "",
              Birth: "",
              Places: "",
              Business: "",
              Small_Business: "",
              Retired: "",
              Non_Retired: "",
              Income: "",
              Race: "",
              Real_Estate: "",
              Education: "",
            },
            statistic,
            google,
            segments,
          });
        })
        .catch((err) => 
    }*/
    google.zoom = 10;
    this.setState({ ...this.state, filterMethod, statistic, google });
  };

  //filter method (sending filter data to backend)
  searchLocationsByFilterData = () => {
    const filters = this.state.filterMethod;
    const google = this.state.google;
    const country = this.state.google.country;
    let city = this.state.google.city;
    let url = "";
    if (country === "USA") {
      url = `https://backendapp.murmurcars.com/api/v1/zipcode/filter-us-demographics/${city}`;
    } else {
      url = `https://backendapp.murmurcars.com/api/v1/zipcode/filter-${city}-demographics`;
    }
    google.loaded = false;
    this.setState({ ...this.state, google });
    axios
      .post(url, {
        filters,
      })
      .then(async (res) => {
        const { locations, polygons } = res.data;
        const markers = [];
        let center;

        try {
          if (polygons == null) {
            for (let location of locations) {
              center = await this.locateGeolocationOnMapFromCity(
                `${city},${location}`
              );

              markers.push(center);
            }
          } else {
            center = await this.locateGeolocationOnMapFromCity(
              `${country},${city}`
            );
          }
          //center = markers[0];

          google.center = center;
          google.postCenter = center;
          google.coordinates = polygons != null ? polygons : [];
          google.districts = locations;
          google.markers = markers != null ? markers : [];
          google.zoom = 10;
          google.loaded = true;
          this.setState({
            ...this.state,
            google,
          });
        } catch (err) {
          google.loaded = true;
          this.setState({
            ...this.state,
            google,
          });
        }
      })
      .catch((err) => {
        google.loaded = true;
        this.setState({
          ...this.state,
          google,
        });
      });
  };

  onPlaceSelected = (e) => {
    const value = e.target.value;

    this.setState({
      ...this.state,
      places: {
        ...this.state.places,
        place: value,
      },
    });
  };
  //location input
  onChangeLocationToZoomIn = (e) => {
    const google = this.state.google;
    google.location = e.target.value;
    this.setState({ ...this.state, google });
  };

  //submiting to backend and getting polygons and statistics
  submitLocationToZoomIn = async (e) => {
    e.preventDefault();
    const { statistic, google, places } = this.state;
    const { country, city, location } = google;
    const place = places.place;
    places.list_of_places = [];

    if (
      country !== "USA" &&
      country !== "Azerbaijan" &&
      country !== "Georgia"
    ) {
      //google.errorMessage = "we dont provide data in this country yet";
      google.permission = true;
      google.alert_status = true;
      //google.errorZipCode = true;
      this.setState({
        ...this.state,
        google,
      });
      return;
    }

    try {
      google.loaded = false;
      this.setState({ ...this.state, google });

      const center = await this.locateGeolocationOnMapFromCity(
        `${country},${city},${location},${country === "USA" ? "zip" : "raion"}`
      );

      if (google.address.includes("USA")) {
        axios
          .post(
            `https://backendapp.murmurcars.com/api/v1/zipcode/get-zipcode-polygon-coords`,
            {
              location,
              place,
              center,
            }
          )
          .then((res) => {
            if (res.data.status === 204) {
              /*google.loaded = true;
              //google.hint = res.data.hints;
              //google.errorMessage = res.data.message;
              //google.errorZipCode = true;
              google.toggleCartWithAreaInformation = false;
              this.setState({
                ...this.state,
                google,
                places: [],
              });*/
              google.toggleCartWithAreaInformation = false;
              google.errorZipCode = true;
              google.loaded = true;
              this.setState({
                ...this.state,
                google,
              });
              return;
            }

            if (res.data.places) {
              places.list_of_places = res.data.places.results;
            }
            if (res.data.polygons) {
              google.coordinates = [res.data.polygons];
              google.markers = null;
            } else {
              google.coordinates = null;
              google.markers.push(center);
            }

            statistic.areaStatistic = res.data.areaStatistic;
            statistic.display = true;

            places.place = "";
            google.loaded = true;
            google.toggleCartWithAreaInformation = true;
            google.postCenter = center;
            google.center = center;
            google.location = "";

            this.setState({
              ...this.state,
              google,
              statistic,
              places,
            });
          })
          .catch((err) => {
            const google = this.state.google;
            google.errorMessage = err.message;
            google.errorZipCode = true;
            this.setState({
              ...this.state,
              google,
            });
          });
      } else {
        axios
          .post(
            `https://backendapp.murmurcars.com/api/v1/zipcode/get-district-information/${country}`,
            {
              district: location,
              place,
              center,
            }
          )
          .then((res) => {
            if (res.data.status === 204) {
              /*  google.hint = res.data.hints;
              google.toggleCartWithAreaInformation = false;
              google.errorMessage = res.data.message;
              google.errorZipCode = true;
              google.loaded = true;
              this.setState({
                ...this.state,
                google,
                places,
              });*/
              google.toggleCartWithAreaInformation = false;
              google.errorZipCode = true;
              google.loaded = true;
              this.setState({
                ...this.state,
                google,
              });
              return;
            }
            if (res.data.places) {
              places.list_of_places = res.data.places.results;
            }
            google.coordinates = [res.data.polygons];

            statistic.areaStatistic = res.data.areaStatistic;
            statistic.district = this.state.google.location;
            statistic.display = true;

            places.place = "";
            google.loaded = true;
            google.toggleCartWithAreaInformation = true;
            google.postCenter = center;
            google.center = center;
            google.location = "";

            this.setState({
              ...this.state,
              google,
              statistic,
              places,
            });
          })
          .catch((err) => {
            google.toggleCartWithAreaInformation = false;
            google.errorZipCode = true;
            google.loaded = true;
            this.setState({
              ...this.state,
              google,
            });
          });
      }
    } catch (err) {
      google.toggleCartWithAreaInformation = false;
      google.errorZipCode = true;
      google.loaded = true;
      this.setState({
        ...this.state,
        google,
      });
    }
  };

  //filter method dhows all location and this is method for selecting and rendering data
  selectedLocation = (district, index) => {
    const { statistic, google, filterMethod } = this.state;
    const address = google.address;

    let url = "";
    google.loaded = false;
    this.setState({ ...this.state, google });
    if (!address.includes("US")) {
      const country = google.country;
      url = `https://backendapp.murmurcars.com/api/v1/zipcode/get-district-information/${country}`;
    } else {
      url =
        "https://backendapp.murmurcars.com/api/v1/zipcode/get-us-information";
    }
    axios.post(url, { district }).then((resp) => {
      statistic.areaStatistic = resp.data.areaStatistic;
      statistic.district = district;
      statistic.display = true;

      let markers = google.markers;

      if (google.coordinates.length) {
        google.coordinates = [google.coordinates[index]];
        google.markers = null;
      } else {
        google.markers = [markers[index]];
        google.coordinates = null;
      }

      filterMethod.display = false;

      this.handleGeocode(district, google, { statistic, filterMethod });
    });
  };
  /*selectedDataSegment = (type) => {
    const google = this.state.google;
    this.setState({ ...this.state, segments: [type], google });
  };*/

  componentDidMount() {
    // if (!firebase.apps.length) {
    //   firebase.initializeApp(firebaseConfig);
    // } else {
    //   firebase.app(); // if already initialized, use that one
    // }

    //this.handleConnectionToFirebaseRealTimeDatabase();

    navigator.geolocation.getCurrentPosition(
      (position) => {
        const google = this.state.google;
        /* google.postCenter = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };

        google.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };*/

        google.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        google.postCenter = google.center;

        google.permission = true;

        this.setState({
          ...this.state,
          google,
        });
        this.handleReverseGeocode();
      },
      (err) => {
        this.setState({
          ...this.state,
          google: {
            ...this.state.google,
            loaded: true,
          },
        });
      }
    );
  }

  /*handleConnectionToFirebaseRealTimeDatabase = () => {
    ref = firebase.database().ref("users/");
    ref.on("value", (snapshot) => {
      const newValue = snapshot.val();
      //Convert Objects into Array
      let newArrayOfDrivers = Object.values(newValue);
      const google = this.state.google;
      google.drivers = newArrayOfDrivers;
      google.NumberOfDrivers = newArrayOfDrivers.length;
      this.setState({
        ...this.state,
        google,
      });
    });
  };*/

  handleGeocode = (location, google, rest) => {
    const { city } = this.state.google;

    Geocode.fromAddress(city + " " + location)
      .then((response) => {
        const { lat, lng } = response.results[0].geometry.location;

        google.loaded = true;
        google.toggleCartWithAreaInformation = true;
        google.postCenter = { lat, lng };
        google.center = { lat, lng };
        google.location = "";

        this.setState({
          ...this.state,
          google,
          ...rest,
        });
      })
      .catch((err) => {
        google.loaded = true;
        google.toggleCartWithAreaInformation = true;
        google.location = "";
        this.setState({
          ...this.state,
          google,
          ...rest,
        });
      });
  };
  handleReverseGeocode = () => {
    const google = this.state.google;
    Geocode.fromLatLng(
      this.state.google.postCenter.lat,
      this.state.google.postCenter.lng
    ).then((response) => {
      //const address = response.results.at(0).formatted_address;

      const address = response.plus_code.compound_code;
      const country = address
        .split(/\s|[,]/i)
        .filter((el) => el !== "")
        .at(-1)
        .trim();
      let city = address
        .split(/\s|[,]/i)
        .filter((el) => el !== "")
        .at(-2)
        .trim();

      if (country === "USA") {
        city = city === "Illinois" || city === "IL" ? "Chicago" : "SF";
      }

      axios
        .get(
          `https://backendapp.murmurcars.com/api/v1/zipcode/get-filters/${country}`
        )
        .then(
          (response) => {
            const { data } = response;
            google.address = address;
            google.country = country;
            google.city = city;
            google.loaded = true;

            const filterMethod = {};

            for (let key of data) {
              filterMethod[key.filter] = "";
            }

            this.setState({
              ...this.state,
              google,
              filters: data,
              filterMethod: {
                display: false,
                filtering: false,
                ...filterMethod,
              },
            });
          },
          () => {
            google.loaded = true;
            this.setState({ ...this.state, google });
          }
        );
    });
  };

  showMoreInDatabox = () => {
    document.getElementById("scroll").scrollTop = 0;
    this.setState({
      ...this.state,
      statistic: {
        ...this.state.statistic,
        modal: !this.state.statistic.modal,
        loading: false,
      },
    });
  };

  /*componentWillUnmount() {
    ref.off();
  }*/
  reverseGeolocation = (country, city) =>
    new Promise((resolve, reject) => {
      Geocode.fromAddress(`${country}, ${city}`)
        .then((response) => {
          const { lat, lng } = response.results[0].geometry.location;
          const center = {
            lat,
            lng,
          };
          resolve(center);
        })
        .catch((err) => {
          reject();
        });
    });

  changeToDifferentCity = (country, city) => {
    this.setState((state) => ({
      ...state,
      locationModal: false,
      locationLoader: true,
    }));
    this.reverseGeolocation(country, city).then((center) => {
      this.setState((state) => ({
        ...state,
        locationLoader: false,
        statistic: {
          ...state.statistic,
          modal: false,
          display: false,
          district: ''
        },
        google: {
          ...state.google,
          permission: true,
          alert_status: false,
          country,
          city,
          address: `${country}, ${city}`,
          center,
          postCenter: center,
        },
      }));
    });
  };
  render() {
    const {
      filterMethod,
      google,
      statistic,
      filters,
      places,
      locationModal,
      locationLoader,
    } = this.state;
    const {
      loaded,
      address,
      alert_status,
      permission,
      errorZipCode,
      country,
      city,
    } = google;
    const { modal, activeTab, hasStatistic } = statistic;

    return (
      <React.Fragment>
        {!loaded ? (
          <div id="preloader" style={{ opacity: 0.7 }}>
            <div id="status">
              <div className="spinner-chase">
                <div className="chase-dot"></div>
                <div className="chase-dot"></div>
                <div className="chase-dot"></div>
                <div className="chase-dot"></div>
                <div className="chase-dot"></div>
                <div className="chase-dot"></div>
              </div>
            </div>
          </div>
        ) : locationLoader ? (
          <div id="preloader" style={{ opacity: 0.7 }}>
            <div
              id="status"
              className="top-50"
              style={{ width: "100px", height: "100px" }}
            >
              <img src={Earth} alt="spinner" className="w-100 h-100" />{" "}
            </div>
          </div>
        ) : null}

        <div className={classes.dash_right}>
          <div className={classes.map}>
            <StreetIQMap
              maping={google}
              selectedLocation={this.selectedLocation}
              list_of_places={places.list_of_places}
            />
          </div>

          {loaded && (
            <React.Fragment>
              <div
                className={`${classes.dashboard_right}  ${classes2.dash_856px}`}
              >
                {this.state.google.errorZipCode ? (
                  <Alert color="danger">
                    {google.errorMessage}
                    <div className="d-flex flex-wrap">
                      {google.hint &&
                        google.hint.map((el, i) => (
                          <a
                            className="mr-3"
                            onClick={() => {
                              google.location = el;
                              google.errorZipCode = false;
                              google.coordinates = [];
                              statistic.display = false;

                              this.setState({ ...this.state, google });
                            }}
                            href="#"
                            key={i}
                          >
                            {el}
                          </a>
                        ))}
                    </div>
                    <Button
                      color="link"
                      onClick={() => {
                        google.errorZipCode = false;
                        this.setState({
                          ...this.state,
                          google,
                        });
                      }}
                    >
                      Close
                    </Button>
                  </Alert>
                ) : null}
                {alert_status && (
                  <Alert className="d-flex align-items-center justify-content-between">
                    {!permission
                      ? "Please activate geolocation in order to fetch data"
                      : "We are not providing data in your region"}

                    <Button
                      color="link"
                      onClick={() =>
                        this.setState({
                          ...this.state,
                          google: { ...this.state.google, alert_status: false },
                        })
                      }
                    >
                      Close
                    </Button>
                  </Alert>
                )}
                {!locationModal ? (
                  <div
                    className={`${classes.show_place} d-flex`}
                    style={{ width: "fit-content", padding: "8px 10px" }}
                    onClick={() => {
                      this.setState((state) => ({
                        ...state,
                        locationModal: true,
                      }));
                    }}
                  >
                    <svg
                      viewBox="0 0 24 24"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="#000000"
                      width="30"
                      height="30"
                    >
                      <g strokeWidth="0"></g>
                      <g strokeLinecap="round" strokeLinejoin="round"></g>
                      <g>
                        <g
                          stroke="none"
                          strokeWidth="1"
                          fill="none"
                          fillRule="evenodd"
                        >
                          {" "}
                          <g
                            transform="translate(-720.000000, -50.000000)"
                            fillRule="nonzero"
                          >
                            {" "}
                            <g transform="translate(720.000000, 50.000000)">
                              {" "}
                              <path
                                d="M24,0 L24,24 L0,24 L0,0 L24,0 Z M12.5934901,23.257841 L12.5819402,23.2595131 L12.5108777,23.2950439 L12.4918791,23.2987469 L12.4918791,23.2987469 L12.4767152,23.2950439 L12.4056548,23.2595131 C12.3958229,23.2563662 12.3870493,23.2590235 12.3821421,23.2649074 L12.3780323,23.275831 L12.360941,23.7031097 L12.3658947,23.7234994 L12.3769048,23.7357139 L12.4804777,23.8096931 L12.4953491,23.8136134 L12.4953491,23.8136134 L12.5071152,23.8096931 L12.6106902,23.7357139 L12.6232938,23.7196733 L12.6232938,23.7196733 L12.6266527,23.7031097 L12.609561,23.275831 C12.6075724,23.2657013 12.6010112,23.2592993 12.5934901,23.257841 L12.5934901,23.257841 Z M12.8583906,23.1452862 L12.8445485,23.1473072 L12.6598443,23.2396597 L12.6498822,23.2499052 L12.6498822,23.2499052 L12.6471943,23.2611114 L12.6650943,23.6906389 L12.6699349,23.7034178 L12.6699349,23.7034178 L12.678386,23.7104931 L12.8793402,23.8032389 C12.8914285,23.8068999 12.9022333,23.8029875 12.9078286,23.7952264 L12.9118235,23.7811639 L12.8776777,23.1665331 C12.8752882,23.1545897 12.8674102,23.1470016 12.8583906,23.1452862 L12.8583906,23.1452862 Z M12.1430473,23.1473072 C12.1332178,23.1423925 12.1221763,23.1452606 12.1156365,23.1525954 L12.1099173,23.1665331 L12.0757714,23.7811639 C12.0751323,23.7926639 12.0828099,23.8018602 12.0926481,23.8045676 L12.108256,23.8032389 L12.3092106,23.7104931 L12.3186497,23.7024347 L12.3186497,23.7024347 L12.3225043,23.6906389 L12.340401,23.2611114 L12.337245,23.2485176 L12.337245,23.2485176 L12.3277531,23.2396597 L12.1430473,23.1473072 Z"
                                fillRule="nonzero"
                              >
                                {" "}
                              </path>{" "}
                              <path
                                d="M8.11081,7.06056 C7.52502,6.47477 7.52502,5.52502 8.11081,4.93924 L10.8685,2.18152 C11.4934,1.55668 12.5064,1.55668 13.1313,2.18152 L15.889,4.93924 C16.4748,5.52502 16.4748,6.47477 15.889,7.06056 C15.3032,7.64634 14.3534,7.64634 13.7677,7.06056 L13.4999,6.79279 L13.4999,10.4999 L17.2069,10.4999 L16.9392,10.2322 C16.3535,9.6464 16.3535,8.69665 16.9392,8.11087 C17.525,7.52508 18.4748,7.52508 19.0606,8.11087 L21.8183,10.8686 C22.4431,11.4934 22.4431,12.5065 21.8183,13.1313 L19.0606,15.889 C18.4748,16.4748 17.525,16.4748 16.9392,15.889 C16.3534,15.3033 16.3534,14.3535 16.9392,13.7677 L17.2071,13.4999 L13.4999,13.4999 L13.4999,17.207 L13.7677,16.9392 C14.3534,16.3534 15.3032,16.3535 15.889,16.9392 C16.4748,17.525 16.4748,18.4748 15.889,19.0606 L13.1313,21.8183 C12.5064,22.4431 11.4934,22.4431 10.8685,21.8183 L8.11081,19.0606 C7.52502,18.4748 7.52502,17.525 8.11081,16.9392 C8.69659,16.3535 9.64634,16.3535 10.2321,16.9392 L10.4999,17.207 L10.4999,13.4999 L6.79273,13.4999 L7.06056,13.7677 C7.64634,14.3535 7.64634,15.3033 7.06056,15.889 C6.47477,16.4748 5.52502,16.4748 4.93924,15.889 L2.18152,13.1313 C1.55668,12.5065 1.55668,11.4934 2.18152,10.8686 L4.93924,8.11087 C5.52502,7.52508 6.47477,7.52508 7.06056,8.11087 C7.64634,8.69665 7.64634,9.6464 7.06056,10.2322 L6.79285,10.4999 L10.4999,10.4999 L10.4999,6.79279 L10.2321,7.06056 C9.64634,7.64634 8.69659,7.64634 8.11081,7.06056 Z"
                                fill="#3F2B89"
                              >
                                {" "}
                              </path>{" "}
                            </g>{" "}
                          </g>{" "}
                        </g>{" "}
                      </g>
                    </svg>
                    <p className={classes.choosen_show_text}>
                      Change Location
                    </p>
                  </div>
                ) : null}
                {!errorZipCode && permission && (
                  <div
                    id="scroll"
                    className={`${classes.choosen_place} 
                    ${
                      modal
                        ? classes.choose_modal_section
                        : classes.choose_search_section
                    }

                    ${filterMethod.display ? classes.overflow_visible : null}
                    `}
                  >
                    {!this.state.filterMethod.display && (
                      <div className={classes2.stc_place_content}>
                        {!modal ? (
                          <>
                            {" "}
                            <div className={classes2.stc_place_head}>
                              <h5 className={classes2.stc_h5}>
                                Statistics of the Choosen Location:{" "}
                                {this.state.statistic.district}
                              </h5>
                              <a
                                onClick={this.switchToDifferentQueryMode}
                                className={classes2.stc_p}
                              >
                                Search using statistics
                                <img src={ArrowRight} alt="" />
                              </a>
                            </div>
                            <form onSubmit={this.submitLocationToZoomIn}>
                              <div className={classes2.stc_search_block}>
                                <span>
                                  <input
                                    type="text"
                                    className={classes2.stc_search_input}
                                    name="stc_search_input"
                                    id="stc_search_input"
                                    placeholder={
                                      !address.includes("US")
                                        ? "Enter district name"
                                        : "Enter zipcode"
                                    }
                                    value={`${
                                      google.location && google.location
                                    }`}
                                    onChange={this.onChangeLocationToZoomIn}
                                  />
                                  <button
                                    type="submit"
                                    className={classes2.search_normal}
                                  >
                                    <img src={SearchNormal} alt="" />
                                  </button>
                                  <button
                                    type="button"
                                    className={classes2.stc_srch_close}
                                    onClick={() => {
                                      const google = this.state.google;
                                      google.location = "";
                                      this.setState({ ...this.state, google });
                                    }}
                                  >
                                    <img src={Close} alt="" />
                                  </button>
                                </span>
                                <span>
                                  <input
                                    type="text"
                                    className={`${classes2.stc_search_input} ${classes2.stc_search_place}`}
                                    name="stc_search_input"
                                    id="stc_search_input"
                                    onChange={this.onPlaceSelected}
                                    placeholder="Type place"
                                    value={places.place && places.place}
                                  />
                                  <button
                                    type="button"
                                    className={classes2.stc_srch_close_2}
                                    onClick={() => {
                                      const places = this.state.places;
                                      places.place = "";
                                      this.setState({ ...this.state, places });
                                    }}
                                  >
                                    <img src={Close} alt="" />
                                  </button>
                                </span>
                              </div>
                            </form>
                          </>
                        ) : (
                          <>
                            <div className={classes2.streetIQ}>
                              <ul className={classes2.streetIQ_menu}>
                                <li
                                  className={`${
                                    statistic.activeTab === "1" &&
                                    classes2.active
                                  }`}
                                >
                                  <a
                                    href="#"
                                    className={classes2.streetIQ_nav_a}
                                    onClick={() => {
                                      this.setState({
                                        ...this.state,
                                        statistic: {
                                          ...statistic,
                                          activeTab: "1",
                                        },
                                      });
                                    }}
                                  >
                                    Statistics
                                  </a>
                                </li>
                                <li
                                  className={`${
                                    this.state.statistic.activeTab === "2" &&
                                    classes2.active
                                  }`}
                                >
                                  <a
                                    href="#"
                                    className={classes2.streetIQ_nav_a}
                                    onClick={() => {
                                      this.setState({
                                        ...this.state,
                                        statistic: {
                                          ...statistic,
                                          activeTab: "2",
                                        },
                                      });
                                    }}
                                  >
                                    Charts
                                  </a>
                                </li>
                              </ul>
                            </div>

                            <>
                              {activeTab === "1" && modal && (
                                <Statistic
                                  // selectedDataSegment={this.selectedDataSegment}
                                  expandStatisticModalWithScrool={
                                    this.showMoreInDatabox
                                  }
                                  modal={true}
                                  areaStatistic={statistic.areaStatistic}
                                  //Places={this.state.filterMethod.Places}
                                  Country={google.country}
                                />
                              )}

                              {activeTab === "2" && modal && (
                                <Charts
                                  //  segments={this.state.segments}
                                  selectedDataSegment={this.selectedDataSegment}
                                  expandStatisticModalWithScrool={
                                    this.showMoreInDatabox
                                  }
                                  areaStatistic={
                                    this.state.statistic.areaStatistic
                                  }
                                  //Places={this.state.filterMethod.Places}
                                  country={country}
                                  modal={true}
                                />
                              )}
                            </>
                          </>
                        )}

                        {!this.state.statistic.display && !modal && (
                          <div className={classes2.stc_zip_code}>
                            <img src={MapMulti} alt="" />
                            <p className={classes2.ztc_zip_p}>
                              {`Enter  ${
                                !address.includes("US")
                                  ? "District Name"
                                  : "Zip Code"
                              } or search using Statistics`}
                            </p>
                          </div>
                        )}
                        {statistic.display && !modal && hasStatistic && (
                          <Statistic
                            //segments={this.state.segments}
                            //selectedDataSegment={this.selectedDataSegment}
                            expandStatisticModalWithScrool={
                              this.showMoreInDatabox
                            }
                            areaStatistic={statistic.areaStatistic}
                            Country={google.country}
                          />
                        )}
                        {statistic.display && !modal && !hasStatistic && (
                          <h1 className={classes2.no_data}>
                            We do not yet have data for this Zip Code{" "}
                          </h1>
                        )}
                      </div>
                    )}
                    {this.state.filterMethod.display && (
                      <div className={classes2.stc_plc_zipcode}>
                        <div className={classes2.stc_place_head}>
                          <h5 className={classes2.stc_h5}>
                            Statistics of the choosen Place:
                          </h5>
                          <a
                            onClick={this.switchToDifferentQueryMode}
                            className={classes2.stc_p}
                          >
                            {`Search using ${
                              address !== "US" ? "district name" : "zipcode"
                            }`}
                            <img src={ArrowRight} alt="" />
                          </a>
                        </div>
                        <div className={classes2.stc_place_drops}>
                          <Filters
                            filterMethod={filterMethod}
                            filters={filters}
                            searchLocationsByFilterData={
                              this.searchLocationsByFilterData
                            }
                            selectFilterData={this.selectFilterData}
                            resetFilters={this.resetFilters}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </React.Fragment>
          )}
        </div>
        <LocationModal
          modalStatus={locationModal}
          country={country}
          city={city}
          closeModal={(state, country, city) => {
          
            if (!state || country === undefined || city === undefined) {
              this.setState({
                ...this.state,
                locationModal: false,
              });
              return;
            }
            this.changeToDifferentCity(country, city);
            axios
              .get(
                `https://backendapp.murmurcars.com/api/v1/zipcode/get-filters/${country}`
              )
              .then((response) => {
                const { data } = response;

                const filterMethod = {};

                for (let key of data) {
                  filterMethod[key.filter] = "";
                }

                this.setState((state) => ({
                  ...state,
                  filters: data,
                  filterMethod: {
                    display: false,
                    filtering: false,
                    ...filterMethod,
                  },
                }));
              })
              .catch((err) => alert("network error"));
          }}
        />
      </React.Fragment>
    );
  }
}

export default StreetIQMain;
