import React from "react";

import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Row,
  Col,
} from "reactstrap";

import classes from "../../assets/css/personaAI/subscribe-modal.module.scss";
import "../../assets/css/personaAI/modal.scss";

import axios from "axios";

class CanselSubscription extends React.Component {
  canselSubscription = () => {
    const { subscription, user_id } = this.props;

    axios
      .post(
        `http://localhost:4000/api/v1/ai/customer/${user_id}/cansel-subscription`,
        {
          subscriptionId: subscription.id,
        }
      )
      .then((response) => {
        this.props.toggle(response.data.message);
      })
      .catch((error) => alert('netwrok issue'))
  };

  render() {
    const { show, toggle, cansel_reason, canselReasonInput } = this.props;

    const disabled = cansel_reason == null || cansel_reason.length < 32;

    return (
      <Modal
        isOpen={show}
        toggle={toggle}
        className={`${classes.modal} personaAI`}
      >
        <ModalHeader>
          <div className={classes.modal_header}>
            <span>Cansel membership</span>{" "}
          </div>
        </ModalHeader>
        <ModalBody>
          <div className={classes.cansel_reason}>
            <label htmlFor="cansel-reason">
              Why you want to cancel membership? Help us to improve
            </label>
            <textarea
              id="cansel-reason"
              placeholder="Short text describing the reason"
              onChange={canselReasonInput}
            />
            <p>You can always come back and subscribe again.</p>
          </div>
        </ModalBody>
        <ModalFooter>
          <Row className="w-100 d-flex justify-content-between">
            <div className={classes.btns}>
              <button type="button" onClick={toggle}>
                Cancel
              </button>

              <button
                type="button"
                className={`${classes.cansel_membership_btn} ${
                  disabled ? null : classes.cansel_active
                }`}
                disabled={disabled}
                onClick={this.canselSubscription}
              >
                Cansel membership
                <svg
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.66699 9.99984H16.3337M16.3337 9.99984L10.5003 4.1665M16.3337 9.99984L10.5003 15.8332"
                    stroke="white"
                    strokeWidth="1.67"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </div>
          </Row>
        </ModalFooter>
      </Modal>
    );
  }
}

export default CanselSubscription;
