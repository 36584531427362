import React from "react";

import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Row,
  Col,
} from "reactstrap";

import classes from "../../assets/css/personaAI/subscribe-modal.module.scss";
import "../../assets/css/personaAI/modal.scss";

import { CheckoutForm } from "./checkout-form";
import { loadStripe } from "@stripe/stripe-js";

import { Elements } from "@stripe/react-stripe-js";

import axios from "axios";

const stripe = loadStripe(process.env.REACT_APP_TEST_STRIPEKEY);

class SubscribeModal extends React.Component {

  changeSubscription = (subscriptionId, priceId, userId, Package) => {
    axios
      .post(
        `http://localhost:4000/api/v1/ai/customer/${userId}/change-subscription`,
        {
          subscriptionId,
          priceId,
        }
      )
      .then((response) => {
        const { tokens, subscription } = response.data;

        this.props.membershipChanged(
          tokens,
          subscription,
          `Subscription was switched  successfully to ${Package}`,
          Package
        );
      })
      .catch((error) => alert('netwrok issue'))
  };

  render() {
    const {
      show,
      toggle,
      stripe_subscription,
      profile,
      subscription,
      chooseSubscriptionPackage,
      subscribedSuccessfully,
      canselSubscription,
    } = this.props;

    const disabled =
      subscription.packgage !== "pro" && subscription.packgage !== "ultra";

    return (
      <Modal
        isOpen={show}
        toggle={toggle}
        className={`${classes.modal} personaAI`}
      >
        <ModalHeader>
          <div className={classes.modal_header}>
            <span>Subscribe</span>{" "}
          </div>
        </ModalHeader>
        <ModalBody>
          {stripe_subscription ? (
            <Elements stripe={stripe}>
              <CheckoutForm
                user_id={profile?.id}
                name={profile?.fullName}
                email={profile?.email}
                priceId={profile?.priceId}
                subscription={subscription.packgage}
                subscribedSuccessfully={subscribedSuccessfully}
              />
            </Elements>
          ) : (
            <>
              {" "}
              <p className={classes.low_credit_alert}>
                You have {profile.tokens} tokens left. You can enroll to another
                package to continue
              </p>
              <Row className="d-flex justify-content-between flex-nowrap">
                <Col
                  className={` ${classes.subscription} ${
                    subscription.packgage === "pro" ? classes.active : null
                  }`}
                  onClick={() =>
                    chooseSubscriptionPackage(
                      "pro",
                      "price_1MMDbeIG4ifzlvJuoVvQppBa",
                      10000
                    )
                  }
                >
                  <h2>Pro Membership</h2>
                  <dl>
                    <dt>cost</dt>
                    <dd>$5</dd>
                  </dl>
                  <p>You will get 10000 tokens</p>
                  {profile.plan != null ? (
                    <button
                      className={`${
                        profile.plan === "price_1MMDbeIG4ifzlvJuoVvQppBa"
                          ? classes.cansel_membership
                          : classes.upgrade_membership
                      }`}
                      onClick={() => {
                        if (profile.plan === "price_1MMDbeIG4ifzlvJuoVvQppBa") {
                          canselSubscription();
                          return;
                        }
                        this.changeSubscription(
                          subscription.id,
                          "price_1MMDbeIG4ifzlvJuoVvQppBa",
                          profile.id,
                          "pro"
                        ); //changing subscription requires its price id
                      }}
                    >
                      {profile.plan === "price_1MMDbeIG4ifzlvJuoVvQppBa"
                        ? "Cansel membership"
                        : "Subscribe"}
                    </button>
                  ) : null}
                </Col>
                <Col
                  className={` ${classes.subscription} ${
                    subscription.packgage === "ultra" ? classes.active : null
                  }`}
                  onClick={() =>
                    chooseSubscriptionPackage(
                      "ultra",
                      "price_1MRdVwIG4ifzlvJu6NPSr6PX",
                      50000
                    )
                  }
                >
                  <h2>Ultra Membership</h2>
                  <dl>
                    <dt>cost</dt>
                    <dd>$15</dd>
                  </dl>
                  <p>You will get 50000 tokens</p>
                  {profile.plan != null ? (
                    <button
                      className={`${
                        profile.plan === "price_1MRdVwIG4ifzlvJu6NPSr6PX"
                          ? classes.cansel_membership
                          : classes.upgrade_membership
                      }`}
                      onClick={() => {
                        if (profile.plan === "price_1MRdVwIG4ifzlvJu6NPSr6PX") {
                          canselSubscription(); //means cansel because u currentely subscribed to this package
                          return;
                        }
                        this.changeSubscription(
                          subscription.id,
                          "price_1MRdVwIG4ifzlvJu6NPSr6PX",
                          profile.id,
                          "ultra"
                        ); //changing subscription requires its price id
                      }}
                    >
                      {" "}
                      {profile.plan === "price_1MRdVwIG4ifzlvJu6NPSr6PX"
                        ? "Cansel membership"
                        : "Upgrade membership"}
                    </button>
                  ) : null}
                </Col>
              </Row>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Row className="w-100 d-flex justify-content-between">
            <div className={classes.btns}>
              <button
                type="button"
                onClick={() => {
                  toggle(false);
                }}
              >
                Cancel
              </button>
              {profile.plan == null && !stripe_subscription ? (
                <button
                  type="button"
                  onClick={() => {
                    toggle(true);
                  }}
                  className={`${disabled ? classes.disabled : classes.active}`}
                  disabled={disabled}
                >
                  Subscribe
                </button>
              ) : null}
            </div>
          </Row>
        </ModalFooter>
      </Modal>
    );
  }
}

export default SubscribeModal;
