import React from "react";

import classes from "../../../assets/css/personaAI/index.module.scss";

import TemplateSamples from "./templates";

class Templates extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      template: "template1",
    };
  }
  render() {
    const { sendPromptToChatGPT } = this.props;
    const { template } = this.state;

    const selectedTemplate = TemplateSamples[template].split("PLACEHOLDER");  // array where each element is string. string inside of  [] is a placeholder for input  
    
    const placeholderResponses = {}  //user inputs we store in simple variable couse we dont need state and we cant use ref in this case 
    return (
      <main className={classes.category}>
        <header className={classes.category_hero}>
          <h2>Template</h2>
          <p>Choose template, fill inputs and click the button</p>
        </header>
        <section>
          <form
            className={classes.category_form}
            onSubmit={(e) => {
              e.preventDefault();
              const indexes = Object.keys(placeholderResponses)
              //first we split the template into an array of strings. [] is a placeholder and we store its index as a key in placeholderResponses 
                //and when submiting we replace placeholders with real values placeholderResponses[index] and join to string
              for(let index of indexes){
                selectedTemplate[+index] = placeholderResponses[index]  //index refers where placeholder located in the array variable 
              }
              const prompt = selectedTemplate.join('')  
          
              sendPromptToChatGPT(
                "Template",//category
                { text: prompt, author: "user" },  //for chatGPT chat page ui
                prompt //for chatGPT
              );
            }}
          >
            <div>
              <select className={classes.width_lg} onChange={e => {
                const template = e.target.value 
                this.setState({template})
              }}>
                <option value="template1">Template 1</option>
                <option value="template2">Template 2</option>
                <option value="template3">Template 3</option>
                <option value="template4">Template 4</option>
                <option value="template5">Template 5</option>
                <option value="template6">Template 6</option>
              </select>
            </div>
            <div className={classes.template}>
              {/*As a{" "}
              <input
                className={classes.placeholders}
                placeholder="type your profession here"
                ref={this.profession}
              />
              I want to improve my
              <input
                className={classes.placeholders}
                placeholder="Business type here"
                ref={this.marketing}
              />
          Business*/}

              {selectedTemplate.map((el, i) => {
                if (el.split(/\[(.*?)\]/).at(0) === "") {
                  placeholderResponses[i] = ''   //this is a placeholder key is the index in array and value placeholder attribute
                  return (
                    <input
                      key={i}
                      className={classes.placeholders}
                      placeholder={el.split(/\[(.*?)\]/).at(1)}
                      onChange={(e) => {
                        const value = e.target.value 
                        placeholderResponses[i] = value  //placeholder attribute
                      }}
                      //ref={this.marketing}
                    />
                  );
                } else {
                  return <span key={i}>{el}</span> ;   //text
                }
              })}
            </div>
            <button type="submit">
              Start using{" "}
              <svg
                width="21"
                height="20"
                viewBox="0 0 21 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.8337 1.66666L9.66699 10.8333M18.8337 1.66666L13.0003 18.3333L9.66699 10.8333M18.8337 1.66666L2.16699 7.49999L9.66699 10.8333"
                  stroke="white"
                  strokeWidth="1.67"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </form>
        </section>
      </main>
    );
  }
}

export default Templates;
