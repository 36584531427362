import {
  LOCATECOUNTRYANDCITY,
  TITLEANDCAMPAIGNDESCRIPTION,
  TOGGLEBRANDEDCITY,
  ASSIGNPROFFECIONALS,
  ADDPROFECIONALS,
  HYPETARGETPROPERTIES,
  TOGGLEHYPEPR,
  BANNERANDLINKS,
  UPLOADSOCIALMEDIALINKS,
  BUDGETFORCAMPAIGN,
  CREATENEWHYPECAMPAIGN,
  CREATENEWHYPECAMPAIGNSUCCESS,
  CREATENEWHYPECAMPAIGNFAILED,
  FETCHALLCREATEDHYPECAMPAIGNS,
  FETCHALLCREATEDHYPECAMPAIGNSSUCCESS,
  FETCHALLCREATEDHYPECAMPAIGNSFAILED,
  FETCHCAMPAIGNTOUPDATEDETAILS,
  DELETEBANNER,
  FETCHCAMPAIGNTOUPDATEDETAILSSUCCESS,
  FETCHCAMPAIGNTOUPDATEDETAILSFAILED,
  PUSHUPDATEDDETAILSOFHYPECAMPAIGNTOBACKEND,
  PUSHUPDATEDDETAILSOFHYPECAMPAIGNSUCCESS,
  PUSHUPDATEDDETAILSOFHYPECAMPAIGNFAILED,
} from "./types";

const brandedCity = (state, subscription, sum) => {
  return {
    type: TOGGLEBRANDEDCITY,
    payload: { state, subscription, sum },
  };
};

const locateLocation = (country, city) => {
  return {
    type: LOCATECOUNTRYANDCITY,
    payload: { country, city },
  };
};

const titleCampaighnDescription = ({
  title,
  description,
  country,
  city,
  location,
}) => {
  return {
    type: TITLEANDCAMPAIGNDESCRIPTION,
    payload: {
      title,
      campaign_description: description,
      country,
      city,
      location,
    },
  };
};

const assignProffecionals = (assigned_profecionals) => {
  return {
    type: ASSIGNPROFFECIONALS,
    payload: assigned_profecionals,
  };
};

const addProfecionals = ({
  category,
  state,
  work_description,
  hype_target,
}) => {
  return {
    type: ADDPROFECIONALS,
    payload: { category, state, work_description, hype_target },
  };
};

const addTargetProperties = ({
  target_shares,
  campaign_duration,
  target_followers,
  PR_subscription,
  PR_budget,
  PR_file,
  PR_link,
  PR_doc_name,
}) => {
  return {
    type: HYPETARGETPROPERTIES,
    payload: {
     target_shares,
      campaign_duration,
      target_followers,
      PR_subscription,
      PR_budget,
      PR_file,
      PR_link,
      PR_doc_name,
    },
  };
};

const togglePR = (state) => {
  return {
    type: TOGGLEHYPEPR,
    payload: state,
  };
};
const uploadBannerAndPostLink = ({
  banner_file,
  banner_name,
  banner_link,
  post_link,
}) => {
  return {
    type: BANNERANDLINKS,
    payload: {
      banner_file,
      banner_name,
      banner_link,
      post_link,
    },
  };
};

const uploadSocialMediaLinks = ({ instagramm, facebook, tiktok }) => {
  return {
    type: UPLOADSOCIALMEDIALINKS,
    payload: {
      instagramm,
      facebook,
      tiktok,
    },
  };
};

const deleteBanner = () => {
  return {
    type: DELETEBANNER,
  };
};

const campaignBudget = (budget) => {
  return {
    type: BUDGETFORCAMPAIGN,
    payload: budget,
  };
};

const createNewHypeCampaign = (campaign, history) => {
  return {
    type: CREATENEWHYPECAMPAIGN,
    payload: { campaign, history },
  };
};

const createNewHypeCampaignSuccess = (campaign) => {
  return {
    type: CREATENEWHYPECAMPAIGNSUCCESS,
    payload: campaign,
  };
};
const createNewHypeCampaignFailed = () => {
  return {
    type: CREATENEWHYPECAMPAIGNFAILED,
  };
};

const fetchAllHypeIQCampaigns = (email) => {
  return {
    type: FETCHALLCREATEDHYPECAMPAIGNS,
    payload: email,
  };
};
const fetchAllHypeIQCampaignsSuccess = (campaigns) => {
  return {
    type: FETCHALLCREATEDHYPECAMPAIGNSSUCCESS,
    payload: campaigns,
  };
};
const fetchAllHypeIQCampaignsFailed = (err) => {
  return {
    type: FETCHALLCREATEDHYPECAMPAIGNSFAILED,
    payload: err,
  };
};

const fetchCampaignToUpdateDetails = (id) => {
  return {
    type: FETCHCAMPAIGNTOUPDATEDETAILS,
    payload: id,
  };
};
const fetchHypeIQCampaignSuccess = (campaign) => {
  return {
    type: FETCHCAMPAIGNTOUPDATEDETAILSSUCCESS,
    payload: campaign,
  };
};
const fetchHypeIQCampaignFailed = (err) => {
  return {
    type: FETCHCAMPAIGNTOUPDATEDETAILSFAILED,
    payload: err,
  };
};

const pushUpdatedDetailsOfHypeCampaign = (campaign, id, history) => {
  
  return {
    type: PUSHUPDATEDDETAILSOFHYPECAMPAIGNTOBACKEND,
    payload: { campaign, id, history },
  };
};

const pushUpdatedDetailsOfHypeCampaignSuccess = (campaign) => {
  return {
    type: PUSHUPDATEDDETAILSOFHYPECAMPAIGNSUCCESS,
    payload: campaign,
  };
};

const pushUpdatedDetailsOfHypeCampaignFailed = (err) => {
  return {
    type: PUSHUPDATEDDETAILSOFHYPECAMPAIGNFAILED,
    payload: err,
  };
};

export {
  //create
  brandedCity,
  locateLocation,
  titleCampaighnDescription,
  assignProffecionals,
  addProfecionals,
  addTargetProperties,
  togglePR,
  uploadBannerAndPostLink,
  uploadSocialMediaLinks,
  campaignBudget,
  createNewHypeCampaign,
  createNewHypeCampaignSuccess,
  createNewHypeCampaignFailed,
  deleteBanner,
  //fetch
  fetchAllHypeIQCampaigns,
  fetchAllHypeIQCampaignsSuccess,
  fetchAllHypeIQCampaignsFailed,
  fetchCampaignToUpdateDetails,
  fetchHypeIQCampaignSuccess,
  fetchHypeIQCampaignFailed,
  pushUpdatedDetailsOfHypeCampaign,
  pushUpdatedDetailsOfHypeCampaignSuccess,
  pushUpdatedDetailsOfHypeCampaignFailed,
};
