import React from "react";

import { Link } from "react-router-dom";

import classes from "../../assets/css/personaAI/index.module.scss";

class Categories extends React.Component {

  render() {
    const { openChatGPTChat } = this.props;
    const Categories = [
      {
        header: "Website copy",
        link: "website-copy",
        text: "With able growth issues request. Unpack baked slipstream that performance stands lunch.",
      },
      {
        header: "Paid ad copy",
        link: "ad-copy",
        text: "With able growth issues request. Unpack baked slipstream that performance stands lunch.",
      },
      /*{
        header: "Marketing strategy",
        link: 'marketing-strategy',
        text: "With able growth issues request. Unpack baked slipstream that performance stands lunch.",
      },*/
      {
        header: "Customer persona",
        link: "customer-persona",
        text: "With able growth issues request. Unpack baked slipstream that performance stands lunch.",
      },
      {
        header: "Write a marketing plan",
        link: "marketing-plan",
        text: "With able growth issues request. Unpack baked slipstream that performance stands lunch.",
      },
      {
        header: "Templates",
        link: "templates",
        text: "With able growth issues request. Unpack baked slipstream that performance stands lunch.",
      },
      {
        header: "chatGPT",
        link: null,
        text: "chat with neurol network chatGPT",
      },
    ];

    return (
      <main className={classes.home}>
        <label>Choose one of categories below:</label>
        <section className={classes.categories}>
          {Categories.map((category, index) => {
            if (category.link != null) {
              return (
                <Link key={index} to={`/personaAI/categories/${category.link}`}>
                  <h3>{category.header}</h3>
                  <p>{category.text}</p>
                </Link>
              );
            } else {
              return (
                <button
                  key={index}
                  className={classes.btn_link}
                  onClick={openChatGPTChat}
                >
                  <h3>{category.header}</h3>
                  <p>{category.text}</p>
                </button>
              );
            }
          })}
        </section>
      </main>
    );
  }
}

export default Categories;
