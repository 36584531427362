import React from "react";

import classes from "../../../assets/css/CreateAd/budget-and-schedule/index.module.css";
import classes2 from "../../../assets/css/hypeIQ/index.module.scss";
import "../../../assets/css/common/css/ant-picker.css";
import "../../../assets/css/common/css/info.scss";
import Add from "../../../assets/css/hypeIQ/add.svg";
import info_circle from "../../../assets/css/hypeIQ/info.svg";
import ArrowDown from "../../../assets/css/CreateAd/arrow-down.svg";
import Calendar from "../../../assets/css/CreateAd/calendar.svg";
import People from "../../../assets/css/hypeIQ/people.svg";

import { DatePicker } from "antd";
import { Row, Col } from "reactstrap";

import { withRouter } from "react-router-dom";

import { connect } from "react-redux";
import {
  addTargetProperties,
  addProfecionals,
  togglePR,
  assignProffecionals,
} from "../../../store/actions";

import PRSubscription from "./modals/PR_subscription";
//import PulledPhotographers from "./profecionals/pullPhotographers";
//import PulledInfluencers from "./profecionals/pullInfluencers";
import PRLink from "./modals/PR_link";

import moment from "moment";

import { Tooltip } from "../../../components/tooltip";

class Target extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      //photographers: null,
      //influencers: null,
      PR_subscr_modal: null,
      PR_link_modal: null,
      target_shares: null,
      target_followers: null,
      data: {
        target_shares: null,
        target_followers: null,
        PR: null,
        PR_subscription: null,
        PR_budget: null,
        PR_file: null,
        PR_document: null,
        PR_doc_name: null,
        PR_shorten: null,
        campaign_duration: null,
      },
    };

    this.photographers_work_description = React.createRef(null);
    this.photographers_hype_target = React.createRef(null);
    this.influencers_work_description = React.createRef(null);
    this.influencers_hype_target = React.createRef(null);
    this.async = null;
  }
  componentDidMount() {
    const {
      PR,
      PR_subscription,
      //PR_file,
      PR_doc_name,
      PR_budget,
      PR_link,
      target_shares,
      target_followers,
      campaign_duration,
    } = this.props.data;

    let shorten = PR_doc_name;
    
    if (PR_doc_name != null) {
      const body = PR_doc_name.split(".")[0];
      const head = PR_doc_name.split(".")[1];
      if (body.length > 5) {
        const left = body.slice(0, 3);
        const right = body.slice(body.length - 3, body.length);

        shorten = left + "..." + right + "." + head;
      }
    }

    this.setState((state) => ({
      ...state,
      target_shares:
         target_shares === null ? null : target_shares ? true : false,
      target_followers:
        target_followers === null
          ? null
          : target_followers
          ? true
          : false,
      data: {
        ...state.data,
        PR,
        PR_subscription,
        //PR_file,
        PR_doc_name,
        PR_budget,
        PR_link,
        PR_shorten: shorten,
        target_shares: target_shares,
        target_followers: target_followers,
        campaign_duration,
      },
    }));
  }
  componentWillUnmount() {
    if (this.async == null) {
      return;
    }
    clearTimeout(this.async);
  }

  handleDateChange = (date, dateString) => {
    const duration = dateString[0] + " " + dateString[1];

    this.setState((state) => {
      return {
        ...state,
        data: {
          ...state.data,
          campaign_duration: duration,
        },
      };
    });
  };

  addSpecialists = (e, specialist) => {
    const value = e.target.value;

    if (value == null) {
      return;
    }
    if (value === "no") {
      this.props.addProfecionals({
        category: specialist,
        state: false,
        work_description: null,
        hype_target: null,
      });
    }
    this.setState((state) => {
      return {
        ...state,
        [specialist]: value === "yes" || value === "modify" ? true : false,
      };
    });
  };

  togglePR = (e) => {
    const value = e.target.value;

    if (value == null) {
      return;
    }
    if (value === "no") {
      this.props.togglePR(false);
    }
    this.setState((state) => {
      return {
        ...state,
        PR_subscr_modal: value === "yes" || value === "modify" ? true : false,
        data: {
          ...state.data,
          PR: value === "yes" || value === "modify" ? true : false,
        },
      };
    });
  };

  selectPRPackage = (plan, price) => {
    this.setState((state) => {
      return {
        ...state,
        PR_subscr_modal: false,
        data: {
          ...state.data,
          PR_subscription: plan,
          PR_budget: price,
        },
      };
    });
    this.props.togglePR(true);
    this.async = setTimeout(() => {
      this.setState((state) => {
        return {
          ...state,
          PR_link_modal: true,
        };
      });
    }, 500);
  };
  uploadPRDocument = (info) => {
    const reader = new FileReader();
    reader.readAsDataURL(info.file);

    reader.onload = (e) => {
      const file = info.file;
      const body = file.name.split(".")[0];
      const head = file.name.split(".")[1];
      let shorten = file.name;
      if (body.length > 5) {
        const left = body.slice(0, 3);
        const right = body.slice(body.length - 3, body.length);

        shorten = left + "..." + right + "." + head;
      }
      const document_name = info.file.name;
      const document = e.target.result;

      this.setState((state) => {
        return {
          ...state,
          data: {
            ...state.data,
            PR_document: document,
            PR_file: file,
            PR_doc_name: document_name,
            PR_shorten: shorten,
          },
        };
      });
    };
  };
  deleteUploadedDocument = () => {
    this.setState((state) => {
      return {
        ...state,
        data: {
          ...state.data,
          PR_file: null,
          PR_document: null,
          PR_doc_name: null,
          PR_shorten: null,
        },
      };
    });
  };
  next = (next, form) => {
    if (next) {
      const {
        PR_subscription,
        PR_budget,
        PR_file,
        PR_doc_name,
        PR_document,
        campaign_duration,
        target_shares,
        target_followers,
      } = this.state.data;

      /*const {
        PR_subscription: hype_PR_subscription,
        PR_budget: hype_PR_budget,
        PR_file: hype_PR_file,
        PR_doc_name: hype_PR_doc_name,
        PR_link: hype_PR_link,
        campaign_duration: hype_campaign_duration,
        hype_target_followers,
        hype_target_shares,
      } = this.props.data;*/
      const body = {
        target_shares,
        campaign_duration,
        target_followers,
        PR_subscription,
        PR_budget,
        PR_file,
        PR_doc_name,
        PR_link: PR_document,
      };

      this.props.addTargetProperties(body);
    }
    this.props.nextForm({ form });
  };
  render() {
    const {
      PR_subscr_modal,
      PR_link_modal,
      target_shares,
      target_followers,
      //photographers,
      //influencers,
      data,
    } = this.state;
    const {
      target_shares: target_shares_value,
      target_followers: target_followers_value,
      campaign_duration,
      PR,
      PR_shorten,
    } = data;
    /*const { data } = this.props;
    const {
      PR,
      //photographers: asigned_photographers,
      //influencers: asigned_influencers,
      hype_target_shares,
      hype_target_followers,
      campaign_duration: hype_campaign_duration,
    } = data;*/

    const disabled =
      (target_shares && target_shares_value == null) ||
      (target_followers && target_followers_value == null) ||
      campaign_duration == null ||
      target_shares == null ||
      target_followers == null ||
      PR == null
        ? true
        : false;

    
    return (
      <>
        <Row>
          <Col className={classes2.custom_cols}>
            {target_shares ? (
              <div className={`${classes2.input_field} mb-3`}>
                {" "}
                <label htmlFor="socail">How many social share?*</label>
                <input
                  id="social"
                  placeholder="Type number here"
                  defaultValue={
                    target_shares_value != null ? target_shares_value : ''
                  }
                  value={target_shares_value}
                  onChange={(e) => {
                    const value = e.target.value.trim();
                    if (/\D/i.test(value)) {
                      return;
                    }
                    this.setState((state) => {
                      return {
                        ...state,
                        data: {
                          ...state.data,
                          target_shares: value,
                        },
                      };
                    });
                  }}
                />
                <img src={Add} alt="" />
              </div>
            ) : (
              <div className="mb-3">
                <span className={classes2.label_field}>
                  <label>Do you need shares?*</label>
                </span>
                <span className={classes2.select_field}>
                  <select
                    onChange={(e) => {
                      const value = e.target.value;
                      this.setState((state) => ({
                        ...state,
                        target_shares: value === "yes" ? true : false,
                      }));
                    }}
                    value={
                      target_shares == null //first u seee select
                        ? "null"
                        : target_shares
                        ? "yes" //then depends on redux reducer state
                        : "no"
                    }
                  >
                    <option value="null">Select</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>
                  <img src={ArrowDown} alt="" />
                </span>
              </div>
            )}
            <div className={`${classes2.input_field} mb-3`}>
              <label htmlFor="duration">Campaing Duration*</label>
              <div
                className={`${classes2.step_relative} ${classes.duration_step} hypeiq-ant_picker`}
              >
                <DatePicker.RangePicker
                  className={`${classes.step_element}`}
                  name="duration"
                  id="duration"
                  picker="date"
                  onChange={(date, dateString) =>
                    this.handleDateChange(date, dateString)
                  }
                  defaultrValue={
                    campaign_duration != null
                      ? campaign_duration.split(" ").map((el) => moment(el))
                      : campaign_duration
                  }
                />

                <img src={Calendar} alt="" className={classes2.calendar_icon} />
              </div>
            </div>
            {target_followers ? (
              <div className={`${classes2.input_field} mb-3`}>
                <label htmlFor="followers">
                  How many followers are looking to gain?*
                </label>
                <input
                  id="followers"
                  placeholder="Type number here"
                  defaultValue={
                    target_followers_value != null
                      ? target_followers_value
                      : null
                  }
                  onChange={(e) => {
                    const value = e.target.value.trim();
                    if (/\D/i.test(value)) {
                      return;
                    }
                    this.setState((state) => {
                      return {
                        ...state,
                        data: {
                          ...state.data,
                          target_followers: value,
                        },
                      };
                    });
                  }}
                />
                <img src={People} alt="" />
              </div>
            ) : (
              <div className="mb-3">
                <span className={classes2.label_field}>
                  <label>Do you need followers?*</label>
                </span>
                <span className={classes2.select_field}>
                  <select
                    onChange={(e) => {
                      const value = e.target.value;
                      this.setState((state) => ({
                        ...state,
                        target_followers: value === "yes" ? true : false,
                      }));
                    }}
                    value={
                      target_followers == null //first u seee select
                        ? "null"
                        : target_followers
                        ? "yes" //then depends on redux reducer state
                        : "no"
                    }
                  >
                    <option value="null">Select</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>
                  <img src={ArrowDown} alt="" />
                </span>
              </div>
            )}
          </Col>
          <Col className={classes2.custom_cols}>
            {/* <span>
                  <span className={classes2.label_field}>
                    <label>Do you need influencers?</label>
                    <img src={info_circle} alt="" id="influencers" />

                    <Tooltip target={`influencers`}>
                      <div className="tooltip-cont">
                        Influencers are one of the most important individuals in
                        your advertising. They can sway over your target
                        audience in your niche or industry.By choosing this
                        option, your ad will be matched with the influencers in
                        your niche for starting hyping.
                      </div>
                    </Tooltip>
                  </span>

                  <span className={`${classes2.select_field} mb-3`}>
                    <select
                      onChange={(e) => this.addSpecialists(e, "influencers")}
                      value={
                        asigned_influencers == null
                          ? "null"
                          : asigned_influencers
                          ? "yes"
                          : "no"
                      }
                    >
                      <option value="null">Select</option>
                      <option value="yes">Yes</option>
                      {asigned_influencers ? (
                        <option value="modify">Modify</option>
                      ) : null}
                      <option value="no">No</option>
                    </select>
                    <img src={ArrowDown} alt="" />
                  </span>
                </span>
                <div className="mb-3">
                  <span className={classes2.label_field}>
                    <label>Do you need photographers?</label>
                    <img src={info_circle} alt="" id="photographers" />
                    <div className="info">
                      <Tooltip target={`photographers`}>
                        <div className="tooltip-cont">
                          Brands cannot imagine a perfect ad campaign without
                          the role of a professional photographer. By choosing
                          this option, your ad campaign will be matched with the
                          photographers working with your niche, so that you can
                          choose the one that you will work with.
                        </div>
                      </Tooltip>
                    </div>
                  </span>
                  <span className={classes2.select_field}>
                    <select
                      onChange={(e) => this.addSpecialists(e, "photographers")}
                      value={
                        asigned_photographers == null
                          ? "null"
                          : asigned_photographers
                          ? "yes"
                          : "no"
                      }
                    >
                      <option value="null">Select</option>
                      <option value="yes">Yes</option>
                      {asigned_photographers ? (
                        <option value="modify">Modify</option>
                      ) : null}
                      <option value="no">No</option>
                    </select>
                    <img src={ArrowDown} alt="" />
                  </span>
                      </div>*/}
            <div>
              <span className={classes2.label_field}>
                <label>Do you need PR?</label>
                <img src={info_circle} alt="" id="pr" />
                <div className="info">
                  <Tooltip target={`pr`}>
                    <div className="tooltip-cont">
                      Sending out information about the new releases of the
                      brand is quite a daunting process. By choosing this
                      option, the press releases of your brand will be sent to
                      dozens of news agencies and resources in the country.
                      Build up the visibility of your brand via HypeIQ
                    </div>
                  </Tooltip>
                </div>
              </span>
              <span className={classes2.select_field}>
                <select
                  onChange={this.togglePR}
                  value={
                    PR == null //first u seee select
                      ? "null"
                      : PR
                      ? "yes" //then depends on redux reducer state
                      : "no"
                  }
                >
                  <option value="null">Select</option>
                  <option value="yes">Yes</option>
                  {PR ? <option value="modify">Modify</option> : null}
                  <option value="no">No</option>
                </select>
                <img src={ArrowDown} alt="" />
              </span>
            </div>
          </Col>

          {/* photographers ? (
            <PulledPhotographers
              photographers_work_description={
                this.photographers_work_description
              }
              photographers_hype_target={this.photographers_hype_target}
            />
          ) : (
            <PulledInfluencers
              influencers_work_description={this.influencers_work_description}
              influencers_hype_target={this.influencers_hype_target}
            />
          )*/}
        </Row>

        <div className={classes2.btns}>
          <button
            onClick={() => {
              /*if (photographers || influencers) {
                this.setState((state) => {
                  return { ...state, photographers: false, influencers: false };
                });
                return;
              }*/
              this.next(false, "campaign_title");
            }}
          >
            {/*photographers || influencers ? "Cansel" : "Back"*/}
            Back
          </button>
          <button
            disabled={disabled}
            className={disabled ? "bg-secondary" : null}
            onClick={() => {
              /*if (photographers) {
                this.setState((state) => {
                  return { ...state, photographers: false };
                });
                this.props.addProfecionals({
                  category: "photographers",
                  state: true,
                  work_description:
                    this.photographers_work_description.current != null
                      ? this.photographers_work_description.current.value
                      : null,
                  hype_target:
                    this.photographers_hype_target.current != null
                      ? this.photographers_hype_target.current.value
                      : null,
                });
                return;
              }*/
              this.next(true, "links");
            }}
          >
            {/*photographers || influencers ? "Save" :*/ "Next"}
          </button>
        </div>
        <PRSubscription
          show={PR_subscr_modal}
          selectPackage={this.selectPRPackage}
          toggleModal={(state) => {
            if (state === true) {
              this.props.togglePR(true);
            }
            this.setState((prev) => {
              return {
                ...prev,
                PR_subscr_modal: false,
              };
            });
          }}
        />
        {PR_link_modal ? (
          <PRLink
            show={PR_link_modal}
            uploadPRDocument={this.uploadPRDocument}
            deleteUploadedDocument={this.deleteUploadedDocument}
            PR_doc_name={PR_shorten}
            toggle={() => {
              this.setState((prev) => {
                return {
                  ...prev,
                  PR_link_modal: false,
                };
              });
            }}
          />
        ) : null}
      </>
    );
  }
}

export default connect(null, {
  addTargetProperties,
  addProfecionals,
  togglePR,
  assignProffecionals,
})(withRouter(Target));
