import React from "react";

import classes from "../../assets/css/personaAI/message-box.module.scss";
import Twitter from "../../assets/css/hypeIQ/socials/twitter.svg";
import Facebook from "../../assets/css/Destination/facebook.svg";
import AI from "../../assets/images/AI-avatar.png";

import axios from "axios";

import AyShareModal from "./ayshare";

class MessageBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ayshare_modal: false,
      platforms: [
        {
          item_img: Twitter,
          item_name: "twitter",
          active: false,
          menu: false,
        },
        {
          item_img: Facebook,
          item_name: "facebook",
          active: false,
          menu: false,
        },
      ], //ayshare connected social media platforms
    };
  }

  copyTextToClipboard = (value) => {
    navigator.clipboard.writeText(value);
  };

  shareInSocialMediaChannels = (ayshare) => {
    if (ayshare) {
      axios
        .get(
          `http://localhost:4000/api/v1/sm/customer/${this.props.user_id}/get-platforms`
        )
        .then((response) => {
          const activeSocialAccounts = response.data;

          const platforms = new Array(...this.state.platforms);
          for (let activePlatform of activeSocialAccounts) {
            for (let platform of platforms) {
              if (activePlatform.toLowerCase() === platform.item_name) {
                platform.active = true;
              }
            }
          }
          this.setState((state) => ({
            ...state,
            platforms,
            ayshare_modal: true,
          }));
        })
        .catch((error) => alert('netwrok issue'))
    }else{
      
    }
  };

  /*uploadCreativity = (e) => {
    const files = Array.from(e.target.files) //converting array like collections to native js array
  };*/

  toggleDropdownMenu = (left, platform, right) => {
    this.setState((state) => ({
      ...state,
      platforms: [...left, platform, ...right],
    }));
  };

  togglePlatform = (left, platform, right) => {
    this.setState((state) => ({
      ...state,
      platforms: [...left, platform, ...right],
    }));
  };

  render() {
    const { ayshare_modal, platforms } = this.state;
    const {
      chat,
      alertError,
      savePostAsFavorites,
      deleteFromFavorites,
      successfullyConnected,
    } = this.props;

    //const index = favorites.findIndex((el) => el.text === chat.text);

    const ayshare = JSON.parse(sessionStorage.getItem("ayshare"));

    

    return (
      <div className={classes.message_box}>
        <div className={classes.avatar}>
          <img
            src={
              chat.author === "ai" ? AI : sessionStorage.getItem("profileImage")
            }
            alt=""
          />
        </div>
        <div
          className={`${classes.text} ${
            chat.author !== "ai" ? classes.w_95 : null
          }`}
        >
          {chat.status === "loading" ? (
            <div className={classes.loader}>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          ) : (
            <div>
              <ul style={{ listStyle: "none" }}>
                <li>{chat.text}</li>
              </ul>
            </div>
          )}
        </div>

        {chat.author === "ai" ? (
          <div className={classes.chatbox_actions}>
            <button
              onClick={() => {
                if (chat.favorite) {
                  deleteFromFavorites(chat.id);
                } else {
                  savePostAsFavorites(chat.text);
                }
              }}
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={`${chat.favorite ? classes.favorite : null}`}
              >
                <path
                  d="M15.8337 17.5L10.0003 13.3333L4.16699 17.5V4.16667C4.16699 3.72464 4.34259 3.30072 4.65515 2.98816C4.96771 2.67559 5.39163 2.5 5.83366 2.5H14.167C14.609 2.5 15.0329 2.67559 15.3455 2.98816C15.6581 3.30072 15.8337 3.72464 15.8337 4.16667V17.5Z"
                  stroke="#7F56D9"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
            <button onClick={() => this.copyTextToClipboard(chat.text)}>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_4952_21014)">
                  <path
                    d="M4.16699 12.5001H3.33366C2.89163 12.5001 2.46771 12.3245 2.15515 12.0119C1.84259 11.6994 1.66699 11.2754 1.66699 10.8334V3.33341C1.66699 2.89139 1.84259 2.46746 2.15515 2.1549C2.46771 1.84234 2.89163 1.66675 3.33366 1.66675H10.8337C11.2757 1.66675 11.6996 1.84234 12.0122 2.1549C12.3247 2.46746 12.5003 2.89139 12.5003 3.33341V4.16675M9.16699 7.50008H16.667C17.5875 7.50008 18.3337 8.24627 18.3337 9.16675V16.6667C18.3337 17.5872 17.5875 18.3334 16.667 18.3334H9.16699C8.24652 18.3334 7.50033 17.5872 7.50033 16.6667V9.16675C7.50033 8.24627 8.24652 7.50008 9.16699 7.50008Z"
                    stroke="#7F56D9"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_4952_21014">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </button>
            <button onClick={() => this.shareInSocialMediaChannels(ayshare)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0,0,256,256"
                width="20px"
                height="20px"
                fillRule="nonzero"
              >
                <g
                  fill="#7356c0"
                  fillRule="nonzero"
                  stroke="none"
                  strokeWidth="1"
                  strokeLinecap="butt"
                  strokeLinejoin="miter"
                  strokeMiterlimit="10"
                  strokeDasharray=""
                  strokeDashoffset="0"
                  fontFamily="none"
                  fontWeight="none"
                  fontSize="none"
                  textAnchor="none"
                >
                  <g transform="scale(5.12,5.12)">
                    <path d="M41,2c-3.86,0 -7,3.14 -7,7c0,1.84579 0.72316,3.52153 1.89453,4.77344l-8.36914,10.22656h-11.60547c-0.48787,-3.38658 -3.40037,-6 -6.91992,-6c-3.86,0 -7,3.14 -7,7c0,3.86 3.14,7 7,7c3.51955,0 6.43205,-2.61342 6.91992,-6h11.58203l8.03125,10.63867c-0.95769,1.1976 -1.5332,2.71209 -1.5332,4.36133c0,3.86 3.14,7 7,7c3.86,0 7,-3.14 7,-7c0,-3.86 -3.14,-7 -7,-7c-1.48701,0 -2.8631,0.47004 -3.99805,1.26367l-7.73047,-10.23828l8.18164,-10.00195c1.04184,0.61596 2.25131,0.97656 3.54688,0.97656c3.86,0 7,-3.14 7,-7c0,-3.86 -3.14,-7 -7,-7z"></path>
                  </g>
                </g>
              </svg>
            </button>
          </div>
        ) : null}

        <AyShareModal
          show={ayshare_modal}
          toggle={(state, ayshare) => {
            if (state) {
              axios
                .post(
                  `http://localhost:4000/api/v1/sm/customer/${this.props.user_id}/ayshare-connect`,
                  { ayshare }
                )
                .then((response) => {
                  /*const {data} = response
              if(data.status===201){
                sessionStorage.setItem('ayshare', JSON.stringify(true))
              }*/

                  let ayshare_modal = false;

                  this.setState({ ayshare_modal });

                  sessionStorage.setItem("ayshare", JSON.stringify(true));
                  if (response.data.status === 204) {
                    sessionStorage.removeItem("ayshare");
                    sessionStorage.setItem("ayshare", JSON.stringify(false));
                    alertError(response.data.message);
                  }
                  successfullyConnected();
                })
                .catch((error) => alert('netwrok issue'))
            }
            this.setState({ ayshare_modal: false });
          }}
          shareContentInSelectedPlatforms={(platforms) => {
            axios
              .post(
                `http://localhost:4000/api/v1/sm/customer/${this.props.user_id}/sm-post`,
                {
                  platforms,
                  text: chat.text,
                  mediaUrls: null,
                }
              )
              .then((response) => {
                 
              })
              .catch((error) => alert('netwrok issue'))
          }}
          ayshare={ayshare}
          copyTextToClipboard={this.copyTextToClipboard}
          savePostAsFavorites={savePostAsFavorites}
          deleteFromFavorites={deleteFromFavorites}
          chat={chat}
          uploadCreativity={this.uploadCreativity}
          platforms={platforms}
          toggleDropdownMenu={this.toggleDropdownMenu}
          togglePlatform={this.togglePlatform}
        />
      </div>
    );
  }
}

export default MessageBox;
