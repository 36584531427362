import React from "react";

import classes from "../../../assets/css/personaAI/index.module.scss";
import PersonaAIChat from "../chat-with-personaAI";



class WebsiteCopy extends React.Component {
  constructor(props) {
    super(props);
    this.website = React.createRef();
    this.link = React.createRef();
    this.description = React.createRef();
  }
  render() {
    const {openAI_chat, sendPromptToChatGPT} = this.props;
    return (
      <main className={classes.category}>
        {openAI_chat ? (
          <PersonaAIChat />
        ) : (
          <>
            <header className={classes.category_hero}>
              <h2>Website copy</h2>
              <p>Fill inputs below and click button</p>
            </header>
            <section>
              <form
                className={classes.category_form}
                onSubmit={(e) => {
                  e.preventDefault();
                  const { value: website } = this.website?.current;
                  const { value: link } = this.link?.current;
                  const { value: description } = this.description?.current;
    
                  const prompt = `Create Website copy. website name is ${website}  and ${link} is website link. Do following: ${description}.`; //to chatGpt
                
                  sendPromptToChatGPT('Website copy', {website, link, description, author: 'user'}, prompt)
                
                }}
              >
                <div>
                  <div>
                    <div>
                      <label htmlFor="website-name">Name of website</label>
                      <input placeholder="Type here" id="website-name" ref={this.website}/>
                    </div>
                    <div>
                      <label htmlFor="website-name">Link of website</label>
                      <input placeholder="Type here" id="website-name" ref={this.link}/>
                    </div>
                  </div>
                  <div>
                    <div>
                      <label htmlFor="description2">Description</label>
                      <textarea
                        id="description2"
                        placeholder="Type here"
                        className={classes.height_lg}
                        ref={this.description}
                      />
                    </div>
                  </div>
                </div>
                <button type="submit">
                  Start using{" "}
                  <svg
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18.8337 1.66666L9.66699 10.8333M18.8337 1.66666L13.0003 18.3333L9.66699 10.8333M18.8337 1.66666L2.16699 7.49999L9.66699 10.8333"
                      stroke="white"
                      strokeWidth="1.67"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </form>
            </section>
          </>
        )}
      </main>
    );
  }
}

export default WebsiteCopy
