export default {
  template1:
    "Please write an email marketing campaign using the 'Consistent-Contrasting' framework to convert leads into customers. Use a consistent message or theme throughout the copy, but incorporate contrasting language or images to draw the reader's attention and keep them engaged. Include talking points such as PLACEHOLDER[product/service]PLACEHOLDER, PLACEHOLDER[unique selling point]PLACEHOLDER. PLACEHOLDER[desired action]PLACEHOLDER!",
  template2:
    "Write an email marketing campaign using the 'Strong-Weak' framework to persuade PLACEHOLDER[ideal customer persona]PLACEHOLDER to take action. Use strong language and images to emphasize the benefits of our PLACEHOLDER[product/service]PLACEHOLDER, but also acknowledge any potential weaknesses or limitations in a transparent and honest way. Include talking points such as PLACEHOLDER[unique selling point]PLACEHOLDER, PLACEHOLDER[pain point]PLACEHOLDER. PLACEHOLDER[desired action]PLACEHOLDER!",
  template3:
    "Using the 'Emotion-Logic' framework, please write an email marketing campaign that connects with PLACEHOLDER[ideal customer persona]PLACEHOLDER and creates desire for our PLACEHOLDER[product/service]PLACEHOLDER. Use emotional appeals to connect with the reader, but also use logical arguments to convince them to take action. Include talking points such as PLACEHOLDER[emotion]PLACEHOLDER, PLACEHOLDER[pain point]PLACEHOLDER.  PLACEHOLDER[desired action]PLACEHOLDER!",
  template4:
    "As a PLACEHOLDER[profession]PLACEHOLDER I want to improve PLACEHOLDER[business]PLACEHOLDER Business.",
  template5:
    "Craft an email marketing campaign using the 'Personal-Universal' framework to make our PLACEHOLDER[product/service]PLACEHOLDER relatable to PLACEHOLDER[ideal customer persona]PLACEHOLDER. Use 'you' language and address their specific needs and desires, but also connect our product to universal human experiences and values. Include talking points such as PLACEHOLDER[unique selling point]PLACEHOLDER, PLACEHOLDER[pain point]PLACEHOLDER.  PLACEHOLDER[desired action]PLACEHOLDER!",
  template6:
    "Write an email marketing campaign using the 'Urgency-Patience' framework to encourage PLACEHOLDER[ideal customer persona]PLACEHOLDER to take action. Create a sense of urgency to encourage the reader to act now, but also remind them that using our PLACEHOLDER[product/service]PLACEHOLDER will bring long-term benefits that are worth waiting for. Include talking points such as PLACEHOLDER[unique selling point]PLACEHOLDER, PLACEHOLDER[pain point]PLACEHOLDER. PLACEHOLDER[desired action]PLACEHOLDER!",
};
