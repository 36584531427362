import React from "react";

import { Modal, ModalBody, ModalHeader, Row, Col } from "reactstrap";

import classes from "../../../../assets/css/hypeIQ/modal.module.scss";
import CloseBtn from "../../../../assets/css/common/icons/close.svg";
import Insta from "../../../../assets/css/hypeIQ/socials/instagram.svg";
import Facebook from "../../../../assets/css/hypeIQ/socials/Facebook.svg";
import Tiktok from "../../../../assets/css/hypeIQ/socials/tiktok.svg";

import { connect } from "react-redux";

class SocialMedia extends React.Component {
  render() {
    const { show, toggle } = this.props;
    return (
      <Modal
        isOpen={show}
        toggle={toggle}
        className={`${classes.modal} ${classes.social}`}
      >
        <ModalHeader>
          <div className={classes.modal_header}>
            <span>Connect Social links</span>{" "}
            <button onClick={() => toggle(false)}>
              <img src={CloseBtn} alt="" />
            </button>
          </div>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col className="d-flex flex-column align-items-center">
              <div className={classes.media_links}>
                <span>
                  <input
                    placeholder="Paste Instagram link here"
                    ref={this.props.instagramm}
                    defaultValue={this.props.social_media.instagramm}
                  />
                  <img src={Insta} alt="" />
                </span>
                <span>
                  <input
                    placeholder="Paste Facebook link here"
                    ref={this.props.facebook}
                    defaultValue={this.props.social_media.facebook}
                  />
                  <img src={Facebook} alt="" />
                </span>
                <span>
                  <input
                    placeholder="Paste Tiktok link here"
                    ref={this.props.tiktok}
                    defaultValue={this.props.social_media.tiktok}
                  />
                  <img src={Tiktok} alt="" />
                </span>
              </div>

              <div className={classes.btns}>
                <button
                  type="button"
                  onClick={() => {
                    toggle(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={() => {
                    toggle(true);
                  }}
                >
                  Save
                </button>
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    );
  }
}
const mapStateToProps = (state) => {
  const { social_media } = state.HypeIQ;
  return { social_media };
};
export default connect(mapStateToProps, null)(SocialMedia);
