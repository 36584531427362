import React, { Component } from "react";
import axios from "axios";
import { analytics } from "firebase";

export default class QRMenuRedirect extends Component {
  state = {
    ad_creativity_url: null,
    ad_width: null,
    ad_height: null,
    timeOut: null,
    analytics: null,
    advertisers_email: null,
    status: false,
    redirection_url: null,
  };

  componentDidMount() {
    axios
      .get("http://localhost:4000/api/v1/qr-menu/get-qr-medu-ads")
      .then((res) => {
        const data = res.data.response[0];
        this.setState({ data, status: true });
        
      })
    .catch((error) => alert('netwrok issue'))
    setTimeout(() => {
      setTimeout(() => {
        window.location.replace(this.state.data.redirection_url);
      }, 1000);
    }, 3000);
  }

  render() {
    return (
      <div>
        <div
          style={{
            alignContent: "center",
            alignItems: "center",
            width: 800,
            alignSelf: "center",
          }}
        >
          {!this.state.status ? null : (
            <img
              src={this.state.data.ad_creativity_url}
              width={this.state.data.ad_width}
              height={this.state.data.ad_height}
            ></img>
          )}
        </div>
      </div>
    );
  }
}
