
import {
  ADDREMOVERESTAURANTS,
  SELECTLOCTION
} from './actionTypes'

export const addRemoveRestaurants = (restaurant, checked) => {  //if checked false it will remove
  
  return {
    type: ADDREMOVERESTAURANTS,
    payload: {restaurant, checked},
  };
};


export const selectLocation = (country, city) => {
  return {
    type: SELECTLOCTION,
    payload: {country, city},
  };
};
