import React from "react";

import classes from "../../../assets/css/CreateAd/audience/index.module.css";
import classes2 from "../../../assets/css/CreateAd/navbar.module.css";
import custom from "../../../assets/css/custom/radio.module.scss";
import LogoCreate from "../../../assets/css/CreateAd/logo-create.png";
import Copyright from "../../../assets/css/CreateAd/copyright.svg";
import Check from "../../../assets/css/CreateAd/check.svg";
import InfoCircle from "../../../assets/css/CreateAd/info-circle.svg";
import ArrowDown from "../../../assets/css/CreateAd/arrow-down.svg";
import ArrowLeft from "../../../assets/css/CreateAd/ads-details/arrow-left.svg";
import LocationError from "../../../assets/css/CreateAd/location-error.svg";
import LocationImg from "../../../assets/css/CreateAd/location.svg";

import GoogleMap from "../map";

import { toggleSideBar } from "../../../store/actions";
import { connect } from "react-redux";

import { Link, withRouter } from "react-router-dom";

import axios from "axios";

import Geocode from "react-geocode";

const GOOGLE_MAP_KEY = process.env.REACT_APP_GOOGLEMAPKEY;
Geocode.setApiKey(GOOGLE_MAP_KEY);

class Location extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      location: {
        center: this.props.center,
        zoom: 10,
        postCenter: this.props.center,
        coordinates: [...this.props.coordinates], //polygons
        markers: [...this.props.markers],
      },
      audience: {
        country: this.props.country,
        city: this.props.city,
        locations: [...this.props.locations],
      },
      Cities: {
        Azerbaijan: ["Baku"],
        Georgia: ["Tbilisi"],
        "United States": ["SF", "Chicago"],
        Turkiye: ["Stanbul"],
      },
      error: {
        country: false,
        locations: false,
      },
      multiple: false,
    };
    this.submit = this.submit.bind(this);
    this.canselNewCampaign = this.canselNewCampaign.bind(this);
  }
  submit(event) {
    event.preventDefault();
    let hasNoError = true;
    const { pickLocations, fromPlaceIQ } = this.props;
    const { error, audience, location } = this.state;
    const { country, city, locations } = audience;
    const { coordinates, markers, center } = location;
    const states = Object.keys(audience);

    for (let i = 0; i < states.length; i++) {
      if (
        (this.state.audience[states[i]] === null ||
          !this.state.audience[states[i]].length) &&
        (!markers.length || !coordinates.length)
      ) {
        error[states[i]] = true;
        this.setState({ audience, error });
        hasNoError = false;
      }
    }
    if (hasNoError || fromPlaceIQ) {
      pickLocations({ country, city, locations, coordinates, markers, center });
    }
  }

  getLocationApiCall = (locations, country) => {
    axios
      .post(
        "https://backendapp.murmurcars.com/api/v1/zipcode/get-multiple-polygon-coords",
        {
          districts: locations,
          country: country,
        }
      )
      .then((resp) => {
        const { polygons, center } = resp.data;
      })
      .catch((err) => {});
  };
  handleChange = (event) => {
    const value = event.target.value;
    const { audience, multiple } = this.state;
    const { locations, country, city } = audience;

    const Locations = [value];
    if (multiple) {
      /*for (let location of locations) {
     //   if (location === value) {
         // const select = document.getElementById("step-aud-location");
          //select.value = "picked-locations";
          return;
        }
      }*/

      Locations.unshift(...locations);
    }
    if (city === "sf") {
      this.addLocationMarkers(country, city, value, multiple);
      return;
    }
    axios
      .post(
        "https://backendapp.murmurcars.com/api/v1/zipcode/get-multiple-polygon-coords",
        {
          districts: Locations,
          country,
        }
      )
      .then((resp) => {
        const { polygons, center } = resp.data;

        this.setState({
          ...this.state,
          audience: {
            ...this.state.audience,
            locations: Locations,
          },
          location: {
            ...this.state.location,
            coordinates: polygons,
            center: center,
            postCenter: center,
          },
        });
        //const select = document.getElementById("step-aud-location");
        //select.value = "picked-locations";
      })
      .catch((err) => {
        console.log("something went wrong");
      });
  };
  addLocationMarkers = (country, city, location, multiple) => {
    this.markersGPS(country, `${city}, ${location}`, multiple);
  };
  /*handleUSZipsChange = (e) => {
    e.persist();
    const value = e.target.value;
    const { audience, multiple } = this.state;
    const { locations, country, city } = audience;
    if (value.length === 5) {
      const Locations = [value];

      if (multiple) {
        for (let location of locations) {
          if (location === value) {
            return;
          }
        }
        Locations.unshift(...locations);
      }

      alert(city)
      if (city === "sf") {
        this.addLocationMarkers(country, city, Locations);
        return;
      }
      axios
        .post(
          "https://backendapp.murmurcars.com/api/v1/zipcode/get-multiple-polygon-coords",
          {
            districts: Locations,
            country: country,
          }
        )
        .then((resp) => {
          const { polygons, center } = resp.data;

          this.setState({
            ...this.state,
            audience: {
              ...this.state.audience,
              locations: Locations,
            },
            location: {
              ...this.state.location,
              coordinates: polygons,
              center: center,
              postCenter: center,
            },
          });

          e.target.value = "";
        })
        .catch((err) => {
          
        });
    }
  };*/

  handleCountryChange = (event) => {
    const country = event.target.value.toLowerCase();

    const city =
      country === "azerbaijan"
        ? "baku"
        : country === "georgia"
        ? "tbilisi"
        : "chicago";

    this.setState({
      ...this.state,
      audience: {
        ...this.state.audience,
        country,
        city,
        locations: [],
      },
    });

    this.fromLocationFindGPS(country, city);

    /*if (country === "azerbaijan" || country === "georgia") {
      //const select = document.getElementById("step-aud-location");
      //select.value = "picked-locations";
    }*/
  };

  canselNewCampaign() {
    this.props.toggleSideBar(true);
    this.props.history.replace("/ad-manager");
    this.props.history.go("/ad-manager");
  }

  deletePolygonFromStack = (index) => {
    const { location, audience } = this.state;
    const { coordinates: coordinatesState } = location;
    const { locations: locationsState } = audience;

    const leftCoordinates = coordinatesState.slice(0, index);
    const rightCoordinates = coordinatesState.slice(
      index + 1,
      coordinatesState.length
    );
    const coordinates = [...leftCoordinates, ...rightCoordinates];

    const leftLocations = locationsState.slice(0, index);
    const rightLocations = locationsState.slice(
      index + 1,
      locationsState.length
    );
    const locations = [...leftLocations, ...rightLocations];

    this.setState({
      ...this.state,
      location: {
        ...this.state.location,
        coordinates,
      },
      audience: {
        ...this.state.audience,
        locations,
      },
    });
  };
  handleGeocode = (city, location, Locations, multiple) => {
    Geocode.fromAddress(city + " " + location)
      .then((response) => {
        const { lat, lng } = response.results[0].geometry.location;

        const center = { lat, lng };

        this.setState({
          ...this.state,
          audience: {
            ...this.state.audience,
            locations: Locations,
          },
          location: {
            ...this.state.location,
            markers: multiple
              ? [...this.state.location.markers, center]
              : [center],
            center,
            postCenter: center,
          },
        });
      })
      .catch((err) => {
        this.setState({
          ...this.state,
        });
      });
  };

  handleReverseGeocode = () => {
    let audience = this.state.audience;
    Geocode.fromLatLng(
      this.state.location.center.lat,
      this.state.location.center.lng
    ).then(
      (response) => {
        const address = response.plus_code.compound_code;
        const country = address
          .split(/\s|[,]/i)
          .filter((el) => el !== "")
          .at(-1)
          .trim();

        let city = address
          .split(/\s|[,]/i)
          .filter((el) => el !== "")
          .at(-2)
          .trim();

        if (country === "USA") {
          city = city === "Illinois" ? "Chicago" : "SF";
        }
        if (
          country !== "USA" &&
          country !== "Azerbaijan" &&
          country !== "Georgia"
        ) {
          return;
        }
        audience.country = country.toLowerCase();
        audience.city = city.toLowerCase();

        this.setState({ ...this.state, audience });

        this.setState((state) => ({
          ...state,
          audience,
          //error: { ...state.error, Country: error },
        }));
      },
      (error) => {
        this.setState({ ...this.state, audience });
      }
    );
  };

  markersGPS = (country, location, multiple) => {
    Geocode.fromAddress(`${country}, ${location}`)
      .then((response) => {
        const { lat, lng } = response.results[0].geometry.location;
        const center = { lat, lng };

        this.setState((state) => ({
          ...state,
          location: {
            ...state.location,
            markers: multiple
              ? [...state.location.markers, { postion: center }]
              : [{ position: center }],
          },
          audience: {
            ...state.audience,
            locations: multiple
              ? [...state.audience.locations, location]
              : [location],
          },
        }));
      })
      .catch((err) => {});
  };

  fromLocationFindGPS = (country, city) => {
    Geocode.fromAddress(`${country}, ${city}`)
      .then((response) => {
        const { lat, lng } = response.results[0].geometry.location;
        const center = { lat, lng };
        this.setState((state) => ({
          ...state,
          audience: {
            ...state.audience,
            city,
            country,
            //locations: [],
          },
          location: {
            ...state.location,
            center,
            coordinates: [],
            markers: [],
            postCenter: center,
          },
        }));
      })
      .catch((err) => {});
  };
  cityChange = (e) => {
    const city = e.target.value;
    const { country } = this.state.audience;

    this.fromLocationFindGPS(country, city);
  };

  componentDidMount() {
    const { country, city, locations } = this.state.audience;
    if (locations.length) {
      return;
    }

    if (country) {
      this.fromLocationFindGPS(country, city);
    }

  }

  render() {
    const { error, multiple, audience, location } = this.state;
    const { country, city, locations } = audience;

    const { markers } = location;

    const { fromPlaceIQ } = this.props;

    return (
      <React.Fragment>
        <header className={classes2.header}>
          <div
            className={`${classes2.mur_contain} ${classes2.create_top_cancel}`}
          >
            <a href="#">
              <img src={LogoCreate} alt="" />
            </a>
            <button
              type="button"
              className={classes2.create_cancel_btn}
              onClick={this.canselNewCampaign}
            >
              Cancel
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M13.4142 11.9998L17.7072 7.70676C18.0982 7.31576 18.0982 6.68376 17.7072 6.29276C17.3162 5.90176 16.6842 5.90176 16.2933 6.29276L12.0002 10.5858L7.70725 6.29276C7.31625 5.90176 6.68425 5.90176 6.29325 6.29276C5.90225 6.68376 5.90225 7.31576 6.29325 7.70676L10.5862 11.9998L6.29325 16.2928C5.90225 16.6838 5.90225 17.3158 6.29325 17.7068C6.48825 17.9018 6.74425 17.9998 7.00025 17.9998C7.25625 17.9998 7.51225 17.9018 7.70725 17.7068L12.0002 13.4138L16.2933 17.7068C16.4882 17.9018 16.7443 17.9998 17.0002 17.9998C17.2562 17.9998 17.5122 17.9018 17.7072 17.7068C18.0982 17.3158 18.0982 16.6838 17.7072 16.2928L13.4142 11.9998Z"
                  fill="#192038"
                />
              </svg>
            </button>
          </div>
        </header>
        <section className={classes2.mur_contain}>
          <div className={classes2.create_steps}>
            <div className={classes2.create_navbar}>
              <ul className={classes2.create_nav_ul}>
                <li
                  className={`${classes2.nav_ul_item} ${classes2.passed_step}`}
                >
                  <a href="#" className={classes2.nav_item_name}>
                    Campaign Objective
                  </a>
                  <div className={classes2.step_number_cover}>
                    <div className={classes2.number_dot}>
                      <span className={classes2.step_number}>1</span>
                      <img
                        src={Check}
                        alt=""
                        className={classes2.passed_check}
                      />
                    </div>
                    <div className={classes2.step_number_active}>
                      <span className={classes2.step_number}>1</span>
                    </div>
                  </div>
                </li>
                <li
                  className={`${classes2.nav_ul_item} ${classes2.passed_step}`}
                >
                  <a href="#" className={classes2.nav_item_name}>
                    Campaign Details
                  </a>
                  <div className={classes2.step_number_cover}>
                    <div className={classes2.number_dot}>
                      <span className={classes2.step_number}>2</span>
                      <img
                        src={Check}
                        alt=""
                        className={classes2.passed_check}
                      />
                    </div>
                    <div className={classes2.step_number_active}>
                      <span className={classes2.step_number}>2</span>
                    </div>
                  </div>
                </li>
                <li
                  className={`${classes2.nav_ul_item}  ${classes2.passed_step}`}
                >
                  <a href="#" className={classes2.nav_item_name}>
                    Audience
                  </a>
                  <div className={classes2.step_number_cover}>
                    <div className={classes2.number_dot}>
                      <span className={classes2.step_number}>3</span>
                      <img
                        src={Check}
                        alt=""
                        className={classes2.passed_check}
                      />
                    </div>
                    <div className={classes2.step_number_active}>
                      <span className={classes2.step_number}>3</span>
                    </div>
                  </div>
                </li>
                <li className={`${classes2.nav_ul_item} ${classes2.active}`}>
                  <a href="#" className={classes2.nav_item_name}>
                    Location
                  </a>
                  <div className={classes2.step_number_cover}>
                    <div className={classes2.number_dot}>
                      <span className={classes2.step_number}>4</span>
                      <img
                        src={Check}
                        alt=""
                        className={classes2.passed_check}
                      />
                    </div>
                    <div className={classes2.step_number_active}>
                      <span className={classes2.step_number}>4</span>
                    </div>
                  </div>
                </li>
                <li className={classes2.nav_ul_item}>
                  <a href="#" className={classes2.nav_item_name}>
                    Budget & Schedule
                  </a>
                  <div className={classes2.step_number_cover}>
                    <div className={classes2.number_dot}>
                      <span className={classes2.step_number}>5</span>
                      <img
                        src={Check}
                        alt=""
                        className={classes2.passed_check}
                      />
                    </div>
                    <div className={classes2.step_number_active}>
                      <span className={classes2.step_number}>5</span>
                    </div>
                  </div>
                </li>
                <li className={classes2.nav_ul_item}>
                  <a href="#" className={classes2.nav_item_name}>
                    Placement Type
                  </a>
                  <div className={classes2.step_number_cover}>
                    <div className={classes2.number_dot}>
                      <span className={classes2.step_number}>6</span>
                      <img
                        src={Check}
                        alt=""
                        className={classes2.passed_check}
                      />
                    </div>
                    <div className={classes2.step_number_active}>
                      <span className={classes2.step_number}>6</span>
                    </div>
                  </div>
                </li>
                <li className={classes2.nav_ul_item}>
                  <a href="#" className={classes2.nav_item_name}>
                    Ad Media
                  </a>
                  <div className={classes2.step_number_cover}>
                    <div className={classes2.number_dot}>
                      <span className={classes2.step_number}>7</span>
                      <img
                        src={Check}
                        alt=""
                        className={classes2.passed_check}
                      />
                    </div>
                    <div className={classes2.step_number_active}>
                      <span className={classes2.step_number}>7</span>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div className={classes.audience_step}>
              <div className={classes.create_step_1}>
                <h4 className={classes.create_step_h4}>Location</h4>

                <div className={classes.step_max_form}>
                  <form onSubmit={this.submit}>
                    <div
                      className="w-100 position-relative"
                      style={{ height: "200px" }}
                    >
                      <GoogleMap
                        state={location}
                        deletePolygonFromStack={this.deletePolygonFromStack}
                      />
                    </div>
                    <div className={classes.locations}>
                      {locations.map((el, i) => (
                        <button
                          key={i}
                          type="button"
                          onClick={() => {
                            const locLeft = locations.slice(0, i);
                            const locRight = locations.slice(
                              i + 1,
                              locations.length
                            );

                            const coordinates = location.coordinates;

                            const coordLeft = coordinates.slice(0, i);
                            const coordRight = coordinates.slice(
                              i + 1,
                              coordinates.length
                            );
                            this.setState((state) => ({
                              ...state,
                              audience: {
                                ...state.audience,
                                locations: coordinates.length
                                  ? [...locLeft, ...locRight]
                                  : [],
                                markers: markers.length
                                  ? [...locLeft, ...locRight]
                                  : [],
                              },
                              location: {
                                ...state.location,
                                coordinates: [...coordLeft, ...coordRight],
                              },
                            }));
                          }}
                        >
                          {el}
                        </button>
                      ))}
                    </div>
                    <div className={classes.step_form_item}>
                      <label
                        htmlFor="step-aud-country"
                        className={classes.step_label}
                      >
                        Country
                      </label>
                      <div className={classes.step_relative}>
                        <select
                          name="step-aud-country"
                          id="step-aud-country"
                          className={classes.step_select_item}
                          onChange={this.handleCountryChange}
                        >
                          <option value="" disabled>
                            Select
                          </option>

                          {country ? (
                            <option value={country.toLowerCase()}>
                              {country}
                            </option>
                          ) : null}
                          {!fromPlaceIQ
                            ? ["USA", "Azerbaijan", "Georgia"]
                                .filter(
                                  (el) =>
                                    el.toLowerCase() !== country.toLowerCase()
                                )
                                .map((el, i) => {
                                  return (
                                    <option key={el} value={el.toLowerCase()}>
                                      {" "}
                                      {el}
                                    </option>
                                  );
                                })
                            : null}
                        </select>
                        <img
                          src={error.locations ? LocationError : ArrowDown}
                          alt=""
                          className={`${classes.step_form_icon_selector}`}
                        />
                      </div>
                    </div>
                    {country ? (
                      <div className={classes.step_form_item}>
                        <label
                          htmlFor="step-aud-city"
                          className={classes.step_label}
                        >
                          City
                        </label>
                        <div className={classes.step_relative}>
                          <select
                            name="step-aud-city"
                            id="step-aud-city"
                            className={classes.step_select_item}
                            onChange={this.cityChange}
                          >
                            {country.toLowerCase() === "azerbaijan" ? (
                              <option value="baku">Baku</option>
                            ) : country === "georgia" ? (
                              <option value="tbilisi">Tbilisi</option>
                            ) : (
                              <>
                                {" "}
                                <option value="chicago">Chicago</option>
                                <option value="sf">San Francisco</option>
                              </>
                            )}
                          </select>
                          <img
                            src={error.locations ? LocationError : ArrowDown}
                            alt=""
                            className={`${classes.step_form_icon_selector}`}
                          />
                        </div>
                      </div>
                    ) : null}
                    {city && !fromPlaceIQ ? (
                      <div className={`${classes.step_form_item} `}>
                        <span className="d-flex justify-content-between">
                          <label
                            htmlFor="step-aud-location"
                            className={classes.step_label}
                          >
                            Audience Location
                          </label>
                          <span className={custom.custom_btn_lable_cont}>
                            Multiple:{" "}
                            <div
                              className={custom.custom_radio}
                              onClick={() => {
                                this.setState({
                                  ...this.state,
                                  multiple: !this.state.multiple,
                                  location: {
                                    ...this.state.location,
                                    coordinates: [],
                                  },
                                  audience: {
                                    ...this.state.audience,
                                    locations: [],
                                  },
                                });
                              }}
                            >
                              <div
                                className={`${multiple && custom.radio_active}`}
                              />
                            </div>
                          </span>
                        </span>

                        <div className={classes.step_relative}>
                          {country === "azerbaijan" ? (
                            <>
                              <select
                                name="step-aud-location"
                                id="step-aud-location"
                                className={classes.step_select_item}
                                onChange={(e) => this.handleChange(e)}
                              >
                                <option value="">Select</option>
                                <option value="nizami">Nizami</option>
                                <option value="nasimi">Nasimi</option>
                                <option value="khazar">Khazar</option>
                                <option value="sabunchu">Sabunchu</option>
                                <option value="qaradaq">Qaradaq</option>
                                <option value="binaqadi">Binaqadi</option>
                                <option value="narimanov">Narimanov</option>
                                <option value="sabayil">Sabayil</option>
                                <option value="pirallahı">Pirallahı</option>
                                <option value="xətai">Xətai</option>
                                <option value="yasamal">Yasamal</option>
                                <option value="suraxanı">Suraxanı</option>
                              </select>
                            </>
                          ) : country === "georgia" ? (
                            <>
                              <select
                                name="step-aud-city"
                                id="step-aud-city"
                                className={classes.step_select_item}
                                onChange={(e) => this.handleChange(e)}
                              >
                                <option value="">Select</option>
                                <option value="chughureti">Chughureti</option>
                                <option value="didube">Didube</option>
                                <option value="eldani">Eldani</option>
                                <option value="isani">Isani</option>
                                <option value="krtsanisi">Krtsanisi</option>
                                <option value="mtatsminda">Mtatsminda</option>
                                <option value="nadzaladevi">Nadzaladevi</option>
                                <option value="saburtalo">Saburtalo</option>
                                <option value="samgori">Samgori</option>
                                <option value="vake">Vake</option>
                              </select>
                            </>
                          ) : (
                            <select
                              name="step-aud-location"
                              id="step-aud-location"
                              className={classes.step_select_item}
                              onChange={(e) => this.handleChange(e)}
                            >
                              <option value="">Select</option>
                              {city === "chicago"
                                ? [
                                    60647, 60639, 60707, 60622, 60651, 60611,
                                    60638, 60652, 60626, 60615, 60621, 60645,
                                    60643, 60660, 60640, 60614, 60631, 60646,
                                    60628, 60625, 60641, 60657, 60636, 60649,
                                    60617, 60633, 60643, 60612, 60604, 60624,
                                    60656, 60644, 60655, 60603, 60605, 60653,
                                    60609, 60666, 60618, 60616, 60602, 60601,
                                    60608, 60607, 60661, 60606, 60827, 60630,
                                    60642, 60659, 60707, 60634, 60613, 60610,
                                    60654, 60632, 60623, 60629, 60620, 60637,
                                    60619,
                                  ].map((el, i) => (
                                    <option value={el} key={i}>
                                      {el}
                                    </option>
                                  ))
                                : [
                                    94102, 94103, 94104, 94105, 94107, 94108,
                                    94109, 94110, 94111, 94112, 94114, 94115,
                                    94116, 94117, 94118, 94119, 94120, 94121,
                                    94122, 94123, 94124, 94125, 94126, 94127,
                                    94128, 94129, 94130, 94131, 94132, 94133,
                                    94134, 94137, 94139, 94140, 94140, 94141,
                                    94142, 94143, 94144, 94145, 94146, 94147,
                                    94151, 94158, 94159, 94160, 94161, 94163,
                                    94164, 94172, 94177, 94188,
                                  ].map((el, i) => (
                                    <option value={el} key={i}>
                                      {el}
                                    </option>
                                  ))}
                            </select>
                          )}
                          <img
                            src={error.locations ? LocationError : LocationImg}
                            alt=""
                            className={classes.step_form_icon_selector}
                          />
                        </div>

                        {/*<!-- <span class="pass_error">Error message</span> -->*/}
                      </div>
                    ) : null}
                    <button type="submit" className={classes.step3_btn}>
                      Next
                    </button>
                    <div className={classes.step_center}>
                      <Link
                        to="/ad-manager/audience"
                        className={classes.step3_back_link}
                      >
                        <img src={ArrowLeft} alt="" />
                        <span>Go Back</span>
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className={classes.create_right_info}>
              <div className={classes.create_info}>
                <p className={classes.create_info_icon}>
                  <img src={InfoCircle} alt="" />
                  <span>Information</span>
                </p>
                <ul className={classes.create_info_ul}>
                  <li>
                    <p className={classes.create_ul_p}>Audience Location</p>
                    <div className={classes.create_ul_txt}>
                      Define the location where you show your ad. Do not forget
                      to put{" "}
                      {country.includes("Azerbaijan")
                        ? "District Name"
                        : "Zip Code"}
                    </div>
                    <div className={`${classes.create_ul_txt} mt-2`}>
                      To delete a location click on it on the map
                    </div>

                    <div className={`${classes.create_ul_txt}`}>
                      Clicking multiple button reset Locations you picked
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <footer className={classes2.footer}>
          <div className={`${classes2.mur_contain} ${classes2.mur_flex}`}>
            <p className={`${classes2.footer_copyright} ${classes2.mur_flex}`}>
              <img src={Copyright} alt="" />
              <span>{new Date().getFullYear()}, MurmurCars</span>
            </p>
            <ul className={classes2.footer_links}>
              <li>
                <a href="#" className={classes2.footer_link}>
                  All rights reserved
                </a>
              </li>
              <li>
                <a href="#" className={classes2.footer_link}>
                  Privacy Policy
                </a>
              </li>
            </ul>
          </div>
        </footer>
      </React.Fragment>
    );
  }
}

export default connect(null, { toggleSideBar })(withRouter(Location));
