import React, { Component } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { withRouter, Link } from "react-router-dom";

//i18n
import { withNamespaces } from "react-i18next";

// users
import user1 from "../../../assets/images/avatar.png";
import classes from "../../../assets/css/Settings/settings.module.css";
import "../../../assets/css/surveys/profile/index.css";

class ProfileMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
      username: sessionStorage.getItem("fullName")
        ? sessionStorage.getItem("fullName")
        : null,

      profile_image: {
        src: "",
      },
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState((prevState) => ({
      menu: !prevState.menu,
    }));
  }
  componentDidMount() {
    const url = sessionStorage.getItem("profileImage");
    let image = {
      src: "",
    };
    if (url) {
      image = new Image();
      image.src = url;
      image.name = url.split(
        "https://backendapp.murmurcars.com/advertisers/users/profilePhoto/"
      )[1];
    }
    this.setState({
      ...this.state,
      profile_image: image,
    });
  }
  render() {

    let image = user1;

    if (this.state.profile_image.src) {
      image = this.state.profile_image.src;
    } 
    if (this.props.image && this.props.scope === "local") {
      image = this.props.image;
    }

    return (
      <React.Fragment>
        <Dropdown
          isOpen={this.state.menu && this.props.scope !== "local"}
          toggle={this.toggle}
        >
          <DropdownToggle
            id="page-header-user-dropdown"
            tag="button"
            type="button"
            className={`${
              this.props.scope === "global"
                ? classes.search_profil
                : classes.profil_cover
            }`}
          >
            <img
              src={image}
              alt=""
              className={`${
                this.props.scope === "global"
                  ? classes.profil_img
                  : classes.profil_cover_img
              }`}
            />
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem tag="a" href="/settings">
              <i className="bx bx-user font-size-16 align-middle mr-1"></i>
              {this.props.t("Profile")}
            </DropdownItem>

            <div className="dropdown-divider"></div>

            <Link to="/logout" className="dropdown-item">
              <i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i>
              <span>{this.props.t("Logout")}</span>
            </Link>
          </DropdownMenu>
        </Dropdown>
      </React.Fragment>
    );
  }
}

export default withRouter(withNamespaces()(ProfileMenu));
