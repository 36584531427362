import React, { useState } from "react";
import { Tooltip as ReactstrapTooltip } from "reactstrap";

export class Tooltip extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tooltip: false,
    };
  }

  toggle = () => {
    this.setState((state) => {
      return {
        ...state,
        tooltip: !state.tooltip,
      };
    });
  };
  render() {
    const { tooltip } = this.state;
    const { target, children } = this.props;
    return (
      <ReactstrapTooltip
        placement="top"
        isOpen={this.props.show ? this.props.show : tooltip}
        target={target}
        toggle={this.props.show ? () => null : this.toggle}
      >
        {children}
      </ReactstrapTooltip>
    );
  }
}
