//create
const TOGGLEBRANDEDCITY = "TOGGLEBRANDEDCITY";
const LOCATECOUNTRYANDCITY = "LOCATECOUNTRYANDCITY";
const TITLEANDCAMPAIGNDESCRIPTION = "TITLEANDCAMPAIGNDESCRIPTION";
const ASSIGNPROFFECIONALS = "ASSIGNPROFFECIONALS";
const ADDPROFECIONALS = "ADDPROFECIONALS";
const HYPETARGETPROPERTIES = "HYPETARGETPROPERTIES";
const TOGGLEHYPEPR = "TOGGLEHYPEPR";
const BANNERANDLINKS = "BANNERANDLINKS";
const UPLOADSOCIALMEDIALINKS = "UPLOADSOCIALMEDIALINKS";
const BUDGETFORCAMPAIGN = 'BUDGETFORCAMPAIGN'
const CREATENEWHYPECAMPAIGN = "CREATENEWHYPECAMPAIGN";
const CREATENEWHYPECAMPAIGNSUCCESS = "CREATENEWHYPECAMPAIGNSUCCESS";
const CREATENEWHYPECAMPAIGNFAILED = "CREATENEWHYPECAMPAIGNFAILED";
const DELETEBANNER = 'DELETEBANNER'

//fetch
const FETCHALLCREATEDHYPECAMPAIGNS = 'FETCHALLCREATEDHYPECAMPAIGNS'
const FETCHALLCREATEDHYPECAMPAIGNSSUCCESS = 'FETCHALLCREATEDHYPECAMPAIGNSSUCCESS'
const FETCHALLCREATEDHYPECAMPAIGNSFAILED = 'FETCHALLCREATEDHYPECAMPAIGNSFAILED'
const FETCHCAMPAIGNTOUPDATEDETAILS = 'FETCHCAMPAIGNTOUPDATEDETAILS'
const FETCHCAMPAIGNTOUPDATEDETAILSSUCCESS = 'FETCHCAMPAIGNTOUPDATEDETAILSSUCCESS'
const FETCHCAMPAIGNTOUPDATEDETAILSFAILED = 'FETCHCAMPAIGNTOUPDATEDETAILSFAILED'
const PUSHUPDATEDDETAILSOFHYPECAMPAIGNTOBACKEND = 'PUSHUPDATEDDETAILSOFHYPECAMPAIGNTOBACKEND'
const PUSHUPDATEDDETAILSOFHYPECAMPAIGNSUCCESS = 'PUSHUPDATEDDETAILSOFHYPECAMPAIGNSUCCESS'
const PUSHUPDATEDDETAILSOFHYPECAMPAIGNFAILED  ='PUSHUPDATEDDETAILSOFHYPECAMPAIGNFAILED'
export {
  //create
  TOGGLEBRANDEDCITY,
  LOCATECOUNTRYANDCITY,
  TITLEANDCAMPAIGNDESCRIPTION,
  ASSIGNPROFFECIONALS,
  ADDPROFECIONALS,
  HYPETARGETPROPERTIES,
  TOGGLEHYPEPR,
  BANNERANDLINKS,
  UPLOADSOCIALMEDIALINKS,
  BUDGETFORCAMPAIGN,
  CREATENEWHYPECAMPAIGN,
  CREATENEWHYPECAMPAIGNSUCCESS,
  CREATENEWHYPECAMPAIGNFAILED,
  DELETEBANNER,
  //fetch
  FETCHALLCREATEDHYPECAMPAIGNS,
  FETCHALLCREATEDHYPECAMPAIGNSSUCCESS,
  FETCHALLCREATEDHYPECAMPAIGNSFAILED,
  FETCHCAMPAIGNTOUPDATEDETAILS,
  FETCHCAMPAIGNTOUPDATEDETAILSSUCCESS,
  FETCHCAMPAIGNTOUPDATEDETAILSFAILED,
  PUSHUPDATEDDETAILSOFHYPECAMPAIGNTOBACKEND,
  PUSHUPDATEDDETAILSOFHYPECAMPAIGNSUCCESS,
  PUSHUPDATEDDETAILSOFHYPECAMPAIGNFAILED
};
