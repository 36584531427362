import { useState } from "react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import axios from "axios";

import "../../assets/css/stripe.scss";

function CheckoutForm({ user_id, priceId,subscribedSuccessfully }) {
  // collect data from the user
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false)

  // stripe items
  const stripe = useStripe();
  const elements = useElements();

  /*var element = elements.create('card', {
      style: {
        base: {
          iconColor: '#c4f0ff',
          color: '#fff',
          fontWeight: '500',
          fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
          fontSize: '16px',
          fontSmoothing: 'antialiased',
          ':-webkit-autofill': {
            color: '#fce883',
          },
          '::placeholder': {
            color: '#87BBFD',
          },
        },
        invalid: {
          iconColor: '#FFC7EE',
          color: '#FFC7EE',
        },
      },
    });*/
  // main function
  const createSubscription = async (e) => {
    e.preventDefault();
    setLoading(true)
    try {
      // call the backend to create subscription

      // create a payment method
      const paymentMethod = await stripe?.createPaymentMethod({
        type: "card",
        card: elements != null && elements.getElement(CardElement),
        billing_details: {
          name,
          email,
        },
      });


      const customer = await axios.post(
        "http://localhost:4000/api/v1/ai/create-customer",
        {
          user_id,
          customer: { email, name },
          paymentMethod: paymentMethod?.paymentMethod?.id,
        }
      );
      /*.then((response) => {
          const {resp} = response.data
          //setProfile(resp)
          
        })
        .catch((err) => {})*/

      const { id } = customer.data;

      const subscription = await axios.post(
        "http://localhost:4000/api/v1/ai/create-subscription",
        {
          user_id,
          customerId: id,
          priceId,
        }
      );
      const { data } = subscription;
      const { client_secret } = data.latest_invoice.payment_intent;
      const result = await stripe?.confirmCardPayment(client_secret);


      if (result?.error) {
         setLoading(false)
      } else {
        setLoading(false)
        const profile = {
          plan: data.plan.id,
          active: data.plan.active,
          currency: data.plan.currency,
          amount: data.plan.amount,
          interval: data.plan.interval,
          
        };

        const subscr = {
          id: data.id,
          package: subscription.package
        }
        subscribedSuccessfully(profile, subscr)
        
      }
    } catch (error) {
      console.log('netwrok issue')
    }
  };


  const disabled = !stripe || !stripe || !name;
  return (
    <div className="stripe">
      <form onSubmit={createSubscription}>
        <div className="stripe-div">
          <input
            placeholder="Name"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />

          <input
            placeholder="Email"
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <CardElement
          style={{
            base: {
              color: "#32325d",
              lineHeight: "24px",
              fontFamily: "Helvetica Neue",
              fontSmoothing: "antialiased",
              fontSize: "16px",
              border: "1px solid #fa755a",
              iconSize: "30px",
              "::placeholder": {
                color: "#aab7c4",
              },
            },
            invalid: {
              color: "#fa755a",
              iconColor: "#fa755a",
            },
          }}
        />

        <button
          type="submit"
          className={`${disabled ? "disabled" : "active"}`}
          disabled={disabled}
        >
          Subscribe
        </button>
      </form>
    </div>
  );
}

export { CheckoutForm };
