import React from "react";
import { Component, Fragment } from "react";

//reactstrap and react-datepicker
import { Button } from "reactstrap";
//import { DatePicker } from "antd";
import { DatePicker } from "antd";

//router and redux
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import {Alert} from "reactstrap";

//assets and map
import GoogleMap from "./google-map";
import classes from "../../assets/css/Dashboard/dashboard.module.css";
import classes2 from "../../assets/css/air-quality/index.module.scss";
import "../../assets/css/Dashboard/date_picker.css";
import ChevronDown from "../../assets/css/Dashboard/chevron-down.svg";
import ChevronUp from "../../assets/css/Dashboard/chevron-up.svg";
import PedestriansMeasure from "../../assets/css/Dashboard/pedestrians-measure.svg";
import Pedestrian from "../../assets/css/Dashboard/pedestrian.svg";
import Temperature from "../../assets/css/Dashboard/temperature.svg";
import DateIcon from "../../assets/css/Dashboard/date-icon.svg";
import NoiseLevel from "../../assets/css/Dashboard/noise-level.svg";
import AirQuality from "../../assets/css/Dashboard/air-quality.svg";
import ChevronRight from "../../assets/css/Dashboard/chevron-right.svg";
import Population_hight from "../../assets/css/Dashboard/population_high.svg";
import Population_avg from "../../assets/css/Dashboard/population-avg.svg";
import Population_low from "../../assets/css/Dashboard/population_low.svg";
import Plus from "../../assets/css/Dashboard/plus.svg";
import Add from "../../assets/css/Dashboard/add.svg";
import BadAIQ from "../../assets/css/Dashboard/Bad-aiq.svg";
import ModerateAIQ from "../../assets/css/Dashboard/Moderate-aiq.svg";
import GoodAIQ from "../../assets/css/Dashboard/Good-aiq.svg";
import ArrowRight from "../../assets/css/air-quality/arrow-right.svg";
import ChangeLoc from "../../assets/css/Dashboard/aiq-change-loc.svg";
import OverallInfo from "../../assets/css/Dashboard/aiq-overall.svg";
import BillboardSize from "../../assets/css/air-quality/billboard-size.svg";
import Traffic from "../../assets/css/air-quality/traffic.svg";
import BillboardHeight from "../../assets/css/air-quality/billboard-height.svg";
import BillboardAngle from "../../assets/css/air-quality/angle.svg";
import Clock from "../../assets/css/air-quality/clock.svg";
import Earth from "../../assets/images/earth-spins.gif";
//action
import { toggleSideBar } from "../../store/actions";

//axiso
import axios from "axios";

import Geocode from "react-geocode";

import LocationModal from "../../components/location-modal";


const GOOGLE_MAP_KEY = process.env.REACT_APP_GOOGLEMAPKEY;
Geocode.setApiKey(GOOGLE_MAP_KEY);

class AirQualityContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      location_change_spinner: false,
      err: null,
      permission: true,
      alert_status: false,
      location_alert: true,
      modal: false,
      map: {
        address: "",
        country: "",
        city: "",
        center: {
          lng: 0,
          lat: 0,
        },
        postCenter: {
          lng: 0,
          lat: 0,
        },
        zoom: 11,

        markers: {
          pedestrian: [],
          air_quality: [],
          billboards: [],
          noise_level: [],
        },
      },
      pedestrian: {
        display: false,
        min: 0,
        max: 0,
        avg: 0,
        streets: [],
      },
      temperature: {
        display: false,
        city: "",
        temp_scale: "C",
        data: {
          avg_t: 0,
          min_t: 0,
          max_t: 0,
        },
      },
      air_quality: {
        display: false,
        streets: [],
      },
      overall_info: {
        display: false,
        data: {
          billboard_size: "",
          billboard_height: "",
          dwell_time: "",
          traffic_speed: "",
          vision_angle: "",
          number_of_billboards: "",
        },
      },
      billboards: {
        display: false,
      },
      noise_level: { display: false, streets: [] },
      user_input: {
        date_picked: "",
      },
    };
    this.async = null;
  }

  populationAndTempApi = async (city) => {
    city = city.toLowerCase();
    const response = await axios.get(
      `https://backendapp.murmurcars.com/api/v1/air-quality/get-population?city=${city}`
    );

    const { data } = response.data;
    if (data.status === 204) {
      throw data;
    }

    return data;
  };

  fetchStreetsFromBackend = async (city, category) => {
    city = city.toLowerCase();
    const response = await axios.get(
      `https://backendapp.murmurcars.com/api/v1/admin/get-all-streets?city=${city}&category=${category}`
    );
    
    const { data } = response;

    if (data.status === 204) {
      throw data;
    }

    return data;
  };
  getPopulation = async (city) => {
    try {
      const response = await this.populationAndTempApi(city);
      const streets = await this.fetchStreetsFromBackend(city, "crowded");
      
      const { avg, max, min, locations } = response;
      let { all_location_population } = response;
      const centers = [];
      for (let location of locations) {
        const center = await this.locateGeolocationOnMapFromCity(
          `${city},${location}`
        );

        centers.push(center);
      }

      all_location_population = all_location_population.map((el, i) => {
        if (el.population < avg - avg * 0.3) {
          return {
            position: centers[i],
            image: Population_low,
            population: el.population,
            name: el.name,
          };
        } else if (
          el.population >= avg - avg * 0.3 &&
          el.population <= avg + avg * 0.3
        ) {
          return {
            position: centers[i],
            image: Population_avg,
            population: el.population,
            name: el.name,
          };
        } else {
          return {
            position: centers[i],
            image: Population_hight,
            population: el.population,
            name: el.name,
          };
        }
      });

      return { avg, min, max, all_location_population, streets };
    } catch (err) {
      throw err.message;
    }
  };

  locateGeolocationOnMap = () =>
    new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const center = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          resolve(center);
        },
        () => {
          reject();
        }
      );
    });

  locateGeolocationOnMapFromCity = (location) =>
    new Promise((resolve, reject) => {
      Geocode.fromAddress(location)
        .then((response) => {
          const { lat, lng } = response.results[0].geometry.location;
          const center = {
            lat,
            lng,
          };
          resolve(center);
        })
        .catch((err) => {
          
          reject();
        });
    });

  handleReverseGeocode = (center) =>
    new Promise((resolve, reject) => {
      {
        Geocode.fromLatLng(center.lat, center.lng)
          .then((response) => {
            const address = response.plus_code.compound_code;
            const country = address
              .split(/\s|[,]/i)
              .filter((el) => el !== "")
              .at(-1)
              .trim();
              let city = address
              .split(/\s|[,]/i)
              .filter((el) => el !== "")
              .at(-2)
              .trim();
            
            if(country === 'USA'){
              city = city === 'Illinois' ? 'Chicago' : 'SF'
            }
            resolve({ address, city,country });
          })
          .catch(() => {
            reject();
          });
      }
    });

  /*componentDidMount() {
    this.setState({
      ...this.state,
      loading: true,
    });

    this.locateGeolocationOnMap()
      .then((center) => {
        this.setState({
          map: {
            ...this.state.map,
            center,
            postCenter: center,
            permission: true,
            alert_status: false,
          },
        });
        this.initialWeatherInfo(center);
      })
      .catch(() => {
        this.setState({
          ...this.state,
          alert_status: true,
          permission: false,
          loading: false,
        });
      });
  }*/

  populationDencity = async (types, type) => {
    const { city } = this.state.map;
    let states = {};
    try {
      const data = await this.getPopulation(city);
      /*const response = await axios.get(
        `http://localhost:4000/api/v1/admin/fetch-streets/${city}`
      );
      const { most_crowded_streets } = response.data;*/
      this.setState((state) => ({
        ...state,
        loading: false,
        alert_status: false,
        permission: true,
        air_quality: {
          ...state.air_quality,
          display: false,
        },
        pedestrian: {
          ...state.pedestrian,
          display: !state.pedestrian.display,
          min: data.min,
          max: data.max,
          avg: data.avg,
          streets: data.streets,
        },
        noise_level: {
          ...state.noise_level,
          display: false,
        },
        temperature: {
          ...state.temperature,
          display: false,
        },
        overall_info: {
          ...state.overall_info,
          display: false,
        },
        billboards: {
          display: false,
        },
        map: {
          ...state.map,
          loading: false,
          markers: {
            ...state.map.markers,
            pedestrian: data.all_location_population,
          },
        },
      }));
    } catch (err) {
      this.setState((state) => ({
        ...state,
        loading: false,
        err: err,
        permission: false,
        alert_status: true,
        air_quality: {
          ...state.air_quality,
          display: false,
        },
        pedestrian: {
          ...state.pedestrian,
          display: !state.pedestrian.display,
        },
        noise_level: {
          ...state.noise_level,
          display: false,
        },
        temperature: {
          ...state.temperature,
          display: false,
        },
        overall_info: {
          ...state.overall_info,
          display: false,
        },
        billboards: {
          display: false,
        },
      }));
    }
  };

  fetchBillboards = async () => {
    const { city } = this.state.map;
    
    try {
      const response = await axios.get(
        `https://backendapp.murmurcars.com/api/v1/venues/overall-statistic/${city.toLowerCase()}`
      );
      if (response.data.status === 204) {
        throw new Error(response.data.message);
      }
      const {
        average_billboard_height,
        average_billboard_size,
        average_dwell_time,
        average_traffic_speed,
        average_vision_angle,
        number_of_billboards,
      } = response.data;

      this.setState((state) => {
        return {
          ...state,
          loading: false,
          alert_status: false,
          permission: true,
          air_quality: {
            ...state.air_quality,
            display: false,
          },
          pedestrian: {
            ...state.pedestrian,
            display: false,
          },
          noise_level: {
            ...state.noise_level,
            display: false,
          },
          billboards: {
            ...state.billboards,
            display: false,
          },
          temperature: {
            ...state.temperature,
            display: false,
          },
          overall_info: {
            display: !state.overall_info.display,
            data: {
              average_billboard_height,
              average_billboard_size,
              average_dwell_time,
              average_traffic_speed,
              average_vision_angle,
              number_of_billboards,
            },
          },
          map: {
            ...state.map,
            loading: false,
          },
        };
      });
    } catch (err) {
      this.setState((state) => {
        return {
          ...this.state,
          loading: false,
          err: err.message,
          permission: false,
          alert_status: true,
          air_quality: {
            ...state.air_quality,
            display: false,
          },
          pedestrian: {
            ...state.pedestrian,
            display: false,
          },
          noise_level: {
            ...state.noise_level,
            display: false,
          },
          billboards: {
            display: false,
          },
          temperature: {
            ...state.temperature,
            display: false,
          },
          overall_info: {
            ...state.overall_info,
            display: true,
          },
          map: {
            ...state.map,
            loading: false,
          },
        };
      });
    }
  };
  toggleCollapse = async (type) => {
    const types = [
      "pedestrian",
      "temperature",
      "noise_level",
      "air_quality",
      "overall_info",
    ];
    let states = {};
    this.setState({
      ...this.state,
      loading: true,
    });
    if (
      type === "pedestrian" &&
      !this.state.pedestrian.display &&
      !this.state.map.markers.pedestrian.length
    ) {
      this.populationDencity(types, type);
    } else if (type === "overall_info" && !this.state.overall_info.display) {
      this.fetchBillboards(types, type);
    } else {
      for (let t of types) {
        if (t === type) {
          states[t] = {
            ...this.state[type],
            display: !this.state[type].display,
          };
        } else {
          states[t] = {
            ...this.state[t],
            display: false,
          };
        }
      }
      this.setState({
        ...this.state,
        alert_status: false,
        permission: true,
        loading: false,
        ...states,
      });
    }
  };

  toggleNoisePolutionWindow = async () => {
    const display = !this.state.noise_level.display;
    const { map, noise_level: NoiseLevel } = this.state;
    const { markers, city } = map;
    const { pedestrian, noise_level: noise } = markers;
    const noise_quality = noise;
    let streets = NoiseLevel.streets;
    let lat_lngs = pedestrian;
    if (display && !noise_quality.length) {
      this.setState({
        ...this.state,
        loading: true,
      });
      try {
        streets = await this.fetchStreetsFromBackend(city, "quietest");
        if (!pedestrian.length) {
          const data = await this.getPopulation(city);
          lat_lngs = data.all_location_population;
        }

        for (let i = 0; i < lat_lngs.length; i++) {
          const { lat, lng } = lat_lngs[i].position;

          const response = await this.noisePolutionApi(lat, lng);
      
          const { scoretext } = response;
          const location = {
            name: lat_lngs[i].name,
            noise_level:
              scoretext === "Calm"
                ? "Good"
                : scoretext === "Active"
                ? "Moderate"
                : "Bad",
            position: { lat, lng },
            image:
              scoretext === "Calm"
                ? GoodAIQ
                : scoretext === "Active"
                ? ModerateAIQ
                : BadAIQ,
          };

          noise_quality.push(location);
        }
      } catch (err) {
        this.setState((state) => ({
          ...state,
          loading:false,
          alert_status: true,
          permission: false,
          err,
          air_quality: {
            ...state.air_quality,
            display: false,
          },
          pedestrian: {
            ...state.pedestrian,
            display: false,
          },
          noise_level: {
            ...state.noise_level,
            display
          },
          temperature: {
            ...state.temperature,
            display: false,
          },
          overall_info: {
            ...state.overall_info,
            display: false,
          },
        }));
        return
      }
    }

    this.setState((state) => {
      return {
        ...this.state,
        loading: false,
        alert_status: false,
        permission: true,
        map: {
          ...state.map,
          markers: {
            ...state.map.markers,
            noise_level: noise_quality,
          },
        },
        air_quality: {
          ...state.air_quality,
          display: false,
        },
        pedestrian: {
          ...state.pedestrian,
          display: false,
        },
        noise_level: {
          ...state.noise_level,
          streets,
          display,
        },
        temperature: {
          ...state.temperature,
          display: false,
        },
        overall_info: {
          ...state.overall_info,
          display: false,
        },
      };
    });
  };

  toggleAirQualityWindow = async () => {
    const display = !this.state.air_quality.display;
    const { map, air_quality: Air_quality } = this.state;
    const { markers, city } = map;
    const { pedestrian, air_quality: aiq } = markers;
    const air_quality = aiq;
    let streets = Air_quality.streets;
    
    let lat_lngs = pedestrian;
    if (display && !air_quality.length) {
      this.setState({
        ...this.state,
        loading: true,
      });
      try {
        streets = await this.fetchStreetsFromBackend(city, "cleanest");
        
        if (!pedestrian.length) {
          const data = await this.getPopulation(city);
          lat_lngs = data.all_location_population;
        }

        for (let i = 0; i < lat_lngs.length; i++) {
          const { lat, lng } = lat_lngs[i].position;

          const response = await this.airQualityApi(lat, lng);
          const location = {
            name: lat_lngs[i].name,
            aiq:
              response["gb-defra-index"] === 1
                ? "Good"
                : response["gb-defra-index"] <= 2 &&
                  response["gb-defra-index"] > 1
                ? "Moderate"
                : "Bad",
            position: { lat, lng },
            image:
              response["gb-defra-index"] === 1
                ? GoodAIQ
                : response["gb-defra-index"] <= 2 &&
                  response["gb-defra-index"] > 1
                ? ModerateAIQ
                : BadAIQ,
          };

          air_quality.push(location);
        }
      } catch (err) {
        this.setState((state) => ({
          ...state,
          loading:false,
          alert_status: true,
          permission: false,
          err,
          air_quality: {
            ...state.air_quality,
            display,
          },
          pedestrian: {
            ...state.pedestrian,
            display: false,
          },
          noise_level: {
            ...state.noise_level,
            display:false
          },
          temperature: {
            ...state.temperature,
            display: false,
          },
          overall_info: {
            ...state.overall_info,
            display: false,
          },
        }));
        return
      }
    }
    this.setState((state) => {
      return {
        ...this.state,
        loading: false,
        alert_status: false,
        map: {
          ...state.map,
          markers: {
            ...state.map.markers,
            air_quality,
          },
        },
        air_quality: {
          ...state.air_quality,
          streets,
          display: display,
        },
        pedestrian: {
          ...state.pedestrian,
          display: false,
        },
        noise_level: {
          ...state.noise_level,
          display: false,
        },
        temperature: {
          ...state.temperature,
          display: false,
        },
        overall_info: {
          ...state.overall_info,
          display: false,
        },
        billboards: {
          ...state.billboards,
          display: false,
        },
      };
    });
  };

  airQualityApi = (lat, lng) =>
    new Promise((resolve, reject) => {
      axios
        .get(
          `https://backendapp.murmurcars.com/api/v1/air-quality/air-quality-for-given-latlng?lat=${lat}&lng=${lng}`
        )

        .then((response) => {
          const air_quality = response.data;
          
          resolve(air_quality);
        })
        .catch((err) => {
          reject(err);
        });
    });
  noisePolutionApi = (lat, lng) =>
    new Promise((resolve, reject) => {
      axios
        .get(
          `https://backendapp.murmurcars.com/api/v1/air-quality/noise-polution-for-given-latlng?lat=${lat}&lng=${lng}`
        )

        .then((response) => {
          const { to_frontend } = response.data;
          if (to_frontend == null) {
            reject("we do not provide data for this region yet");

          }
          resolve(to_frontend);
        })
        .catch((err) => {
          reject(err);
        });
    });

  toggleBillboardsOnMap = async () => {
    const { map } = this.state;
    const { country } = map;

    const display = !this.state.billboards.display;
    if (!display) {
      return;
    }
    this.setState((state) => {
      return {
        ...state,
        loading: true,
      };
    });

    axios
      .get(
        `https://backendapp.murmurcars.com/api/v1/venues/all-venues-by-city/${country}`
      )
      .then((response) => {
        const billboards = response.data;
        this.setState((state) => {
          return {
            ...state,
            loading: false,
            alert_status: false,
            permission: true,
            map: {
              ...state.map,
              markers: {
                ...state.map.markers,
                billboards,
              },
            },
            air_quality: {
              ...state.air_quality,
              display: false,
            },
            pedestrian: {
              ...state.pedestrian,
              display: false,
            },
            noise_level: {
              ...state.noise_level,
              display: false,
            },
            temperature: {
              ...state.temperature,
              display: false,
            },
            overall_info: {
              ...state.overall_info,
              display: false,
            },
            billboards: {
              ...state.billboards,
              display,
            },
          };
        });
      })
      .catch((err) => {
        this.setState((state) => {
          return {
            ...state,
            loading: false,
            billboards: false,
          };
        });
      });
  };

  handleDateChange = (data) => {
    this.setState({
      ...this.state,
      user_input: {
        ...this.state.user_input,
        date_picked: data,
      },
    });
  };
  handleResetDate = () => {
    this.setState({
      ...this.state,
      user_input: {
        ...this.state.user_input,
        date_picked: new Date(),
      },
    });
  };

  getTemperatureDataFromApi = () => {
    const { map, temperature, user_input } = this.state;
    const { date_picked } = user_input;
    let { city } = temperature;
    const { country } = map;

    const str = date_picked.toString();
    let parts = str.split(" ");
    let months = {
      Jan: "01",
      Feb: "02",
      Mar: "03",
      Apr: "04",
      May: "05",
      Jun: "06",
      Jul: "07",
      Aug: "08",
      Sep: "09",
      Oct: "10",
      Nov: "11",
      Dec: "12",
    };
    const date = parts[3] + "-" + months[parts[1]] + "-" + parts[2];
    let feature = false;
    if (date) {
      this.setState({
        ...this.state,
        loading: true,
      });

      if (
        new Date() < new Date(date_picked) ||
        new Date() === new Date(date_picked)
      ) {
        feature = true;
      }
      const url = `https://backendapp.murmurcars.com/api/v1/air-quality/weater-for-selected-day/${city}/${date}?feature=${feature}`;
      axios
        .get(url)
        .then((fetched_data) => {
          //fetched data
          const forecastday = fetched_data.data;
          const { day } = forecastday[0];

          let temp_scale = "C";
          if (country === "US") {
            temp_scale = "F";
          }
          const data = ((scale) => {
            if (scale === "C") {
              const { maxtemp_c: max, mintemp_c: min, avgtemp_c: avg } = day;
              return { max, min, avg };
            } else {
              const { maxtemp_f: max, mintemp_f: min, avgtemp_f: avg } = day;
              return { max, min, avg };
            }
          })(temp_scale);

          const { max, min, avg } = data;

          this.setState({
            ...this.state,
            loading: false,
            temperature: {
              ...this.state.temperature,
              temp_scale,
              data: { max_t: max, avg_t: avg, min_t: min },
            },
          });
        })
        .catch((err) => {
          this.setState({
            ...this.state,

            loading: false,
          });
        });
    }
  };

  toAddCampaign = () => {
    this.props.history.push("/ad-manager/campaign-objective");
    window.location.reload();
  };

  initialWeatherInfo = (center) => {
    this.handleReverseGeocode(center)
      .then(({ address, city,country }) => {
        axios
          .get(
            `https://backendapp.murmurcars.com/api/v1/air-quality/initial-weater/${city}`
          )
          .then((fetched_data) => {
            //fetched data

            const forecastday = fetched_data.data;
            const { day } = forecastday[0];

          

            let temp_scale = "C";
            if (country === "US") {
              temp_scale = "F";
            }

            const data = ((scale) => {
              if (scale === "C") {
                const { maxtemp_c: max, mintemp_c: min, avgtemp_c: avg } = day;
                return { max, min, avg };
              } else {
                const { maxtemp_f: max, mintemp_f: min, avgtemp_f: avg } = day;
                return { max, min, avg };
              }
            })(temp_scale);

            const { max, min, avg } = data;
            this.setState({
              ...this.state,
              loading: false,
              temperature: {
                ...this.state.temperature,
                temp_scale,
                data: { max_t: max, avg_t: avg, min_t: min },
              },
              map: { ...this.state.map, city, address, country },
            });
          })
          .catch((err) => {
            this.setState({
              ...this.state,
              loading: false,
              map: { ...this.state.map, city, address, country },
            });
          });
      })
      .catch(() => {
        this.setState({
          ...this.state,
          loading: false,

        });
      });
  };

  cityPickerForTemperature = (e) => {
    const value = e.target.value.toLowerCase();
    this.setState({
      ...this.state,
      map: {
        ...this.state.map,
        country:
          value === "sf" || value === "chicago"
            ? "USA"
            : value === "baku"
            ? "Azerbaijan"
            : "Georgia",
      },
      temperature: {
        ...this.state.temperature,
        city: value,
      },
    });
  };

  cityChange = (city, country) => {
    this.setState((state) => ({
      ...state,
      location_change_spinner: true,
      modal: false,
    }));
    this.locateGeolocationOnMapFromCity(city)
      .then((center) => {
        axios
          .get(
            `https://backendapp.murmurcars.com/api/v1/air-quality/initial-weater/${city}`
          )
          .then((fetched_data) => {
            //fetched data
            const forecastday = fetched_data.data;
            const { day } = forecastday[0];

            let temp_scale = "C";
            if (country === "USA") {
              temp_scale = "F";
            }

            const data = ((scale) => {
              if (scale === "C") {
                const { maxtemp_c: max, mintemp_c: min, avgtemp_c: avg } = day;
                return { max, min, avg };
              } else {
                const { maxtemp_f: max, mintemp_f: min, avgtemp_f: avg } = day;
                return { max, min, avg };
              }
            })(temp_scale);

            const { max, min, avg } = data;
            this.setState((state) => {
              return {
                ...state,
                location_change_spinner: false,
                alert_status: false,
                err: null,
                temperature: {
                  ...state.temperature,
                  temp_scale,
                  data: { max_t: max, avg_t: avg, min_t: min },
                },
                map: {
                  ...state.map,
                  city,
                  country,
                  center,
                  postCenter: center,
                  markers: {
                    pedestrian: [],
                    air_quality: [],
                    noise_level: [],
                  },
                },
                pedestrian: {
                  ...state.pedestrian,
                  display: false,
                },
                air_quality: {
                  ...state.air_quality,
                  display: false,
                },
                noise_level: {
                  ...state.noise_level,
                  display: false,
                },
                billboards: {
                  ...state.billboards,
                  display: false,
                },
                modal: false,
              };
            });
          })
          .catch(() => {
            this.setState({
              ...this.state,
              location_change_spinner: false,
            });
          });
      })
      .catch(() => {
        this.setState({
          ...this.state,
          modal: false,
          map: {
            ...this.state.map,
            city,
            country,
          },
        });
      });
  };

  render() {
    const {
      map,
      loading,
      location_change_spinner,
      pedestrian,
      temperature,
      air_quality,
      noise_level,
      user_input,
      alert_status,
      location_alert,
      err,
      modal,
      overall_info,
      billboards,
    } = this.state;

    const { min, avg, max } = pedestrian;
    const renderOnMap = pedestrian.display
      ? "pedestrian"
      : air_quality.display
      ? "air_quality"
      : noise_level.display
      ? "noise_level"
      : billboards.display
      ? "billboards"
      : null;

    const { data, temp_scale, city } = temperature;
    const { min_t, max_t, avg_t } = data;
    const { date_picked } = user_input;

    const { country, city: loc } = map;


    return (
      <Fragment>
        {loading ? (
          <div id="preloader" style={{ opacity: 0.7 }}>
            <div id="status">
              <div className="spinner-chase">
                <div className="chase-dot"></div>
                <div className="chase-dot"></div>
                <div className="chase-dot"></div>
                <div className="chase-dot"></div>
                <div className="chase-dot"></div>
                <div className="chase-dot"></div>
              </div>
            </div>
          </div>
        ) : location_change_spinner ? (
          <div id="preloader" style={{ opacity: 0.7 }}>
            <div
              id="status"
              className="top-50"
              style={{ width: "100px", height: "100px" }}
            >
              <img src={Earth} alt="spinner" className="w-100 h-100" />{" "}
            </div>
          </div>
        ) : null}

        <div className={`${classes.dash_right}`}>
          <div className={`${classes.map} `}>
            <GoogleMap state={map} segment={renderOnMap} />
          </div>
          {!loading && !location_change_spinner && (
            <div className={classes.right_dashboard_cont}>
              <div
                className={`${classes.dashboard_right} ${classes.new_dashboard_right}`}
                style={{pointerEvents: 'all'}}
              >
                <div
                  className={`${classes.dashboard_collapses} ${classes.fixed_height}`}
                >
                  <div className={classes.colapsed_items_flex}>
                    <span>
                      <img src={ChangeLoc} />
                    </span>
                    <span>
                      <h3> Select Location</h3>
                    </span>
                    <span>
                      <Button
                        onClick={() => {
                          this.setState((state) => {
                            return {
                              ...state,
                              modal: !state.modal,
                              air_quality: {
                                display: false,
                              },
                              pedestrian: {
                                ...this.state.pedestrian,
                                display: false,
                              },
                              air_quality: {
                                ...this.state.air_quality,
                                display: false,
                              },
                              noise_level: {
                                ...this.state.noise_level,
                                display: false,
                              },
                              temperature: {
                                ...this.state.temperature,
                                display: false,
                              },
                              overall_info: {
                                ...this.state.overall_info,
                                display: false,
                              },
                            };
                          });
                        }}
                        className={classes.collapse_btn}
                        color="white"
                        aria-expanded={true}
                      >
                        <img src={ArrowRight} alt="collapse icon" />
                      </Button>
                    </span>
                  </div>
                </div>
                <div
                  className={`${classes.dashboard_collapses} ${
                    !pedestrian.display && classes.fixed_height
                  }`}
                >
                  <div className={classes.colapsed_items_flex}>
                    <span>
                      <img src={Pedestrian} />
                    </span>
                    <span>
                      <h3>Population dencity</h3>
                    </span>
                    <span>
                      <Button
                        color="white"
                        onClick={() => this.toggleCollapse("pedestrian")}
                        className={classes.collapse_btn}
                        aria-expanded={true}
                      >
                        <img
                          src={`${
                            pedestrian.display ? ChevronDown : ChevronUp
                          }`}
                          alt="collapse icon"
                        />
                      </Button>
                    </span>
                  </div>
                  <div className={classes.collapsed_item_open_flex}>
                    <div
                      className={`${classes.collapsed_item} ${
                        pedestrian.display && classes.collapsed_item_open
                      }`}
                    >
                      {alert_status ? (
                        <strong> {err} </strong>
                      ) : (
                        <>
                          <p className={classes.collapsed_item_p}>
                          Population density:
                          </p>
                          <div className={classes.measures_and_img}>
                            <img src={PedestriansMeasure} alt="measure" />
                            <span className={classes.pedestrians_measures}>
                              <p>{min}</p>
                              <p>{avg}</p>
                              <p>{max}</p>
                            </span>
                          </div>
                          <p
                            className={`${classes.collapsed_item_p} ${classes.margin_bottom_null}`}
                          >
                            Most crowded places:
                          </p>
                          <ol>
                            {pedestrian.streets.map((el, i) => {
                              return <li key={i}>{el}</li>;
                            })}
                          </ol>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className={`${classes.dashboard_collapses} ${
                    !temperature.display && classes.fixed_height_temp
                  }`}
                >
                  <div className={classes.colapsed_items_flex}>
                    <span>
                      <img src={Temperature} />
                    </span>
                    <span>
                      <h3>Temperature</h3>
                    </span>
                    <span>
                      <Button
                        color="white"
                        onClick={() => this.toggleCollapse("temperature")}
                        className={classes.collapse_btn}
                        aria-expanded={true}
                      >
                        <img
                          src={`${
                            temperature.display ? ChevronDown : ChevronUp
                          }`}
                          alt="collapse icon"
                        />
                      </Button>
                    </span>
                  </div>
                  <div className={classes.temp_info_cont}>
                    <span className={classes.collapsed_item_border}></span>
                    <div className={classes.temp_measurements}>
                      <span>
                        <p>Average:</p>
                        <span>{`${avg_t}°${temp_scale}`}</span>
                      </span>
                      <span>
                        <p>Lowest:</p>
                        <span>{`${min_t}°${temp_scale}`}</span>
                      </span>
                      <span>
                        <p>Hottest:</p>
                        <span>{`${max_t}°${temp_scale}`}</span>
                      </span>
                    </div>
                  </div>

                  <div className={classes.collapsed_item_open_flex}>
                    <div
                      className={`${classes.collapsed_item} ${
                        temperature.display && classes.collapsed_item_open
                      }`}
                    >
                      <p
                        className={`${classes.collapsed_item_p} ${classes.margin_bottom_reduce}`}
                      >
                        Show tempature of:
                      </p>
                      <input
                        type="text"
                        placeholder="City"
                        value={city}
                        className={`${classes.city_picke}`}
                        onChange={this.cityPickerForTemperature}
                      />
                      <p
                        className={`${classes.collapsed_item_p} ${classes.margin_reduce}`}
                      >
                        For:
                      </p>
                      <div
                        className={classes.date_picker_cont}
                        id="date-picker"
                      >
                        <DatePicker
                          className={`${classes.date_picker}`}
                          format="yyyy-MM-dd"
                          picker="date"
                          isClearable
                          selected={date_picked}
                          value={date_picked}
                          disabledDate={(d) =>
                            !d ||
                            d.isAfter(
                              new Date(
                                new Date().getFullYear(),
                                new Date().getMonth(),
                                new Date().getDate() + 6
                              )
                            ) ||
                            d.isSameOrBefore(
                              new Date(
                                new Date().getFullYear(),
                                new Date().getMonth(),
                                new Date().getDate() - 4
                              )
                            )
                          }
                          onChange={(data) => this.handleDateChange(data)}
                          placeholderText="Select date"
                        />
                        <span className={classes.date_picker_icon}>
                          <img src={DateIcon} alt="date-picker icon" />
                        </span>
                      </div>
                      <div className={classes.data_picker_btn_group}>
                        <button onClick={this.handleResetDate}>Reset</button>
                        <button onClick={this.getTemperatureDataFromApi}>
                          Show <img src={ChevronRight} alt="button icon" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={`${classes.dashboard_collapses} ${
                    !air_quality.display && classes.fixed_height
                  }`}
                >
                  <div className={classes.colapsed_items_flex}>
                    <span>
                      <img src={AirQuality} />
                    </span>
                    <span>
                      <h3>Airquality</h3>
                    </span>
                    <span>
                      <Button
                        color="white"
                        onClick={this.toggleAirQualityWindow}
                        className={classes.collapse_btn}
                        aria-expanded={true}
                      >
                        <img
                          src={`${
                            air_quality.display ? ChevronDown : ChevronUp
                          }`}
                          alt="collapse icon "
                        />
                      </Button>
                    </span>
                  </div>
                  <div className={classes.collapsed_item_open_flex}>
                    <div
                      className={`${classes.collapsed_item} ${
                        air_quality.display && classes.collapsed_item_open
                      }`}
                    >
                      {alert_status ? (
                        <strong> {err} </strong>
                      ) : (
                        <>
                          <p className={classes.collapsed_item_p}>
                            Airquality diagram:
                          </p>
                          <div className={classes.measures_and_img}>
                            <img src={PedestriansMeasure} alt="measure" />
                            <span className={classes.pedestrians_measures}>
                              <p>Poor</p>
                              <p>Moderate</p>
                              <p>Good</p>
                            </span>
                          </div>
                          <p
                            className={`${classes.collapsed_item_p} ${classes.margin_bottom_null}`}
                          >
                            Best places:
                          </p>

                          <ol>
                            {air_quality.streets.map((el, i) => {
                              return <li key={i}>{el}</li>;
                            })}
                          </ol>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className={`${classes.dashboard_collapses} ${
                    !noise_level.display && classes.fixed_height
                  }`}
                >
                  <div className={classes.colapsed_items_flex}>
                    <span>
                      <img src={NoiseLevel} />
                    </span>
                    <span>
                      <h3>Noise Level</h3>
                    </span>
                    <span>
                      <Button
                        color="white"
                        onClick={this.toggleNoisePolutionWindow}
                        className={classes.collapse_btn}
                        aria-expanded={true}
                      >
                        <img
                          src={`${
                            noise_level.display ? ChevronDown : ChevronUp
                          }`}
                          alt="collapse icon"
                        />
                      </Button>
                    </span>
                  </div>
                  <div className={classes.collapsed_item_open_flex}>
                    <div
                      className={`${classes.collapsed_item} ${
                        noise_level.display && classes.collapsed_item_open
                      }`}
                    >
                      {alert_status ? (
                        <strong> {err} </strong>
                      ) : (
                        <>
                          <p className={classes.collapsed_item_p}>
                            Noise level diagram:
                          </p>{" "}
                          <div className={classes.measures_and_img}>
                            <img src={PedestriansMeasure} alt="measure" />
                            <span className={classes.pedestrians_measures}>
                              <p>Poor</p>
                              <p>Moderate</p>
                              <p>Good</p>
                            </span>
                          </div>
                          <p
                            className={`${classes.collapsed_item_p} ${classes.margin_bottom_null}`}
                          >
                            Best places:
                          </p>
                          <ol>
                            {noise_level.streets.map((el, i) => {
                              return <li key={i}>{el}</li>;
                            })}
                          </ol>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className={`${classes.dashboard_collapses} ${classes.fixed_height}`}
                >
                  <div className={classes.colapsed_items_flex}>
                    <span>
                      <img src={Plus} />
                    </span>
                    <span>
                      <h3> Create Ad Campaign</h3>
                    </span>
                    <span>
                      <Button
                        onClick={this.toAddCampaign}
                        className={classes.collapse_btn}
                        color="white"
                        aria-expanded={true}
                      >
                        <img src={`${Add}`} alt="collapse icon" />
                      </Button>
                    </span>
                  </div>
                </div>

                <div
                  className={`${classes.dashboard_collapses} ${
                    !overall_info.display && classes.fixed_height
                  }`}
                >
                  <div className={classes.colapsed_items_flex}>
                    <span>
                      <img src={OverallInfo} />
                    </span>
                    <span>
                      <h3>Overall information</h3>
                    </span>
                    <span>
                      <Button
                        color="white"
                        onClick={() => this.toggleCollapse("overall_info")}
                        className={classes.collapse_btn}
                        aria-expanded={true}
                      >
                        <img
                          src={`${
                            overall_info.display ? ChevronDown : ChevronUp
                          }`}
                          alt="collapse icon "
                        />
                      </Button>
                    </span>
                  </div>
                  <div className={classes.collapsed_item_open_flex}>
                    <div
                      className={`${classes.collapsed_item} ${
                        overall_info.display && classes.collapsed_item_open
                      } w-100 `}
                    >
                      {alert_status ? (
                        <strong> {err} </strong>
                      ) : (
                        <>
                          <div className={classes2.table_modal}>
                            <div className={classes2.border} />
                            <div className={classes2.billboard}>
                              <span className={classes2.key}>
                                <img src={BillboardSize} alt="" /> average
                                billboard size:
                              </span>
                              <span className={classes2.value}>
                                {overall_info.data.average_billboard_size}
                              </span>
                            </div>
                            <div className={classes2.billboard}>
                              <span className={classes2.key}>
                                {" "}
                                <img src={Traffic} alt="" /> average traffic
                                speed:
                              </span>
                              <span className={classes2.value}>
                                {overall_info.data.average_traffic_speed}
                              </span>
                            </div>
                            <div className={classes2.billboard}>
                              <span className={classes2.key}>
                                {" "}
                                <img src={BillboardHeight} alt="" /> average
                                billboard height:
                              </span>
                              <span className={classes2.value}>
                                {overall_info.data.average_billboard_height}
                              </span>
                            </div>
                            <div className={classes2.billboard}>
                              <span className={classes2.key}>
                                {" "}
                                <img src={BillboardAngle} alt="" /> average
                                vision angle:
                              </span>
                              <span className={classes2.value}>
                                {overall_info.data.average_vision_angle}
                              </span>
                            </div>
                           {/* <div className={classes2.billboard}>
                              <span className={classes2.key}>
                                {" "}
                                <img src={BillboardAmount} alt="" /> number of
                                billboards:
                              </span>
                              <span className={classes2.value}>
                                {overall_info.data.number_of_billboards}
                              </span>
                      </div>*/}
                            <div className={classes2.billboard}>
                              <span className={classes2.key}>
                                {" "}
                                <img src={Clock} alt="" /> average dwell time:
                              </span>
                              <span className={classes2.value}>
                                {overall_info.data.average_dwell_time}
                              </span>
                            </div>
                            <div className=" pb-3">
                              <button
                                type="button"
                                className={classes2.show_billboards_btn}
                                onClick={this.toggleBillboardsOnMap}
                              >
                                Show Murmur Partner Venues
                                <img
                                  src={ArrowRight}
                                  alt=""
                                  className={classes2.bilboard_show_icon}
                                />
                              </button>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
          )}
                 {location_alert && (
                    <Alert
                      className="d-flex align-items-center justify-content-between"
                      style={{ pointerEvents: "all", width: '500px' }}
                    >
               
                        Please select location to get data
                    
                      <Button
                        color="link"
                        onClick={() =>
                          this.setState({
                            ...this.state,
                            location_alert: false,
                          })
                        }
                      >
                        Close
                      </Button>
                    </Alert>
                  )}
        </div>
        <LocationModal
          modalStatus={modal}
          country={country}
          city={loc}
          closeModal={(state,country, city) => {
            if (!state || country === undefined || city === undefined) {
              this.setState({
                ...this.state,
                modal: false,
              });
              return;
            }
            this.cityChange(city, country);
          }}
        />
      </Fragment>
    );
  }
}

export default connect(null, { toggleSideBar })(
  withRouter(AirQualityContainer)
);
