import React from "react";

import { Modal, ModalBody, ModalHeader, ModalFooter, Row } from "reactstrap";

import classes from "../../assets/css/personaAI/form.module.scss";
import classes2 from "../../assets/css/personaAI/subscribe-modal.module.scss";
import classes3 from "../../assets/css/personaAI/message-box.module.scss";
import "../../assets/css/personaAI/modal.scss";
import "../../assets/css/common/css/upload.scss";

import SocialMediaChannels from "./components/socialMediaChannels";

class AyShareModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ayshare: null,
    };
  }

  render() {
    const {
      show,
      toggle,
      shareContentInSelectedPlatforms,
      ayshare,
      chat,
      copyTextToClipboard,
      savePostAsFavorites,
      deleteFromFavorites,
      platforms,
      toggleDropdownMenu,
      togglePlatform
    } = this.props;

    const disabled = this.state.ayshare == null && !ayshare ? true : false;

    return (
      <Modal
        isOpen={show}
        toggle={toggle}
        className={`${classes2.modal} personaAI`}
      >
        <ModalHeader>
          <div className={classes2.modal_header}>
            <span>{ayshare ? "Ayshare" : "Ayshare private key"}</span>
          </div>
        </ModalHeader>
        <ModalBody>
          <div className={classes2.modal_header}>
            <p className={`${ayshare ? classes2.ayshare : null}`}>
              {ayshare ? (
                <>
                  {chat.text}{" "}
                  <span className={`${classes3.chatbox_actions} mt-1`}>
                    <button>
                      <svg
                        fill="#7356c0"
                        width="20"
                        height="20"
                        viewBox="0 0 16 16"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g strokeWidth="0" />

                        <g strokeLinecap="round" strokeLinejoin="round" />

                        <g>
                          <path d="M15.49 7.3h-1.16v6.35H1.67V3.28H8V2H1.67A1.21 1.21 0 0 0 .5 3.28v10.37a1.21 1.21 0 0 0 1.17 1.25h12.66a1.21 1.21 0 0 0 1.17-1.25z" />

                          <path d="M10.56 2.87 6.22 7.22l-.44.44-.08.08-1.52 3.16a1.08 1.08 0 0 0 1.45 1.45l3.14-1.53.53-.53.43-.43 4.34-4.36.45-.44.25-.25a2.18 2.18 0 0 0 0-3.08 2.17 2.17 0 0 0-1.53-.63 2.19 2.19 0 0 0-1.54.63l-.7.69-.45.44zM5.51 11l1.18-2.43 1.25 1.26zm2-3.36 3.9-3.91 1.3 1.31L8.85 9zm5.68-5.31a.91.91 0 0 1 .65.27.93.93 0 0 1 0 1.31l-.25.24-1.3-1.3.25-.25a.88.88 0 0 1 .69-.25z" />
                        </g>
                      </svg>
                    </button>
                    <button
                      onClick={() => {
                        if (chat.favorite) {
                          deleteFromFavorites(chat.id);
                        } else {
                          savePostAsFavorites(chat.text);
                        }
                      }}
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className={`${
                          chat.favorite ? classes3.favorite : null
                        }`}
                      >
                        <path
                          d="M15.8337 17.5L10.0003 13.3333L4.16699 17.5V4.16667C4.16699 3.72464 4.34259 3.30072 4.65515 2.98816C4.96771 2.67559 5.39163 2.5 5.83366 2.5H14.167C14.609 2.5 15.0329 2.67559 15.3455 2.98816C15.6581 3.30072 15.8337 3.72464 15.8337 4.16667V17.5Z"
                          stroke="#7F56D9"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>
                    <button onClick={() => copyTextToClipboard(chat.text)}>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_4952_21014)">
                          <path
                            d="M4.16699 12.5001H3.33366C2.89163 12.5001 2.46771 12.3245 2.15515 12.0119C1.84259 11.6994 1.66699 11.2754 1.66699 10.8334V3.33341C1.66699 2.89139 1.84259 2.46746 2.15515 2.1549C2.46771 1.84234 2.89163 1.66675 3.33366 1.66675H10.8337C11.2757 1.66675 11.6996 1.84234 12.0122 2.1549C12.3247 2.46746 12.5003 2.89139 12.5003 3.33341V4.16675M9.16699 7.50008H16.667C17.5875 7.50008 18.3337 8.24627 18.3337 9.16675V16.6667C18.3337 17.5872 17.5875 18.3334 16.667 18.3334H9.16699C8.24652 18.3334 7.50033 17.5872 7.50033 16.6667V9.16675C7.50033 8.24627 8.24652 7.50008 9.16699 7.50008Z"
                            stroke="#7F56D9"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_4952_21014">
                            <rect width="20" height="20" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </button>
                  </span>
                </>
              ) : (
                "In order to post directly in your social media channels we ask you to create an ayshare private account and paste your generated APIkey here"
              )}
            </p>
          </div>

          {/*uploading creatives*/}
          {/*<div className="upload-file">    
            <input type="file" onChange={uploadCreativity} multiple />
            <svg
              viewBox="0 0 24 24"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g
                id="Page-1"
                stroke="none"
                stroke-width="1"
                fill="none"
                fillRule="evenodd"
              >
                <g id="Download">
                  <rect id="Rectangle" fill-rule="nonzero" x="0" y="0"></rect>
                  <line
                    x1="12"
                    y1="10"
                    x2="12"
                    y2="19"
                    id="Path"
                    stroke="#7356c0"
                    strokeWidth="2"
                    strokeLinecap="round"
                  ></line>
                  <path
                    d="M15,18 L12.7071,20.2929 C12.3166,20.6834 11.6834,20.6834 11.2929,20.2929 L9,18"
                    id="Path"
                    stroke="#7356c0"
                    strokeWidth="2"
                    strokeLinecap="round"
                  ></path>
                  <path
                    d="M8,15 L6,15 C4.34315,15 3,13.6569 3,12 C3,10.3431 4.34315,9 6,9 C6,5.68629 8.68629,3 12,3 C15.3137,3 18,5.68629 18,9 C19.6569,9 21,10.3431 21,12 C21,13.6569 19.6569,15 18,15 L16,15"
                    id="Path"
                    stroke="#7356c0"
                    strokeWidth="2"
                    strokeLinecap="round"
                  ></path>
                </g>
              </g>
            </svg>
              </div>*/}
          {ayshare ? (
            <SocialMediaChannels
              toggleDropdownMenu={(left, platform, right) => {
                toggleDropdownMenu(left, platform, right);
              }}
              togglePlatform={(left, platform, right) =>
                togglePlatform(left, platform, right)
              }
              platforms={platforms}
            />
          ) : (
            <div className={classes.category_form}>
              <div>
                <textarea
                  onChange={(e) => {
                    const value = e.target.value;
                    this.setState({ ayshare: value });
                  }}
                />
              </div>
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <Row className={`w-100 d-flex justify-content-between `}>
            <div className={classes2.btns}>
              <button type="button" onClick={() => toggle(false)}>
                Cancel
              </button>

              <button
                type="button"
                className={`${disabled ? classes2.disabled : classes2.active}`}
                disabled={disabled}
                onClick={() => {
                  ayshare
                    ? shareContentInSelectedPlatforms(
                        platforms
                          .filter((platform) => platform.active)
                          .map((platform) => platform.item_name)
                      )
                    : toggle(true, { api_key: this.state.ayshare });
                }}
              >
                {ayshare ? "Share" : "Connect"}
                <svg
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.66699 9.99984H16.3337M16.3337 9.99984L10.5003 4.1665M16.3337 9.99984L10.5003 15.8332"
                    stroke="white"
                    strokeWidth="1.67"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </div>
          </Row>
        </ModalFooter>
      </Modal>
    );
  }
}

export default AyShareModal;
