import React from "react";

import classes from "../../../assets/css/CreateAd/placement-type/index.module.css";
import classes2 from "../../../assets/css/CreateAd/navbar.module.css";
import LogoCreate from "../../../assets/css/CreateAd/logo-create.png";
import Copyright from "../../../assets/css/CreateAd/copyright.svg";
import Check from "../../../assets/css/CreateAd/check.svg";
import InfoCircle from "../../../assets/css/CreateAd/info-circle.svg";
import ArrowLeft from "../../../assets/css/CreateAd/ads-details/arrow-left.svg";
import ArrowDown from "../../../assets/css/CreateAd/arrow-down.svg";
import Outdoor from "../../../assets/images/outdoor.jpg";
import Indoor from "../../../assets/images/indoor.jpg";
import Pirates from "../../../assets/images/pirates.jpg";

import { toggleSideBar } from "../../../store/actions";
import { connect } from "react-redux";

import GoogleMap from "../map";

import { Link, withRouter } from "react-router-dom";

import axios from "axios";

import Geocode from "react-geocode";
const GOOGLE_MAP_KEY = process.env.REACT_APP_GOOGLEMAPKEY;
Geocode.setApiKey(GOOGLE_MAP_KEY);

class PlacementType extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      postCenter: { lat: "", lng: "" },
      center: { lat: "", lng: "" },
      markers: this.props.markers,
      zoom: 10,
      venues: [],
      campaign_type: this.props.markers.length ? "Indoor" : "",
      adTypeInfo: {
        info: "Use Murmur Smart LCD backpacks to present your business to the potential customers",
      },
      display_quantity: this.props.markers.length ? 0 : 5,
    };
    this.submit = this.submit.bind(this);
    this.handleValueChange = this.handleValueChange.bind(this);
    this.canselNewCampaign = this.canselNewCampaign.bind(this);
  }

  submit(event) {
    event.preventDefault();
    if (
      this.state.campaign_type != null &&
      (this.state.campaign_type !== "Indoor" ? true :  this.state.markers.length
        ? true
        : false)  //if not indoor true else check markers length > 0 true and else false
    ) {
      this.props.createDisplayAmountAndCampaignType({
        campaign_type: this.state.campaign_type,
        display_quantity: this.state.display_quantity,
        restaurants: this.state.markers,
      });
    }
  }

  handleValueChange(event) {
    const value = event.target.value;

    this.setState({ ...this.state, display_quantity: value });
  }

  adTypeSelect = (e) => {
    const adType = e.target.value;
    let info = "";
    const adTypeInfo = {};

    if (adType === "Outdoor") {
      info =
        "Use Murmur Smart LCD backpacks to present your business to the potential customers";
    } else if (adType === "Indoor") {
      info =
        "Place your ad in retail stores, restaurants via Murmur’s customized Dual touch screens";
    } else {
      info = "Target a place with multiple Smart LCD backpacks";
    }

    adTypeInfo.info = info;
    this.setState({ ...this.state, campaign_type: adType, adTypeInfo, markers: [] });
  };

  canselNewCampaign() {
    this.props.toggleSideBar(true);
    this.props.history.replace("/ad-manager");
    this.props.history.go("/ad-manager");
  }

  componentDidMount() {
    const { country, city } = this.props;
    Geocode.fromAddress(`${country}, ${city}`)
      .then((response) => {
        const { lat, lng } = response.results[0].geometry.location;
        axios
          .get(
            `https://backendapp.murmurcars.com/api/v1/venues/all-venues-by-city/${country}`
          )
          .then((response) => {
            const { data } = response;
            this.setState((state) => {
              return {
                ...state,
                postCenter: { lat, lng },
                center: { lat, lng },
                venues: data,
              };
            });
          })
          .catch((err) => {     alert('something went wrong')})
      })
      .catch((err) => {
        alert('something went wrong')
      });
  }

  removeRestaurantFromList = (index) => {
    const { markers } = this.state;

    const left = markers.slice(0, index);
    const right = markers.slice(index + 1, markers.length);

    this.setState((state) => ({
      ...state,
      markers: [...left, ...right],
    }));
  };

  render() {
    const { campaign_type, adTypeInfo, venues, markers } = this.state;

    const { info } = adTypeInfo;
    const { country, city } = this.props;

    return (
      <React.Fragment>
        <header className={classes2.header}>
          <div
            className={`${classes2.mur_contain} ${classes2.create_top_cancel}`}
          >
            <a href="#">
              <img src={LogoCreate} alt="" />
            </a>
            <button
              type="button"
              className={classes2.create_cancel_btn}
              onClick={this.canselNewCampaign}
            >
              Cancel
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M13.4142 11.9998L17.7072 7.70676C18.0982 7.31576 18.0982 6.68376 17.7072 6.29276C17.3162 5.90176 16.6842 5.90176 16.2933 6.29276L12.0002 10.5858L7.70725 6.29276C7.31625 5.90176 6.68425 5.90176 6.29325 6.29276C5.90225 6.68376 5.90225 7.31576 6.29325 7.70676L10.5862 11.9998L6.29325 16.2928C5.90225 16.6838 5.90225 17.3158 6.29325 17.7068C6.48825 17.9018 6.74425 17.9998 7.00025 17.9998C7.25625 17.9998 7.51225 17.9018 7.70725 17.7068L12.0002 13.4138L16.2933 17.7068C16.4882 17.9018 16.7443 17.9998 17.0002 17.9998C17.2562 17.9998 17.5122 17.9018 17.7072 17.7068C18.0982 17.3158 18.0982 16.6838 17.7072 16.2928L13.4142 11.9998Z"
                  fill="#192038"
                />
              </svg>
            </button>
          </div>
        </header>
        <section className={classes2.mur_contain}>
          <div className={classes2.create_steps}>
            <div className={classes2.create_navbar}>
              <ul className={classes2.create_nav_ul}>
                <li
                  className={`${classes2.nav_ul_item} ${classes2.passed_step}`}
                >
                  <a href="#" className={classes2.nav_item_name}>
                    Campaign Objective
                  </a>
                  <div className={classes2.step_number_cover}>
                    <div className={classes2.number_dot}>
                      <span className={classes2.step_number}>1</span>
                      <img
                        src={Check}
                        alt=""
                        className={classes2.passed_check}
                      />
                    </div>
                    <div className={classes2.step_number_active}>
                      <span className={classes2.step_number}>1</span>
                    </div>
                  </div>
                </li>
                <li
                  className={`${classes2.nav_ul_item} ${classes2.passed_step}`}
                >
                  <a href="#" className={classes2.nav_item_name}>
                    Campaign Details
                  </a>
                  <div className={classes2.step_number_cover}>
                    <div className={classes2.number_dot}>
                      <span className={classes2.step_number}>2</span>
                      <img
                        src={Check}
                        alt=""
                        className={classes2.passed_check}
                      />
                    </div>
                    <div className={classes2.step_number_active}>
                      <span className={classes2.step_number}>2</span>
                    </div>
                  </div>
                </li>
                <li
                  className={`${classes2.nav_ul_item} ${classes2.passed_step}`}
                >
                  <a href="#" className={classes2.nav_item_name}>
                    Audience
                  </a>
                  <div className={classes2.step_number_cover}>
                    <div className={classes2.number_dot}>
                      <span className={classes2.step_number}>3</span>
                      <img
                        src={Check}
                        alt=""
                        className={classes2.passed_check}
                      />
                    </div>
                    <div className={classes2.step_number_active}>
                      <span className={classes2.step_number}>3</span>
                    </div>
                  </div>
                </li>
                <li
                  className={`${classes2.nav_ul_item} ${classes2.passed_step}`}
                >
                  <a href="#" className={classes2.nav_item_name}>
                    Location
                  </a>
                  <div className={classes2.step_number_cover}>
                    <div className={classes2.number_dot}>
                      <span className={classes2.step_number}>4</span>
                      <img
                        src={Check}
                        alt=""
                        className={classes2.passed_check}
                      />
                    </div>
                    <div className={classes2.step_number_active}>
                      <span className={classes2.step_number}>4</span>
                    </div>
                  </div>
                </li>
                <li
                  className={`${classes2.nav_ul_item} ${classes2.passed_step}`}
                >
                  <a href="#" className={classes2.nav_item_name}>
                    Budget & Schedule
                  </a>
                  <div className={classes2.step_number_cover}>
                    <div className={classes2.number_dot}>
                      <span className={classes2.step_number}>5</span>
                      <img
                        src={Check}
                        alt=""
                        className={classes2.passed_check}
                      />
                    </div>
                    <div className={classes2.step_number_active}>
                      <span className={classes2.step_number}>5</span>
                    </div>
                  </div>
                </li>
                <li className={`${classes2.nav_ul_item} ${classes2.active}`}>
                  <a href="#" className={classes2.nav_item_name}>
                    Placement Type
                  </a>
                  <div className={classes2.step_number_cover}>
                    <div className={classes2.number_dot}>
                      <span className={classes2.step_number}>6</span>
                      <img
                        src={Check}
                        alt=""
                        className={classes2.passed_check}
                      />
                    </div>
                    <div className={classes2.step_number_active}>
                      <span className={classes2.step_number}>6</span>
                    </div>
                  </div>
                </li>
                <li className={classes2.nav_ul_item}>
                  <a href="#" className={classes2.nav_item_name}>
                    Ad Media
                  </a>
                  <div className={classes2.step_number_cover}>
                    <div className={classes2.number_dot}>
                      <span className={classes2.step_number}>7</span>
                      <img
                        src={Check}
                        alt=""
                        className={classes2.passed_check}
                      />
                    </div>
                    <div className={classes2.step_number_active}>
                      <span className={classes2.step_number}>7</span>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div className={classes.placement_step}>
              <div className={classes.create_step_1}>
                <h4 className={classes.create_step_h4}>Type Of Placement</h4>

                <div className={classes.step_max_form}>
                  <form onSubmit={this.submit}>
                    <div className={`${classes.step_form_item} `}>
                      <div className={classes.step_relative}>
                        <select
                          name="step-place-type"
                          id="step-place-type"
                          className={classes.step_select_item}
                          onChange={this.adTypeSelect}
                        >
                          <option
                            value={markers.length ? "Indoor" : "Choose type"}
                          >
                            {markers.length ? "Indoor" : "Choose type"}
                          </option>
                          <option value="Outdoor">Outdoor</option>
                          {markers.length || !venues.length ? null : (
                            <option value="Indoor">Indoor</option>
                          )}
                          <option value="Pirates">Pirates</option>
                        </select>
                        <img
                          src={ArrowDown}
                          alt=""
                          className={classes.step_select_icon}
                        />

                        {/* <div
                          className={classes.indoor}
                          onClick={() => this.adTypeSelect("indoor")}
                        >
                          <span>
                            {" "}
                            <span className={classes.custom_radio}>
                              <span
                                className={`${
                                  campaign_type === "indoor" &&
                                  classes.custom_radio_active
                                }`}
                              />
                            </span>
                            <label htmlFor="indoor">Indoor:</label>
                          </span>
                          <div className={classes.img_container}>
                            <img src={Indoor} alt="" />
                          </div>
                        </div>
                        <div
                          className={classes.outdoor}
                          onClick={() => this.adTypeSelect("outdoor")}
                        >
                          <span>
                            {" "}
                            <span className={classes.custom_radio}>
                              <span
                                className={`${
                                   campaign_type === "outdoor" &&
                                  classes.custom_radio_active
                                }`}
                              />
                            </span>
                            <label htmlFor="outdoor">Outdoor:</label>
                          </span>
                          <div className={classes.img_container}>
                            <img src={Outdoor} alt="" />
                          </div>
                        </div>
                        <div
                          className={classes.pirates}
                          onClick={() => this.adTypeSelect("pirates")}
                        >
                          <span>
                            {" "}
                            <span className={classes.custom_radio}>
                              <span
                                className={`${
                                campaign_type === "pirates" &&
                                  classes.custom_radio_active
                                }`}
                              />
                            </span>
                            <label htmlFor="pirates">Pirates:</label>
                          </span>
                          <div className={classes.img_container}>
                            <img src={Pirates} alt="" />
                          </div>
                              </div>*/}
                      </div>
                    </div>
                    {campaign_type === "Outdoor" ||
                    campaign_type === "Pirates" ? (
                      <div className={classes.step_form_item}>
                        <label
                          htmlFor="step-place-num"
                          className={classes.step_label}
                        >
                          Number of Displays
                        </label>
                        <div className={classes.step_relative}>
                          <select
                            name="step-place-num"
                            id="step-place-num"
                            className={classes.step_select_item}
                            onChange={this.handleValueChange}
                          >
                            <option value="" disabled>
                              Choose Number
                            </option>
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="85">85</option>
                            <option value="150">150</option>
                            <option value="350">350</option>
                          </select>
                          <img
                            src={ArrowDown}
                            alt=""
                            className={classes.step_select_icon}
                          />
                        </div>
                        {/*<!-- <span class="pass_error">Error message</span> -->*/}
                      </div>
                    ) : campaign_type === "Indoor" ? (
                      <div className={classes.step_form_item}>
                        <div
                          className="w-100 position-relative"
                          style={{ height: "200px" }}
                        >
                          <GoogleMap
                            state={{
                              center: this.state.center,
                              postCenter: this.state.postCenter,
                              zoom: this.state.zoom,
                              coordinates: [],
                              markers: this.state.markers,
                            }}
                            deletePolygonFromStack={this.deletePolygonFromStack}
                          />
                        </div>
                        <div className={classes.locations}>
                          {markers.map((el, i) => (
                            <button
                              key={i}
                              type="button"
                              onClick={() => this.removeRestaurantFromList(i)}
                            >
                              {el.venue_name}
                            </button>
                          ))}
                        </div>
                        <label
                          htmlFor="step-place-num"
                          className={classes.step_label}
                        >
                          Venues for {country + " , " + city}
                        </label>
                        <div className={classes.step_relative}>
                          <select
                            name="step-place-num"
                            id="step-place-num"
                            className={classes.step_select_item}
                            onChange={(e) => {
                              const venue_name = e.target.value;
                              const venue = venues
                                .filter((el) => el.venue_name === venue_name)
                                .at(0);
                              this.setState((state) => ({
                                ...state,
                                markers: [...state.markers, venue],
                              }));
                            }}
                          >
                               <option value=''>
                                Select
                              </option>
                            {venues.map((venue, i) => (
                              <option value={venue.venue_name} key={i}>
                                {venue.venue_name}
                              </option>
                            ))}
                          </select>
                          <img
                            src={ArrowDown}
                            alt=""
                            className={classes.step_select_icon}
                          />
                        </div>
                      </div>
                    ) : null}
                    <button type="submit" className={classes.step5_btn}>
                      Next
                    </button>
                    <div className={classes.step_center}>
                      <Link
                        to="/ad-manager/budget-schedule"
                        className={classes.step5_back_link}
                      >
                        <img src={ArrowLeft} alt="" />
                        <span>Go Back</span>
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className={classes.create_right_info}>
              <div className={classes.create_info}>
                <p className={classes.create_info_icon}>
                  <img src={InfoCircle} alt="" />
                  <span>Information</span>
                </p>
                <ul className={classes.create_info_ul}>
                  <li>
                    <p className={classes.create_ul_p}>{campaign_type}</p>
                    <div
                      className={`${classes.create_ul_txt} d-flex flex-column position-relative`}
                    >
                      <img
                        src={Outdoor}
                        alt=""
                        style={{ borderRadius: "15px" }}
                        loading="lazy"
                        id="outdoor"
                        className={`${
                          campaign_type === "Outdoor" ? classes.active : null
                        }`}
                      />
                      <img
                        src={Indoor}
                        alt=""
                        style={{ borderRadius: "15px" }}
                        loading="lazy"
                        id="indoor"
                        className={`${
                          campaign_type === "Indoor" ? classes.active : null
                        }`}
                      />
                      <img
                        src={Pirates}
                        alt=""
                        style={{ borderRadius: "15px" }}
                        loading="lazy"
                        id="pirates"
                        className={`${
                          campaign_type === "Pirates" ? classes.active : null
                        }`}
                      />
                      {info}
                    </div>
                  </li>
                  <li>
                    <p className={classes.create_ul_p}>Type of Your Ad</p>
                    <div className={classes.create_ul_txt}>
                      Choose the type of your ad
                    </div>
                  </li>
                  <li>
                    <p className={classes.create_ul_p}>Number of Displays</p>
                    <div className={classes.create_ul_txt}>
                      How many displays will show your ad?
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <footer className={classes2.footer}>
          <div className={`${classes2.mur_contain} ${classes2.mur_flex}`}>
            <p className={`${classes2.footer_copyright} ${classes2.mur_flex}`}>
              <img src={Copyright} alt="" />
              <span>{new Date().getFullYear()}, MurmurCars</span>
            </p>
            <ul className={classes2.footer_links}>
              <li>
                <a href="#" className={classes2.footer_link}>
                  All rights reserved
                </a>
              </li>
              <li>
                <a href="#" className={classes2.footer_link}>
                  Privacy Policy
                </a>
              </li>
            </ul>
          </div>
        </footer>
      </React.Fragment>
    );
  }
}


export default connect(null, { toggleSideBar })(
  withRouter(PlacementType)
);
